<template>
  <div>
    <p class="searchFilterTitle" :style="{color: color ? color :  '#607c24'}">
      {{$t('Search filter')}}
    </p>
    <v-divider class="mt-2 mb-2"/>
    <div class="selectContainer">
      <v-select
          class="ma-2 ml-0"
          :items="days"
          :label="$t('Day')"
          clearable
          :color="color ? color : '#607c24'"
          :item-color="color ? color : '#607c24'"
          :value="selectedDay"
          v-model="mDay"
          outlined
          :disabled="!mYear || !mMonth"
      ></v-select>
      <v-select
          clearable
          class="ma-2"
          :items="months"
          :label="$t('Month')"
          :color="color ? color : '#607c24'"
          :item-color="color? color : '#607c24'"
          :value="selectedMonth"
          v-model="mMonth"
          :disabled="!mYear"
          outlined
      >
        <template v-slot:selection="{ item }">
          {{monthName(parseInt(item) - 1)}}
        </template>
        <template v-slot:item="{ item }">
          {{monthName(parseInt(item) - 1)}}
        </template>
      </v-select>
      <v-select
          class="ma-2"
          :items="years"
          :label="$t('Year')"
          :color="color ? color : '#607c24'"
          :item-color="color ? color : '#607c24'"
          :value="selectedYear"
          v-model="mYear"
          outlined
      ></v-select>
    </div>
  </div>
</template>

<script>
import {daysInMonth, getNameOfMonth} from "../../../utils/date";

export default {
  name: "TarifficMonitoringSearchFilter",
  props: ['selectedYear', 'selectedMonth', 'selectedDay', 'color'],
  data: () => {
    return {
      mYear: '',
      mMonth: '',
      mDay: '',
      months: [...Array(12).keys()].map((_, index) => {
        return  1 + index;
      }),
      years: [...Array(100).keys()].map((_, index) => {
        return 2022 + index;
      }),
      lastEmit: ''
    }
  },
  computed: {
    days() {
      if (this.mYear && this.mMonth) {
        return [...Array(daysInMonth(parseInt(this.mMonth), parseInt(this.mYear))).keys()].map((_, index) =>  {
          return 1 + index;
        })
      } else {
        return [];
      }
    }
  },
  methods: {
    monthName(month) {
      return getNameOfMonth(month);
    },
    emitData() {
      if ( (this.lastEmit && (this.lastEmit.day !== this.mDay  || this.lastEmit.month !== this.mMonth || this.lastEmit.year !== this.mYear)) || !this.lastEmit) {
        const data = {
          day: this.mDay,
          month: this.mMonth,
          year: this.mYear
        };
        this.lastEmit = data;
        this.$emit('filter-change', data);
      }
    },
    init() {
      this.mYear = this.selectedYear;
      this.mMonth = this.selectedMonth;
      this.mDay = this.selectedDay;
    }
  },
  watch: {
    selectedYear(newVal) {
      this.mYear = parseInt(newVal);
    },
    selectedMonth(newVal) {
      this.mMonth = parseInt(newVal);
    },
    selectedDay(newVal) {
      this.mDay = parseInt(newVal);
    },

    mMonth(newVal) {
      if (!newVal) {
        this.mDay = "";
      }
      this.emitData();
    },
    mYear() {
      this.emitData();
    },
    mDay() {
      this.emitData()
    }
  },
  created() {
    this.init();
  }
}
</script>

<style scoped>
.searchFilterTitle {
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 1.5rem;
}
.selectContainer {
  display: flex;
  flex-direction: row;
}
</style>