<template>
  <div>
    <v-data-table :headers="headers" :items="customers" class="my-5">
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>{{ strings.CUSTOMERS.tableTitle }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn rounded class="pa-4 mt-4" @click="$emit('update:dialog', true)">
            {{ strings.CUSTOMERS.btnNewCustomerText }}
          </v-btn>
        </v-toolbar>
      </template>
      <template v-slot:item.status="{ item }">
        <v-switch @change="statusChange(item)" v-model="item.status"
                  :label="`${item.status ? strings.CUSTOMERS.statusActivated : strings.CUSTOMERS.statusDeactivated}`"
                  color="success"></v-switch>
      </template>
      <template v-slot:item.subPlan="{ item }">
        <PlanSelect
            :items="getFilteredSubs(item)"
            @change="(v) => handleSelect(v, item)"
            :value="$store.getters['subscription/subscriptionByCustomerId'][item.id] ? $store.getters['subscription/subscriptionByCustomerId'][item.id].subscription : ''"
        />
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon class="mr-1" @click="$emit('editItem', item)">mdi-pencil</v-icon>
        <v-icon @click="$emit('deleteItem', item)" class="mr-1" v-if="false">mdi-delete</v-icon>
        <v-icon @click="$emit('infoItem', item)">mdi-information</v-icon>
      </template>
      <template v-slot:no-data>No customers</template>
    </v-data-table>
  </div>
</template>

<script>
import {updateCustomer} from "@/api/customers";
import PlanSelect from "./PlanSelect";
import {filterPlanBasedOnCustomerType} from "../../utils/customer";

export default {
  name: "CustomersTable",
  components: {PlanSelect},
  props: {
    customers: Array,
  },
  computed: {
    strings() {
      return this.$store.getters['language/strings']
    },
    headers() {
      return [
        {text: this.strings.CUSTOMERS.tableHeaderEmail, value: 'email'},
        {text: this.strings.CUSTOMERS.tableHeaderName, value: 'name'},
        {text: this.strings.CUSTOMERS.tableHeaderApiKey, value: 'key'},
        {text: this.strings.CUSTOMERS.tableHeaderStatus, value: 'status'},
        {text: this.strings.CUSTOMERS.tableHeaderCreatedAt, value: 'createdAt'},
        {text: this.strings.CUSTOMERS.tableHeaderUpdatedAt, value: 'updatedAt'},
        {text: 'Naročninski plan', value: 'subPlan'},
        {
          text: this.strings.CUSTOMERS.tableHeaderActions,
          value: 'actions',
          sortable: false,
          align: 'center',
          width: 120
        }
      ]
    },
    allSubscriptions() {
      return this.$store.getters["subscription/allSubscriptions"]
    },
    subscriptionsById() {
      return this.$store.getters["subscription/subscriptionByCustomerId"]
    }
  },
  methods: {
    async statusChange(item) {
      try {
        await updateCustomer(item.id, {email: item.email, name: item.name, status: item.status})
      } catch (e) {
        // this.$emit('fetchCustomers')
      }
    },
    async handleSelect(plan, customer) {
      this.$emit('planChange', {
        customer: customer,
        plan: plan
      })
    },
    getFilteredSubs(item) {
      const items = filterPlanBasedOnCustomerType(this.allSubscriptions, item.customerType)
      console.log(items);
      return items;
    }
  },
  mounted() {
    const currenSubs = this.$store.getters["subscription/allSubscriptions"];
    if (currenSubs.length <= 0) {
      this.$store.dispatch('subscription/getAllSubscriptions')
    }
  }
}
</script>

<style scoped>
</style>