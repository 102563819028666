<template>
  <v-row class="mt-2 justify-center">
    <v-col
        class="col-lg-5 col-sm-12"
    >
      <TariffAccountInformationCard />
    </v-col>
    <v-col
        class="col-lg-5 col-sm-12"
    >
      <TarifficSubscriptionInfoCard />
      <TarifficCustomerSupportCard class="mt-4" />
    </v-col>
  </v-row>
</template>

<script>
import TariffAccountInformationCard from "./components/TarifficAccountInformationCard";
import TarifficSubscriptionInfoCard from "./components/TarifficSubscriptionInfoCard";
import TarifficCustomerSupportCard from "./components/TarifficCustomerSupportCard";
export default {
  name: "Main",
  components: {TarifficCustomerSupportCard, TarifficSubscriptionInfoCard, TariffAccountInformationCard},
  data: () => {
    return {
      showPassword: false
    }
  }
}
</script>

<style scoped>

</style>