import {getHistoryData} from "../../api/tariffic-customer";

export const tarifficHistory = {
    namespaced: true,
    state: {
        historyData: '',
        fetchInProgress: false,
        activeDate: '',
    },
    mutations: {
        setHistoryData(state, data) {
            state.historyData = data;
        },
        setActiveDate(state, date) {
            state.activeDate = date;
        },
        addHistoryData(state, data) {
            if (state.historyData) {
                state.historyData = [
                    ...state.historyData,
                    ...data
                ];
            } else {
                state.historyData = data;
            }
        }
    },
    actions: {
        async fetchHistoryData(context, { offset, limit }) {
            const activeDate = context.state.activeDate;
            if (context.state.fetchInProgress) return
            const ONE_DAY_SECONDS = 3600 * 24;
            const data = await getHistoryData(
                activeDate,
                activeDate + ONE_DAY_SECONDS,
                offset,
                limit
            );
            context.commit('addHistoryData', data);
        },
        async resetActiveDate(context, date) {
            context.commit('setActiveDate', date);
            context.commit('setHistoryData', '');
        }
    },
    getters: {
        isFetching: (state) => state.fetchInProgress,
        historyData: (state) => state.historyData,
        activeDate: (state) => state.activeDate
    }
}