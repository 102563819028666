import httpClient from "../utils/httpClient";

/**
 * Creates customer based on entered parameters
 *     data class CustomersCreateReq(
 *     val email: String,
 *     val name: String?,
 *     val password: String,
 *     val subscriptionId: String?,
 *     val customerType: Int,
 *
 *     val committedId: String,
 *     val contractId: String,
 *     val branchId: String
 * )
 * @param req - create customer request
 */
export const createCustomerAPI = async (req) => {
    const res = await httpClient.post(`${process.env.VUE_APP_API_URL}/customers`, req);
    return res.data;
}

/**
 * Gets customers
 */
export const getCustomers = async () => {
    const res = await httpClient.get(`${process.env.VUE_APP_API_URL}/customers`);
    return res.data;
}
/**
 * Deletes customer
 * @param customerId - identifier of customer
 */
export const deleteCustomer = async (customerId) => {
    await httpClient.delete(`${process.env.VUE_APP_API_URL}/customers/${customerId}`);
}

/**
 * Updates customer
 * @param customerId - identifier of customer
 * @param req - update request
 */
export const updateCustomer = async (customerId, req) => {
    await httpClient.put(`${process.env.VUE_APP_API_URL}/customers/${customerId}`, req);
}

/**
 * Gets all available subscription plans
 */
export const getAvailableCustomerPlans = async () => {
    const res = await httpClient.get(`${process.env.VUE_APP_API_URL}/customers/subscriptionPlans`);
    return res.data;
}

/**
 * Gets customer plan
 * @param customerId - identifier of customer
 */
export const getCustomerPlan = async (customerId) => {
    const res = await httpClient.get(`${process.env.VUE_APP_API_URL}/customers/${customerId}/subPlan`);
    return res.data;
}

/**
 * Updates customer subscription plan
 * @param customerId - customer identifier
 */
export const updateCustomerSubscription = async (customerId, subscriptionId) => {
    const res = await httpClient.put(`${process.env.VUE_APP_API_URL}/customers/${customerId}/subPlan`, {
        subscriptionId:  subscriptionId
    })
    return res.data
}

/**
 * Gets statistics for customer
 * @param customerId - customer identifier
 * @param day - day of month
 * @param month - month of year
 * @param year - full year
 */
export const getStatisticsForCustomer = async (customerId, day, month, year) => {
    const res = await httpClient.get(`${process.env.VUE_APP_API_URL}/customers/${customerId}/statistics?day=${day}&month=${month}&year=${year}`)
    return res.data
}