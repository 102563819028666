<template>
  <v-container>
    <v-row>
      <v-col class="col-lg-6">
        <p class="rowTitle">
          Seznam prepovedanih in nevarnih predmetov
        </p>
        <v-divider/>

        <v-col class="col-lg-12">
          <p class="rowSubTitle">
            Filtriraj glede na:
          </p>
          <HazardousCommodityTypes @onTypeChange="onTypeChange"/>

          <p class="rowSubTitle">
            Tabela prepovedanih snovi
          </p>
          <div class="mt-2">
            <HazardousCommoditiesTable
                :data="commoditiesData"
                @nextPage="fetchNextPage(false)"
                @onDelete="deleteEntry"
            />
          </div>
        </v-col>
      </v-col>

      <v-col class="col-lg-6">
        <p class="rowTitle">
          Vnos nove snovi
        </p>
        <v-divider/>
        <NewHazardousCommodityForm @onNewEntry="onNewEntry"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import HazardousCommoditiesTable from "./HazardousCommoditiesTable";
import HazardousCommodityTypes from "./HazardousCommodityTypes";
import NewHazardousCommodityForm from "./NewHazardousCommodityForm";
import {paginationMixin} from "../mixins/pagination";
import {createCommodity, deleteCommodity, getHazardousCommodities} from "../api/hazardCommodities";

export default {
  name: "MainDashboard",
  components: {NewHazardousCommodityForm, HazardousCommodityTypes, HazardousCommoditiesTable},
  mixins: [paginationMixin],
  data: () => {
    return {
      filterCommoditiesValue: {
        'restrictionType': 'DANGER',
        'restrictedOn': 'ALL'
      },
      commoditiesData: []
    }
  },
  methods: {
    onTypeChange(val) {
      this.filterCommoditiesValue = val;
    },
    async fetchNextPage(filterChange) {
      if (this.fetchInProgress) {
        return;
      }

      this.fetchInProgress = true;
      const result = await getHazardousCommodities(
          this.paginationInfo.offset,
          this.paginationInfo.limit,
          this.filterCommoditiesValue.restrictionType,
          this.filterCommoditiesValue.restrictedOn
      );
      this.fetchInProgress = false;

      this.commoditiesData = filterChange ? result : [...this.commoditiesData, ...result];
      this.markPageFetch()
    },
    async onNewEntry(req) {
      const newCommodity = await createCommodity(req);
      await this.$store.dispatch('snackbar/fireAlert', {
        message: 'Uspešno dodana nova snov',
        color: 'success'
      });
      if (newCommodity.restrictionType === this.filterCommoditiesValue.restrictionType
          && newCommodity.restrictedOn === this.filterCommoditiesValue.restrictedOn) {
        this.commoditiesData.push(newCommodity);
      }
    },
    async deleteEntry(entry) {
      await deleteCommodity(entry.id);
      this.commoditiesData = this.commoditiesData.filter((it) => it.id !== entry.id);
    }
  },
  async mounted() {
    await this.fetchNextPage(false);
  },
  computed: {
    strings() {
      return this.$store.getters['language/strings']
    }
  },
  watch: {
    filterCommoditiesValue: {
      handler() {
        this.setToDefault();
        this.fetchNextPage(true);
      },
      deep: true
    }
  },
}
</script>

<style scoped>
.rowTitle {
  color: black;
  font-weight: 100;
  font-size: 1.5rem;
}

.rowSubTitle {
  color: black;
  font-weight: 100;
}
</style>
