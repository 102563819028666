import httpClient from "../utils/httpClient";
import download from "downloadjs";

/**
 * Gets classification history
 * @param offset - offset of pagination
 * @param limit - limit of pagination
 * @param fromDate - from date in unix seconds timestamp
 * @param toDate - to date in unix seconds timestamp
 */
export const getHistory = async (offset, limit, fromDate, toDate) => {
    console.log(`${process.env.VUE_APP_API_URL}/classificationHistory?fromDate=${fromDate}&toDate=${toDate}&offset=${offset}&limit=${limit}`)
    const res = await httpClient.get(`${process.env.VUE_APP_API_URL}/classificationHistory?fromDate=${fromDate}&toDate=${toDate}&offset=${offset}&limit=${limit}`);
    return res.data;
}

export const downloadHistory = async (fromDate, toDate) => {
    const res = await httpClient.get(`${process.env.VUE_APP_API_URL}/classificationHistory/csv?fromDate=${fromDate}&toDate=${toDate}`);
    download(res.data, `classificationHistory-${new Date(fromDate * 1000).toUTCString()}.csv`)
}
