<template>
  <div class="mainContainer mt-2">
    <div class="tariffContainer">
      {{ tariffPrefix }}
    </div>
    <div class="tariffContent">
      {{ tariffDescription }}
    </div>
  </div>
</template>

<script>
export default {
  name: "TariffDescription",
  props: ['tariffPrefix', 'tariffDescription']
}
</script>

<style scoped>
.mainContainer {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.tariffContainer {
  font-weight: 600;
  font-size: 1.7rem;
  align-self: flex-start;
}
.tariffContent {
  margin-top: auto;
  margin-bottom: 5px;
  margin-left: 5px;
  font-weight: 300;
  align-self: flex-start;
}
</style>