<template>
  <v-row>
    <v-col class="col-lg-12 pa-10">
      <v-col class="col-lg-3 mt-4">
        <DatePicker @onDate="onDate"/>
      </v-col>
      <ClassificationHistoryTable
          :data="historyData"
          @nextPage="onNextPage"
      />
    </v-col>

    <v-fab-transition>
      <v-btn
          class="ma-10"
          color="white"
          fab
          dark
          small
          absolute
          bottom
          right
          large
          @click="downloadFile"
      >

        <v-icon color="#FBCB07">mdi-export-variant</v-icon>
      </v-btn>
    </v-fab-transition>
  </v-row>
</template>

<script>
import DatePicker from "./DatePicker";
import {downloadHistory, getHistory} from "../api/hsHistory";
import ClassificationHistoryTable from "./ClassificationHistoryTable";
import {paginationMixin} from "../mixins/pagination";

export default {
  name: "ClassificationHistory",
  components: {ClassificationHistoryTable, DatePicker},
  mixins: [paginationMixin],
  data: function () {
    return {
      historyData: [],
      activeDate: Math.floor(Date.now() / 1000),
      ONE_DAY_SECONDS: 3600 * 24
    }
  },
  methods: {
    async onDate(fromDate) {
      this.activeDate = fromDate;
      this.setToDefault();
      this.historyData = [];

      await this.fetchHistoryData()
    },
    async fetchHistoryData() {
      this.fetchInProgress = true;
      this.paginationInfo.limit = 20;
      this.historyData = [...this.historyData, ...await getHistory(
          this.paginationInfo.offset,
          this.paginationInfo.limit,
          this.activeDate,
          this.activeDate + this.ONE_DAY_SECONDS
      )];
      this.fetchInProgress = false;
      this.markPageFetch()
    },
    async onNextPage() {
      if (!this.fetchInProgress) {
        await this.fetchHistoryData();
      }
    },
    async downloadFile() {
      await downloadHistory(
          this.activeDate,
          this.activeDate + this.ONE_DAY_SECONDS
      )
    }
  }
}
</script>

<style scoped>

</style>
