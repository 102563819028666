<template>
  <v-dialog v-model="dialogModel" max-width="500px">
    <v-card>
      <v-card-title>
        <span class="text-h5">{{ formTitle }}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-text-field
                  v-model="editedItem.name"
                  :label="strings.CUSTOMERS.tableHeaderName"
                  solo
                  class="form-bnt-radius"
              />
              <v-text-field
                  v-model="editedItem.email"
                  :label="strings.CUSTOMERS.tableHeaderEmail"
                  solo
                  class="form-bnt-radius"
              />
              <v-text-field
                  v-model="editedItem.password"
                  label="Geslo"
                  solo
                  type="password"
                  class="form-bnt-radius"
              />
              <v-select
                  v-if="id === ''"
                  v-model="selectedType"
                  :items="allTypes"
                  label="Tip stranke"
                  item-text="name"
                  return-object
                  single-line
                  value="value"
                  solo
                  class="form-bnt-radius"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="#000" text @click="dialogModel = false">{{ strings.CUSTOMERS.formCancel }}</v-btn>
        <v-btn color="#000" text @click="save">{{ strings.CUSTOMERS.formSave }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {createCustomer, updateCustomer} from "@/api/customers";

export default {
  name: "AddEditDialog",
  props: {
    dialog: Boolean,
    editedItemProp: Object,
    id: String,
  },
  computed: {
    strings() { return this.$store.getters['language/strings'] },
    formTitle() { return this.id === '' ? this.strings.CUSTOMERS.formTitleNew : this.strings.CUSTOMERS.formTitleEdit },
    dialogModel: {
      get () { return this.dialog },
      set (value) { this.$emit('update:dialog', value) },
    },
    editedItem: {
      get () { return this.editedItemProp },
      set (value) { this.$emit('update:editedItem', value) },
    },
    allSubscriptions() {
      return this.$store.getters["subscription/allSubscriptions"]
    }
  },
  methods: {
    async save () {
      if(this.id === '') {
        await createCustomer({ name: this.editedItem.name, email: this.editedItem.email, password: this.editedItem.password, customerType: this.selectedType.value})
      }else {
        const obj = { email : this.editedItem.email, name : this.editedItem.name, status: this.editedItem.status, password: this.editedItem.password ? this.editedItem.password : null }
        await updateCustomer(this.id, obj)
        await this.$store.dispatch('customer/updateCustomer', {...obj, id: this.id})
      }
      this.dialogModel = false
      this.$emit('fetchCustomers')
    },
    handleChangeSelect() {

    }
  },
  data() {
    return {
      selectedType: {
        name: 'API potrošnik',
        value: 1
      },
      allTypes: [
        {
          name: 'API potrošnik',
          value: 1
        },
        {
          name: 'Lokalna instanca',
          value: 2
        }
      ]
    }
  }
}
</script>

<style scoped>
.form-bnt-radius {
  border-radius: 15px !important;
}
</style>