import {daysInMonth, getNameOfMonth} from "../utils/date";
import {getStatisticsForCustomer} from "../api/customers";

export const customerStatistics = {
    namespaced: true,
    state: {
        statisticsReport: '',
        graphValueData: [],
        graphLabelData: [],
        fetchInProgress: false
    },
    mutations: {
        setStatisticsReport(state, { params, data }) {
            // params contains - day, year, month
            const dataSize = params.day ?
                24 : params.month
                    ? daysInMonth(params.month, params.year) : 12;

            const graphData = [...new Array(dataSize).keys()].map(() => 0);
            let labelData = [...new Array(dataSize).keys()].map(() => 0);

            state.statisticsReport = data;
            state.graphValueData = data.periods.map((it) => it.count)

            if (params.day) {
                labelData = labelData.map((_, index) => {
                    return index;
                })
                data.periods.forEach((it) => {
                    const hour = parseInt(it.periodName.split(":")[1])
                    graphData[hour] = it.count
                })
            } else if (params.month) {
                labelData = labelData.map((_, index) => {
                    return index + 1;
                })
                data.periods.forEach((it) => {
                    const day = parseInt(it.periodName.split("/")[0] - 1)
                    graphData[day] = it.count
                })
            } else {
                labelData = labelData.map((_, index) => {
                    return getNameOfMonth(index)
                })
                data.periods.forEach((it) => {
                    const month = parseInt(it.periodName.split("/")[0] - 1)
                    graphData[month] = it.count
                })
            }


            state.graphValueData = graphData;
            state.graphLabelData = labelData;
        },
        clearStats(state) {
            state.statisticsReport = '';
            state.graphLabelData = [];
            state.graphValueData = [];
        },
        setProgress(state, progress) {
            state.fetchInProgress= progress;
        }
    },
    actions: {
        async getStats(context, data) {
            context.commit('setProgress', true);

            const res = await getStatisticsForCustomer(data.customerId, data.day, data.month, data.year);
            context.commit('setProgress', false);

            context.commit('setStatisticsReport', {
                data: res,
                params: data
            })
        },
        clearStats(context) {
            context.commit('clearStats');
        }
    },
    getters: {
        graphValueData: (state) => state.graphValueData,
        graphLabelData: (state) => state.graphLabelData,
        statisticsReport: (state) => state.statisticsReport,
        isFetching: (state) => state.fetchInProgress
    }
}