<template>
  <v-row class="mt-2 justify-center">
    <v-col
        class="col-lg-5 col-sm-12"
    >
      <TarifficMonitoringSearchFilter
          class="ml-5"
          :selected-day="selectedDay"
          :selected-month="selectedMonth"
          :selected-year="selectedYear"
          @filter-change="onFilterChange"
      />
      <p class="ml-5 subSectionTitle">
        {{$t('Distribution of API calls')}}
      </p>
      <v-divider class="mt-2 ml-5 mb-5"/>
      <TarifficValueGraph
          v-if="statisticsReport && graphLabelData && graphValueData"
          :value-data="graphValueData"
          :value-label-data="graphLabelData"
          id="consumptionGraph"
          :invert-axis="true"
          min-height="60vh"
          :label-x="xAxis"
          :label-y="$t('Number of API calls')"
      />
    </v-col>
    <v-col
        class="col-lg-6 col-sm-12"
    >
      <p class="subSectionTitle">
        {{$t('Confidence of predictions')}}
      </p>
      <v-divider class="mt-2 mb-2"/>
      <TarifficPie
          v-if="pieGraphData"
          id="confidencePie"
          :chart-data="pieGraphData"
      />
      <TarifficNoData
          v-else-if="!isFetching"
          class="mt-3"
      />

      <p class="subSectionTitle mt-5">
        {{$t('Api calls statistics')}}
      </p>
      <v-divider class="mt-2 mb-2" />
      <TarifficNumberOfCalls
          :api-calls-obj="apiCallsCount"
          v-if="apiCallsCount"
      />
      <TarifficNoData
          v-else-if="!isFetching"
          class="mt-3"
      />
    </v-col>
  </v-row>
</template>

<script>
import TarifficMonitoringSearchFilter from "./components/TarifficMonitoringSearchFilter";
import TarifficValueGraph from "./components/TarifficValueGraph";
import TarifficPie from "./components/TarifficPie";
import TarifficNoData from "./components/TarifficNoData";
import TarifficNumberOfCalls from "./components/TarifficNumberOfCalls";
export default {
  name: "TarifficMonitoring",
  components: {TarifficNumberOfCalls, TarifficNoData, TarifficPie, TarifficValueGraph, TarifficMonitoringSearchFilter},
  methods: {
    async onFilterChange(it) {
      await this.$store.dispatch('tariffic-statistics/getStats', it);
    }
  },
  computed: {
    statisticsReport() {
      return this.$store.getters["tariffic-statistics/statisticsReport"];
    },
    graphLabelData() {
      return this.$store.getters["tariffic-statistics/graphLabelData"];
    },
    graphValueData() {
      return this.$store.getters["tariffic-statistics/graphValueData"];
    },
    xAxis() {
      return this.$store.getters["tariffic-statistics/xAxis"]
    },
    pieGraphData() {
      const data = this.$store.getters['tariffic-statistics/statisticsReport'];
      if (!data) return '';
      const res = [
        {
          name: this.$t('Confident predictions'),
          color: '#607c24',
          count: data.confidentPredictionCount
        },
        {
          name: this.$t('Non-confident predictions'),
          color: '#e4c932',
          count: data.nonConfidentPredictionCount
        }
      ];
      if (data.confidentPredictionCount === 0 && data.nonConfidentPredictionCount === 0) {
        return ''
      }
      return res;
    },
    apiCallsCount() {
      const data = this.$store.getters['tariffic-statistics/statisticsReport'];
      if (!data) return ''
      return {
        totalApiCalls: data.totalSumCount,
        confidentPredictionCount: data.confidentPredictionCount,
        nonConfidentPredictionCount: data.nonConfidentPredictionCount,
        accessToTranslatorServiceCount: data.accessToGoogleApiTranslator,
        ...data
      }
    },
    isFetching() {
      return this.$store.getters['tariffic-statistics/isFetching'];
    }
  },
  data: () => {
    return {
      selectedDay: new Date().getDate(),
      selectedMonth: new Date().getMonth() + 1,
      selectedYear: new Date().getFullYear()
    }
  },
  destroyed() {
    this.$store.dispatch('tariffic-statistics/clearStats')
  },
  beforeDestroy() {
    this.$store.dispatch('tariffic-statistics/clearStats')
  }
}
</script>

<style scoped>
.searchFilterTitle {
  color: #e4c932;
}
.subSectionTitle {
  color: #607c24;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
  font-size: 1.5rem;
}
</style>