var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-0"},[_c('div',{staticStyle:{"max-height":"65vh","overflow-y":"auto"},on:{"scroll":_vm.onScroll}},[_c('v-data-table',{attrs:{"hide-default-footer":true,"headers":_vm.headers,"items":_vm.myData,"items-per-page":-1},scopedSlots:_vm._u([{key:"item.rawText",fn:function(ref){
var item = ref.item;
return [(!_vm.isOverflowing(item.rawText))?_c('div',{staticClass:"entryContainer"},[_vm._v(" "+_vm._s(item.rawText)+" ")]):_c('div',{staticClass:"truncatedEntryContainer"},[_c('div',{class:item.rawTextActiveClass},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.onArrowClickRawText(item)}}},[_c('v-icon',[_vm._v(" mdi-chevron-down ")])],1)],1),(item.isRawTextExpanded)?_c('v-expand-transition',[_c('div',[_vm._v(" "+_vm._s(item.rawText)+" ")])]):_c('v-expand-transition',[_c('div',[_vm._v(" "+_vm._s(_vm.truncateText(item.cleanText))+" ")])])],1)]}},{key:"item.detectedLang",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"detectedLangContainer"},[_vm._v(" "+_vm._s(item.detectedLang !== '' ? item.detectedLang : 'N/A')+" ")])]}},{key:"item.cleanText",fn:function(ref){
var item = ref.item;
return [(!_vm.isOverflowing(item.cleanText))?_c('div',{staticClass:"entryContainer"},[_vm._v(" "+_vm._s(item.cleanText)+" ")]):_c('div',{staticClass:"truncatedEntryContainer"},[_c('div',{class:item.cleanTextActiveClass},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.onArrowClickCleanText(item)}}},[_c('v-icon',[_vm._v(" mdi-chevron-down ")])],1)],1),(item.isCleanTextExpanded )?_c('v-expand-transition',[_c('div',[_vm._v(" "+_vm._s(item.cleanText)+" ")])]):_c('v-expand-transition',[_c('div',[_vm._v(" "+_vm._s(_vm.truncateText(item.cleanText))+" ")])])],1)]}},{key:"item.predictedTariff",fn:function(ref){
var item = ref.item;
return [_c('v-expansion-panels',{staticStyle:{"width":"20vw"},attrs:{"value":true}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',[_vm._v(" "+_vm._s(item.predictedTariff)+" ")]),_c('v-expansion-panel-content',[_c('TariffDescriptionContent',{attrs:{"tariff-number":item.predictedTariff}})],1)],1)],1)]}},{key:"item.isConfident",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"width":"2vw"}},[(item.isConfident)?_c('p',[_vm._v(" "+_vm._s(_vm.$t('No'))+" ")]):_c('p',[_vm._v(" "+_vm._s(_vm.$t('Yes'))+" ")])])]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }