import {getApiVersion} from "@/api/tariffic-customer";

export const version = {
    namespaced: true,
    state: {
        apiVersion: ''
    },
    mutations: {
        setVersion(state, { version }) {
            state.apiVersion = version;
        }
    },
    actions: {
        async fetchVersion(ctx) {
            const res = await getApiVersion()
            ctx.commit('setVersion', res)
        }
    },
    getters: {
        apiVersion: (state) => state.apiVersion
    }
}