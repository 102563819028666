<template>
  <div class="mt-0">
    <DecisionModal ref="decisionModal"/>
    <div
        @scroll="onScroll"
        style="max-height: 60vh; overflow-y: scroll"
    >
      <v-data-table
          :hide-default-footer="true"
          :headers="headers"
          :items="data"
          :items-per-page="-1"
      >

        <template v-slot:item.actions="{ item }" @click="onDelete">
          <v-btn
              @click="onDelete(item)"
              icon
          >
            <v-icon>
              mdi-delete
            </v-icon>
          </v-btn>
        </template>

      </v-data-table>
    </div>
  </div>
</template>

<script>
import DecisionModal from "./DecisionModal";
export default {
  name: "HsMappingTable",
  components: {DecisionModal},
  data: function () {
    return {
      headers: [
        {
          text: 'Stara tarfina številka',
          align: 'start',
          sortable: false,
          value: 'oldTariff'
        },
        {
          text: 'Nova tarfina številka',
          align: 'start',
          sortable: false,
          value: 'newTariff'
        },
        {
          text: 'Akcije',
          align: 'start',
          sortable: false,
          value: 'actions'
        }
      ]
    }
  },
  methods: {
    onScroll({target: {scrollTop, clientHeight, scrollHeight}}) {
      if (scrollTop + clientHeight >= scrollHeight) {
        this.$emit('nextPage');
      }
    },
    async onDelete(item) {
      const result = await this.$refs.decisionModal.showModal({
        message: 'Ali ste prepričani, da želite izbrisati preslikavo ?',
        title: 'Brisanje preslikave',
        positiveDecision: 'Izbriši',
        negativeDecision: 'Opusti'
      });
      if (result) {
        this.$emit('onDelete', item);
      }
    }
  },
  props: ['data']
}
</script>

<style scoped>

</style>
