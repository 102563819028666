<template>
  <v-container>
    <v-row>
      <v-col class="col-lg-6">
        <div class="rowTitle titleContainer">
          <div>
            <p>
              Preslikovalna tabela
            </p>
          </div>
          <div style="margin-left: auto" class="mr-2">
            <v-select
                class="pa-0 ma-0"
                placeholder="Leto"
                prepend-icon="mdi-calendar"
                :items="years"
                v-model="year"
            />
          </div>
        </div>
        <v-col class="col-lg-12" v-if="false">
          <v-col class="col-lg-4" v-if="false">
            <v-select
                placeholder="Leto"
                prepend-icon="mdi-calendar"
                :items="years"
                v-model="year"
            />
          </v-col>
        </v-col>
        <v-divider/>

        <v-col class="col-lg-12">
          <div v-if="!fetchInProgress && hsMapping.length === 0">
            <p class="expText">
              Za izbrano leto ni prisotnih preslikav, nove preslikave lahko dodate v desnem obrazcu, ali pa jih uvozite
              iz prejšnjih let.
            </p>
          </div>
          <HsMappingTable
              class="mt-10"
              v-else-if="!fetchInProgress || hsMapping.length > 0"
              :data="hsMapping"
              @nextPage="getHsMapping"
          />
        </v-col>
      </v-col>

      <v-col class="col-lg-6">
        <p class="rowTitle">
          Nastavitve trenutno aktivne preslikovalne tabele
        </p>
        <v-divider/>
        <v-col class="col-lg-5">
          <v-select
              placeholder="Trenutno aktivno leto"
              prepend-icon="mdi-calendar"
              :items="hsMappingYears"
              v-model="hsMappingConfig.activeYear"
          />
        </v-col>

        <HsMappingForm ref="hsMappingForm" @onAdd="addNewHsTariffMapping"/>

      </v-col>
    </v-row>
    <div class="alert">
      <v-alert
          type="error"
      >
        Funkcionalnost je v fazi razvoja
      </v-alert>
    </div>
  </v-container>
</template>

<script>
import {paginationMixin} from "../mixins/pagination";
import {createNewHsMappingReq, getHsConfig, getHsMapping, getHsMappingYears} from "../api/hsMapping";
import HsMappingTable from "./HsMappingTable";
import HsMappingForm from "./HsMappingForm";

export default {
  name: "HsMapping",
  components: {HsMappingForm, HsMappingTable},
  mixins: [paginationMixin],
  data: function () {
    return {
      year: '',
      years: [],
      hsMapping: [],
      hsMappingConfig: '',
      hsMappingYears: []
    }
  },
  methods: {
    async getHsMapping() {
      if (!this.fetchInProgress) {
        this.paginationInfo.limit = 20;
        this.fetchInProgress = true;
        this.hsMapping = [
          ...this.hsMapping,
          ...await getHsMapping(this.year, this.paginationInfo.offset, this.paginationInfo.limit)]
        this.fetchInProgress = false;
        this.markPageFetch()
      }
    },
    async addNewHsTariffMapping(item) {
      const mappingReq = await createNewHsMappingReq({...item, year: this.year});
      this.hsMapping.push(mappingReq);

      this.$refs.hsMappingForm.clearForm();
    }
  },
  async mounted() {
    this.years = [...Array(100).keys()].map((it) => it + 2022);
    this.hsMappingConfig = await getHsConfig();
    this.hsMappingYears = await getHsMappingYears();
    this.year = this.hsMappingConfig.activeYear;
  },
  watch: {
    year: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.setToDefault();
        this.hsMapping = [];
        this.getHsMapping(newVal);
      }
    }
  },
}
</script>

<style scoped>
.rowTitle {
  color: black;
  font-weight: 100;
  font-size: 1.5rem;
}

.rowSubTitle {
  color: black;
  font-weight: 100;
}

.headerContainer {
  display: flex;
}

.expText {
  font-weight: 100;
}

.titleContainer {
  display: flex;
  align-items: center;
}

.form-bnt-radius {
  border-radius: 15px !important;
}

.buttonContainer {
  display: flex;
  justify-content: end;
}

.alert {
  position: absolute;
  bottom: 0;
  margin-bottom: 5%;
  left: 50%;
  transform: translate(-65%, 0);
}
</style>
