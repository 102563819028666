<template>
  <div :style="cssStyles">
    <div class="d-inline-block">
      <div class="prefix">{{prediction.prefix}}</div>
    </div>
    <div class="percentage_container">
      <div class="percentage">{{prediction.precission}}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TariffPredictionBlock",
  props: {
    prediction: Object,
    color: {
      type: String,
      default: "#fff"
    }
  },
  computed: {
    cssStyles() {
      return {
        '--prop-color': this.color
      }
    }
  }
}
</script>

<style scoped>
.percentage_container {
  position: relative;
}
.percentage {
  position: absolute;
  top: 1px;
  left: 50%;
  font-size: 14px;
  transform: translateX(-50%);
  color: var(--prop-color);
}
.prefix {
  position: relative;
  color: var(--prop-color);
  font-weight: 700;
  font-size: 64px;
  line-height: 75px;
  padding-bottom: 10px;
  border-bottom: 1px solid var(--prop-color);
}
.prefix:after {
  content: '';
  position: absolute;
  left: 0;
  right: -1px; /* no double border */
  bottom: 0;
  height: 5px;
  border-left: 1px solid var(--prop-color);
  border-right: 1px solid var(--prop-color);
}
</style>
