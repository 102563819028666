<template>
  <transition name="logo" appear>
    <div class="logo">
      <slot />
    </div>
  </transition>
</template>

<script>
export default {
  name: "Logo.vue",
}
</script>

<style scoped>
.logo {
  margin-bottom: 60px
}
.logo::v-deep img { /* ::v-deep needed for styling what is inside the slots (in Vue3 should be ::v-deep(img))*/
  display: block;
  max-width: 210px;
}
@media screen and (min-width: 600px) {
  .logo {
    margin-top: 50px;
  }
}
@media screen and (min-width: 960px) {
  .logo {
    margin-top: 110px;
  }
}
/* Transition */
.logo-enter-active,
.logo-leave-active {
  transition: all 0.3s ease-in-out;
}
.logo-enter,
.logo-leave-to {
  transform: translateX(-20px);
  opacity: 0;
}
</style>