<template>
  <v-form class="mt-3">
    <v-text-field
        class="form-bnt-radius"
        :label="$t('Tariff number prefix')"
        @input="debounceFinalPrefix"
        v-model="tariffPrefix"
        type="input"
        :color="!isAdmin ? primaryColor : ''"
        :solo="isAdmin"
        :flat="isAdmin"
    ></v-text-field>
    <v-text-field
        class="form-bnt-radius"
        v-model="tariffDescription"
        :label="$t('Tariff number description')"
        type="input"
        :solo="isAdmin"
        :flat="isAdmin"
        :color="!isAdmin ? primaryColor : ''"
        readonly
    ></v-text-field>
    <div :class="isAdmin ? 'subTitleContainer' : 'subTitleContainerTariffic'">
      <p>{{$t('New commodity parameters')}}</p>
    </div>
    <HazardousCommodityTypes @onTypeChange="onTypeChange" :version="version" />
    <div class="buttonContainer">
      <v-btn :rounded="isAdmin"
             :class="isAdmin ? 'pa-6' : 'pa-5'"
             :color="!isAdmin ? primaryColor : ''"
             :disabled="!isAddingEnabled"
             @click="createNewEntry"
      >
        <span
            :style="{color: isAdmin ? 'black' : 'white'}"
        >
          {{$t('Add new commodity')}}
        </span>
      </v-btn>
    </div>
  </v-form>
</template>

<script>
import HazardousCommodityTypes from "./HazardousCommodityTypes";
import _ from 'lodash';
import {getTariffDescription} from "../api/tariffs";
import {isNum} from "../utils/strings";

export default {
  name: "NewHazardousCommodityForm",
  components: {HazardousCommodityTypes},
  props: {
    version: {
      type: String,
      required: true,
      default: 'admin'
    }
  },
  data: function () {
    return {
      tariffPrefix: '',
      restrictionType: 'DANGER',
      restrictedOn: 'ALL',
      numberReg: /^\d+$/,
      tariffFinalNumber: '',
      tariffDescription: ''
    }
  },
  methods: {
    onTypeChange(val) {
      console.log(val);
      this.restrictionType = val.restrictionType;
      this.restrictedOn = val.restrictedOn;
    },
    async createNewEntry() {
      this.$emit('onNewEntry',{
        tariffPrefix: this.tariffPrefix,
        restrictionType: this.restrictionType,
        restrictedOn: this.restrictedOn
      });
      this.tariffPrefix = "";
    },
    debounceFinalPrefix: _.debounce(function (e) {
      this.tariffFinalNumber = e
    }, 700)
  },
  computed: {
    isAddingEnabled: function () {
      return this.tariffPrefix !== "" && this.numberReg.test(this.tariffPrefix) && this.tariffPrefix.length % 2 === 0;
    },
    isAdmin() {
      return this.version === 'admin'
    },
    primaryColor () {
      return this.version === 'admin' ? 'white' : '#607c24';
    },
    secondaryColor () {
      return this.version === 'admin' ? 'black' : 'rgba(0, 0, 0, 0.4)'
    }
  },
  watch: {
    async tariffFinalNumber(val) {
      if (isNum(val)) {
        const result = await getTariffDescription(val);
        this.tariffDescription = result.hsDescription;
      } else {
        this.tariffDescription = '';
      }
    }
  }
}
</script>

<style scoped>
.form-bnt-radius {
  border-radius: 15px !important;
}
.subTitleContainer {
  display: flex;
  justify-content: start;
  font-weight: 100;
}
.subTitleContainerTariffic {
  display: flex;
  justify-content: start;
  font-family: 'Poppins', sans-serif;
  color: #607c24;
}
.buttonContainer {
  display: flex;
  justify-content: end;
}
</style>
