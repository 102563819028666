import {getAccountInformation, getMySubPlan, getNumberOfApiCalls} from "../../api/tariffic-customer";

export const accountInfo = {
    namespaced: true,
    state: {
        accountInformation: '',
        mySubscription: '',
        mySubFetchInProgress: false,
        apiCount: ''
    },
    mutations: {
        setAccountInformation(state, data) {
            state.accountInformation = data;
        },
        setSubscription(state, data) {
            state.mySubscription = data;
        },
        setMySubFetchInProgress(state, data) {
            state.mySubFetchInProgress = data;
        },
        setApiCount(state, count) {
            state.apiCount = count;
        }
    },
    actions: {
        async getAccountInformation(context) {
            const data = await getAccountInformation();
            context.commit('setAccountInformation', data);
        },
        async getMySubscription(context) {
            context.commit('setMySubFetchInProgress', true)
            try {
                const data = await getMySubPlan();
                context.commit('setSubscription', data.subscription);
            } catch (e) {
                console.log(e)
            } finally {
                context.commit('setMySubFetchInProgress', false)
            }
        },
        async getApiCount(context) {
            const data = await getNumberOfApiCalls();
            context.commit('setApiCount', data.count)
        }
    },
    getters: {
        accountInformation(state) {
            return state.accountInformation;
        },
        mySubscription(state) {
            return state.mySubscription;
        },
        subscriptionInFetch(state) {
            return state.mySubFetchInProgress;
        },
        apiCount(state) {
            return state.apiCount;
        }
    }
}