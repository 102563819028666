<template>
<v-container>
  <v-row>
    <v-col class="col-lg-12" cols="12">
      <span class="ml-1 font-weight-thin">{{strings.MONITORING.graphTitle}} {{totalCount}}</span>
      <v-card>
        <Chart :label="strings.MONITORING.graphTitle" @onCount="onCount"/>
      </v-card>
    </v-col>
  </v-row>
</v-container>
</template>

<script>
import Chart from "@/components/Chart";
export default {
  name: "Monitoring",
  components: {Chart},
  data: function () {
    return {
      logs: [],
      trainingInfo: '',
      totalCount: 0
    }
  },
  methods: {
    onCount(totalCount) {
      this.totalCount = totalCount;
    }
  },
  async mounted() {

  },
  filters:{

  },
  computed: {
    strings () {
      return this.$store.getters['language/strings']
    }
  }
}
</script>

<style scoped>

</style>
