<template>
  <v-row>
    <v-spacer />
    <v-col class="pa-5" cols="12" sm="10" md="9" lg="8" xl="5">
      <Logo>
        <img src="@/assets/posta_logo_cropped.png">
      </Logo>

      <Search @onSearch="onSearch" />

      <transition name="switch" appear>
        <v-switch v-model="langDetection" color="black" v-if="switchEnabled">
          <template v-slot:label>
            <span class="input__label">
              {{langDetection ? $t('Disable language detection') : $t('Enable language detection')}}
            </span>
          </template>
        </v-switch>
      </transition>

      <Results :secondaryColor="secondaryColor"/>
    </v-col>
    <v-spacer />
  </v-row>
</template>

<script>
import Search from "@/components/HSClassifier/Search";
import Results from "@/components/HSClassifier/Results";
import Logo from "@/components/HSClassifier/Logo"

export default {
  name: "HSClassifier",
  components: { Logo, Results, Search },
  props: {
    secondaryColor: String,
    switchEnabled: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data() {
    return {
      langDetection: false, // switch v-model
    }
  },
  computed: {
    lang() { return this.$store.getters['language/language'] }
  },
  watch: {
    lang(newLang) { // when changing language change the switch value
      this.langDetection = (newLang !== 'si')
    }
  },
  methods: {
    onSearch(description) {
      this.$store.dispatch('prediction/getPrediction', {
        description: description,
        langDetection: this.langDetection
      })
    }
  },
  mounted() { // set switch state on mounted
    this.langDetection = (this.lang !== 'si')
  },
}
</script>

<style scoped>
.input__label {
  color: black;
}
/* Transition */
.switch-enter-active, .switch-leave-active {
  transition: all 0.3s ease-in-out 0.1s;
}
.switch-enter, .switch-leave-to {
  transform: translateY(30px);
  opacity: 0;
}

/* Vuetify breakpoints */
/*@media screen and (min-width: 600px) {}*/
/*@media screen and (min-width: 960px) {}*/
/*@media screen and (min-width: 1264px) {}*/
/*@media screen and (min-width: 1904px) {}*/
</style>
