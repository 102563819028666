var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-10 main"},[_c('div',{class:_vm.locked ? 'locked' : '',style:({maxHeight: _vm.maxHeight ? _vm.maxHeight :'65vh', overflowY: 'auto'}),on:{"scroll":_vm.onScroll}},[(_vm.data.length === 0 && _vm.dataFetchInProgress === false)?_c('TarifficNoTrainingData'):(_vm.data.length > 0)?_c('v-data-table',{attrs:{"hide-default-footer":true,"items-per-page":-1,"items":_vm.data,"headers":_vm.headers},scopedSlots:_vm._u([(!_vm.noAction)?{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',{staticClass:"ma-1",attrs:{"medium":""},on:{"click":function($event){return _vm.onDelete(item)}}},[_vm._v(" mdi-delete ")])],1),_c('v-btn',{attrs:{"icon":""}},[(false)?_c('v-icon',{staticClass:"ma-1",attrs:{"medium":""},on:{"click":function($event){return _vm.onEdit(item)}}},[_vm._v(" mdi-pencil ")]):_vm._e()],1)]}}:null,{key:"item.description",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"descriptionEntry",style:({maxWidth: _vm.maxLineWidth ? _vm.maxLineWidth : '300px'})},'span',attrs,false),on),[_vm._v(" "+_vm._s(item.description)+" ")])]}}],null,true)},[_c('div',{style:({maxWidth: _vm.maxLineWidth ? _vm.maxLineWidth : '300px'})},[_c('span',[_vm._v(_vm._s(item.description))])])])]}}],null,true)}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }