import {errorHandler} from "./errorHandler";
import {store} from "../store";
import axios from "axios";
const actionScopeSpinner = 'spinner';
const actionScopeAlert = 'snackbar';

export const setupInterceptor = (quiet, httpClient) => ({ dispatch }) => {

    const req = {
        pending: () => {
            dispatch(`${actionScopeSpinner}/addPendingRequest`)
        },
        done: () => {
            dispatch(`${actionScopeSpinner}/removePendingRequest`)
        }
    };

    httpClient.interceptors.request.use(
        async function (config ) {
            req.pending()
            // Workflow: check if token is expired - > if token is expired send preflight request to refresh token
            const authToken = store.getters["auth/getAuthToken"]
            if (authToken !== '') {
                const validToken = store.getters["auth/isLoggedIn"]
                let token = ''

                if (validToken) {
                    // Take valid token
                    token = authToken
                } else {
                    const success = await store.dispatch('auth/refreshTokens')
                    if (success) {
                        token = store.getters["auth/getAuthToken"]
                    }
                }

                // Successfully obtained token should be placed in header
                if (token !== '') {
                    config.headers = {
                        ...config.headers,
                        'Authorization' : `Bearer ${token}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                }
            }
            return config
        },
        error => {
            req.done();
            return Promise.reject(error)
        }
    )
    httpClient.interceptors.response.use(
        (res) => {
            req.done()
            return Promise.resolve(res)
        },
        async function (error) {
            req.done()
            if (error.response) {
                const responseCode = error.response.status || 500

                if (responseCode === 401) {
                    // Workflow -> check if user is logged in there is valid refresh token in store
                    // Dispatch another request using different instance of axios to /refresh-token
                    // If success save new tokens to store, else dispatch logout action to store
                    const success = await store.dispatch('auth/refreshTokens', store.getters['auth/getRefreshToken'])
                    if (success) {
                        // We have successfully obtained new set of tokens and saved them to local storage
                        error.config.headers['Authorization'] = 'Bearer ' + store.getters["auth/getAuthToken"]
                        return axios.request(error.config)
                    }
                }
            }
            if (!quiet) {
                dispatch(`${actionScopeAlert}/fireAlert`, {
                    message: errorHandler(error),
                    color: 'error'
                })
            }
            return Promise.reject(error)
        }
    )
}
