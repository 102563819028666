export const messages = {
    en: {
        'Type of restriction': 'Type of restriction',
        'Restricted upon': 'Restricted upon:',
        'Enter new dangerous / forbidden commodity': 'Enter new dangerous / forbidden commodity',
        'Forbidden and dangerous commodities': 'Forbidden and dangerous commodities',
        'List of dangerous and forbidden commodities': 'List of dangerous and forbidden commodities',
        'Filter by:': 'Filter by:',
        'Functionality is not supported in your current subscription plan': 'Functionality is not supported in your current subscription plan',
        'Type': 'Type',
        'Restriction of commodity': 'of constraint',
        'Prohibited substances': 'Prohibited substances',
        'Dangerous substances': 'Dangerous substances',
        'Limited to': 'Limited to',
        'Type of person': 'type',
        'All persons': 'All persons',
        'Natural persons': 'Natural person',
        'Prefix': 'Prefix',
        'Actions': 'Actions',
        'Dangerous commodity': 'Dangerous commodity',
        'Forbidden commodity': 'Forbidden commodity',
        'On all persons': 'On all persons',
        'On natural persons': 'On natural persons',
        'You have successfully added new commodity': 'You have successfully added new commodity',
        'Tariff number description': 'Tariff number description',
        'Tariff number prefix': 'Tariff number prefix',
        'New commodity parameters:': 'New commodity parameters:',
        'Add new commodity': 'Add new commodity',
        'Deleting hazardous commodity entry': 'Deleting hazardous commodity entry',
        'Are you sure you want to delete hazardous commodity entry ?': 'Are you sure you want to delete hazardous commodity entry ?',
        'Delete': 'Delete',
        'Dismiss': 'Dismiss',
        'Hazardous commodities': 'Hazardous commodities',
        'Tariffic API history overview': 'Tariffic API history overview',
        'Date': 'Date',
        'Cancel': 'Cancel',
        'Ok': 'Ok',
        'Entered text': 'Entered text',
        'Processed text': 'Processed text',
        'Detected language': 'Detected language',
        'Predicted tariff number': 'Predicted tariff number',
        'Detected low confidentiality': 'Detected low confidentiality',
        'Yes': 'Yes',
        'No': 'No',
        'Home': 'Home',
        'Tariffic HS-classifier': 'Tariffic HS-classifier',
        'Classifier': 'Classifier',
        'Tariffic API call overview': 'Tariffic API call overview',
        'Monitoring': 'Monitoring',
        'History': 'History',
        'Hazardous Commodities': 'Hazardous Commodities',
        'Logout': 'Logout',

        'Account information': 'Account information',
        'Name': 'Name',
        'Email address': 'Email address',
        'API key': 'API key',
        'Account created on': 'Account created on: ',
        'Account status': 'Account status',
        'Change your password': 'Change your password',
        'Subscription plan': 'Subscription plan',
        'Subscription plan activated': 'Subscription plan activated: ',
        'Translation enabled': 'Translation enabled: ',
        'Number of calls per month': 'Number of calls per month: ',
        'Change your subscription plan': 'Change your subscription plan',
        'Customer support': 'Customer support',
        'Customer support text': 'If you are experiencing difficulties with Tariffic API, or you have questions regarding subscription plans / Tariffic API / dashboard functionalities etc.',
        'Feel free to contact us at:': 'Feel free to contact us at:',
        'Close': 'Close',
        'Save': 'Save',
        'Password': 'Password',
        'Repeat password': 'Repeat password',
        'ACTIVE': 'ACTIVE',
        'INACTIVE': 'INACTIVE',
        'Password must be at least 6 characters long': 'Password must be at least 6 characters long',
        'Passwords must match': 'Passwords must match',
        'You have successfully reset your password': 'You have successfully reset your password',
        'Hazardous commodities enabled': 'Hazardous commodities enabled',
        'No subscription is currently active': 'No subscription is currently active',
        'Subscription plan information': 'Subscription plan information',
        'Enter the description of the goods in Slovenian language': 'Enter the description of the goods in Slovenian language',
        'Enable language detection': 'Enable language detection',
        'Disable language detection': 'Disable language detection',
        'Classify': 'Classify',
        'Predicted tariff number has low confidence': 'Predicted tariff number has low confidence',
        'Restriction type': 'Restriction type:',

        'Search filter': 'Search filter',
        'Day': 'Day',
        'Month': 'Month',
        'Year': 'Year',
        'Distribution of API calls': 'Distribution of API calls',
        'Confidence of predictions': 'Confidence of predictions',
        'Api calls statistics': 'Api calls statistics',
        'Confident predictions': 'Confident predictions',
        'Non-confident predictions': 'Non-confident predictions',
        'Sum of all API calls': 'Sum of all API calls',
        'Confident prediction count': 'Confident prediction count',
        'Non confident prediction count': 'Non confident prediction count',
        'Access to translator service': 'Access to translator service',
        'There is no data for given search criteria': 'There is no data for given search criteria',
        'Looks like there were no api calls made in selected period.': 'Looks like there were no api calls made in selected period.',
        'Number of API calls': 'Number of API calls',
        'Hour of the day': 'Hour of the day',
        'Day of the month': 'Day of the month',
        'Month of the year': 'Month of the year',

        'January': 'January',
        'February': 'February',
        'March': 'March',
        'April': 'April',
        'May': 'May',
        'June': 'June',
        'July': 'July',
        'August': 'August',
        'September': 'September',
        'October': 'October',
        'November': 'November',
        'December': 'December',

        'Language': 'Language',
        'English': 'English',
        'Slovenian': 'Slovenian',

        'Detected language note': "The entered language was not Slovene but: ${language}, so we translated it from: ${original} to ${translate}, the result will be the quality of the prediction being somewhat worse than if the description had been entered in Slovene",
        'Download tariffic api manuals': 'Download tariffic api manuals'
    },
    sl: {
        'Type of restriction': 'Tip omejitve',
        'Restricted upon': 'Omejeno  na:',
        'Enter new dangerous / forbidden commodity': 'Vnesite novo nevarno / prepovedano snov',
        'Forbidden and dangerous commodities': 'Prepovedane in nevarne snovi',
        'List of dangerous and forbidden commodities': 'Seznam nevarnih in prepovedanih dobrin',
        'Filter by:': 'Filtriraj glede na:',
        'Functionality is not supported in your current subscription plan': 'Funkcionalnost ni podprta v vašem trenutnem naročniškem paketu',
        'Type': 'Type',
        'Restriction of commodity': 'omejitve predmeta',
        'Prohibited substances': 'Prepovedane snovi',
        'Dangerous substances': 'Nevarne snovi',
        'Limited to': 'Omejeno na',
        'Type of person': 'tip osebe',
        'All persons': 'Vse osebe',
        'Natural persons': 'Fizične osebe',
        'Prefix': 'Predpona',
        'Actions': 'Akcije',
        'Dangerous commodity': 'Nevarna snov',
        'Forbidden commodity': 'Prepovedana snov',
        'On all persons': 'Vse osebe',
        'On natural persons': 'Fizične osebe',
        'You have successfully added new commodity': 'Uspešno ste dodali novo snov',
        'Tariff number description': 'Opis tarifne številke',
        'Tariff number prefix': 'Predpona tarifne številke',
        'New commodity parameters': 'Parametri nove snovi:',
        'Add new commodity': 'Dodaj novo snov',
        'Deleting hazardous commodity entry': 'Brisanje vnosa nevarne snovi',
        'Are you sure you want to delete hazardous commodity entry ?': 'Ali ste prepričani, da želite izbrisati vnos nevarne snovi?',
        'Delete': 'Izbriši',
        'Dismiss': 'Opusti',
        'Hazardous commodities': 'Nevarne snovi',
        'Tariffic API history overview': 'Pregled zgodovine Tariffic API-ja',
        'Date': 'Datum',
        'Cancel': 'Prekliči',
        'Ok': 'Vredu',
        'Entered text': 'Vnešen niz',
        'Processed text': 'Procesiran niz',
        'Detected language': 'Detektiran jezik',
        'Predicted tariff number': 'Napovedana tarifna številka',
        'Detected low confidentiality': 'Zaznana niza zanesljivost',
        'Yes': 'Ja',
        'No': 'Ne',
        'Home': 'Domov',
        'Tariffic HS-classifier': 'Tariffic HS-klasifikator',
        'Classifier': 'Klasifikator',
        'Tariffic API call overview': 'Pregled klicev Tariffic API-ja',
        'Monitoring': 'Monitoriranje',
        'History': 'Zgodovina',
        'Hazardous Commodities': 'Nevarne Snovi',
        'Logout': 'Odjava',

        'Account information': 'Informacije o uporabniškem računu',
        'Name': 'Ime',
        'Email address': 'Elektronski naslov',
        'API key': 'API ključ',
        'Account created on': 'Uporabniški račun kreiran',
        'Account status': 'Status uporabniškega računa:',
        'Change your password': 'Spremeni svoje geslo',
        'Subscription plan': 'Naročninski paket: ',
        'Subscription plan activated': 'Naročninski paket aktiviran: ',
        'Translation enabled': 'Prevajanje omogočeno:',
        'Number of calls per month': 'Število klicev na mesec: ',
        'Change your subscription plan': 'Spremeni naročninski paket',
        'Customer support': 'Podpora strankam',
        'Customer support text': 'Če imate težave z API-jem Tarific ali imate vprašanja v zvezi z naročniškimi načrti / API-jem Tarific / funkcijami nadzorne plošče itd.',
        'Feel free to contact us at:': 'Kontaktirajte nas na:',
        'Close': 'Zapri',
        'Save': 'Shrani',
        'Password': 'Geslo',
        'Repeat password': 'Ponovi geslo',
        'ACTIVE': 'AKTIVEN',
        'INACTIVE': 'NEAKTIVEN',
        'Password must be at least 6 characters long': 'Geslo mora biti dolgo vsaj 6 znakov',
        'Passwords must match': 'Gesli se morata ujemati',
        'You have successfully reset your password': 'Uspešno ste spremenili geslo',
        'Hazardous commodities enabled': 'Nevarne snovi omogočene:',
        'No subscription is currently active': 'Trenutno ni aktivna nobena naročnina',
        'Subscription plan information': 'Informacije o naročninskem paketu',
        'Enter the description of the goods in Slovenian language': 'Vnesite opis snovi v slovenskem jeziku',
        'Enable language detection': 'Omogoči detekcijo jezika',
        'Disable language detection': 'Onemogoči detekcijo jezika',
        'Classify': 'Klasificiraj',
        'Predicted tariff number has low confidence': 'Napovedana tarifna številka ima nizko zanesljivost',
        'Restriction type': 'Tip omejitve:',

        'Search filter': 'Iskalni filter',
        'Day': 'Dan',
        'Month': 'Mesec',
        'Year': 'Leto',
        'Distribution of API calls': 'Pregled API klicev',
        'Confidence of predictions': 'Zanesljivost predikcije',
        'Api calls statistics': 'API statistika',
        'Confident predictions': 'Zanesljive predikcije',
        'Non-confident predictions': 'Nezanesljive predikcije',
        'Sum of all API calls': 'Seštevek vseh API klicev',
        'Confident prediction count': 'Seštevek zanesljivih predikcij',
        'Non confident prediction count': 'Seštevek nezanesljivih predikcij',
        'Access to translator service': 'Dostop do prevajalnika',
        'There is no data for given search criteria': 'Za dane iskalne kriterije ni podatkov',
        'Looks like there were no api calls made in selected period.': 'Videti je, da v izbranem obdobju ni bilo api klicev.',
        'Number of API calls': 'Število API klicev',
        'Hour of the day': 'Ura dneva',
        'Day of the month': 'Dan meseca',
        'Month of the year': 'Mesec leta',

        'January': 'Januar',
        'February': 'Februar',
        'March': 'Marec',
        'April': 'April',
        'May': 'Maj',
        'June': 'Junij',
        'July': 'Julij',
        'August': 'Avgust',
        'September': 'September',
        'October': 'Oktober',
        'November': 'November',
        'December': 'December',

        'Language': 'Jezik',
        'English': 'Angleščina',
        'Slovenian': 'Slovenščina',

        'Detected language note': 'Vnešen jezik ni bil slovenski temveč: ${language}, zato smo ga prevedli iz: "${original}" v "${translate}" , posledično bo kvaliteta predikcija nekoliko slabša kot če bi opis vnesli v slovenščini',
        'Download tariffic api manuals': 'Prenesi tariffic api navodila'
    }
}
