import VueRouter from 'vue-router'
import Vue from 'vue'

Vue.use(VueRouter)

import MainDashboard from "@/components/MainDashboard";
import Monitoring from "@/components/Monitoring";
import HSClassifier from "@/components/HSClassifier/HSClassifier";
import Users from "@/components/Users";
import CustomersManager from "@/components/customers/CustomersManager"
import HsMapping from "./components/HsMapping";
import ClassificationHistory from "./components/ClassificationHistory";
import Login from "./components/Login";
import TarifficLogin from "./components/tariffic/TarifficLogin";
import {store} from "./store";
import HSDescriptions from "./components/HSDescriptions";
import TarifficMain from "./components/tariffic/TarifficMain";
import TarifficClassifierDemoApp from "./components/tariffic/TarifficClassifierDemoApp";
import TarifficHazardousCommodities from "./components/tariffic/TarifficHazardousCommodities";
import TarifficMonitoring from "./components/tariffic/TarifficMonitoring";
import TarifficHistory from "./components/tariffic/TarifficHistory";
import CustomerInformation from "./components/CustomerInformation";
import TarifficAlgorithmTrainingData from "./components/tariffic/TarifficAlgorithmTrainingData";

const routes = process.env.VUE_APP_VERSION === 'TARIFFIC-CUSTOMER' ? [
    {
      path: '/',
      component: TarifficMain,
      name: 'Main'
    },
    {
        path: '/login',
        component: TarifficLogin,
        name: 'Login'
    },
    {
        path: '/classifier',
        component: TarifficClassifierDemoApp,
        name: 'Classifier'
    },
    {
        path: '/hazardous-commodities',
        component: TarifficHazardousCommodities,
        name: 'Hazardous-Commodities'
    },
    {
        path: '/monitoring',
        component: TarifficMonitoring,
        name: 'Monitoring'
    },
    {
        path: '/history',
        component: TarifficHistory,
        name: 'History'
    },
    {
        path: '/training',
        component: TarifficAlgorithmTrainingData,
        name: 'AlgorithmTraining'
    }
] : [
    {
        path: '/',
        component: MainDashboard,
        name: 'MainDashboard'
    },
    {
        path: '/monitoring',
        component: Monitoring,
        name: 'Monitoring'
    },
    {
        path: '/classifier',
        component: HSClassifier,
        name: 'HSClassifier'
    },
    {
        path: '/users',
        component: Users,
        name: 'UserManager'
    },
    {
        path: '/customers',
        component: CustomersManager,
        name: 'CustomersManager'
    },
    {
        path: '/mapping',
        component: HsMapping,
        name: 'HsMapping'
    },
    {
        path: '/history',
        component: ClassificationHistory,
        name: 'HSHistory'
    },
    {
        path: '/login',
        component: Login,
        name: 'Login'
    },
    {
        path: '/descriptions',
        component: HSDescriptions,
        name: 'HSDescription'
    },
    {
        path: '/customers/:id',
        component: CustomerInformation,
        name: 'CustomerInformation'
    }
]

export const router = new VueRouter({
    routes
})

router.beforeEach((to, from, next) => {

    const isAuthenticated = store.getters["auth/isLoggedIn"] || store.getters["auth/validRefreshToken"]
    if (to.name !== 'Login' && !isAuthenticated) {
        next({name: 'Login'});
    } else {
        next();
    }
})
