<template>
  <PostaSLapp v-if="appVersion === 'TARIFFIC-ADMIN'" />
  <SolvesallApp v-else-if="appVersion === 'SOLVESALL'"/>
  <TarifficCustomerApp v-else-if="appVersion === 'TARIFFIC-CUSTOMER'" />
  <TarifficCustomerApp v-else />
</template>

<script>
import PostaSLapp from "./screens/PostaSLapp";
import SolvesallApp from "./screens/SolvesallApp";
import TarifficCustomerApp from "./screens/TarifficCustomerApp";
import {i18n} from "@/i18n/i18n";
export default {
  name: 'App',
  components: {TarifficCustomerApp, SolvesallApp, PostaSLapp},
  computed: {
    appVersion() {
      return process.env.VUE_APP_VERSION
    }
  },
  methods: {
    onDecision(dec) {
      console.log(dec)
      this.decision = dec;
    },
    onCompanyChange(val) {
      if (val === 'postaHR') {
        // document.title = 'Hrvatska pošta - HS klasfikator';
      } else if (val === 'solvesall') {
        // document.title = 'Solvesall - HS klasifikator';
      } else {
        // document.title = 'Pošta Slovenije - HS klasifikator';
      }
    }
  },
  data: function () {
    return {
      decision: ''
    }
  },
  mounted() {
    i18n.locale = this.$store.getters['auth/getLanguage']

    const company = process.env.VUE_APP_COMANY;
    this.onCompanyChange(company);
    this.$store.dispatch('version/fetchVersion');
  }
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
html { overflow-y: auto !important }
</style>
