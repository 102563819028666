/**
 * @param licences - array of licences
 * @param customerType - customer tpyes
 */
export const filterPlanBasedOnCustomerType = (licences, customerType) => {
    console.log(licences, customerType);
    if (customerType === 'API_CONSUMER') {
        console.log('consumer');
        return licences.filter((it) => it.id === 'f76034a3-7ab9-4a8c-b217-9e2ae0e4bb8f' || it.id === 'e1bf0870-cbdb-4873-b1ac-65718023d816');
    } else if (customerType === 'LOCAL_INSTANCE') {
        console.log('local instance');
        return licences.filter((it) => it.id === '862da9c2-2961-4a2b-8f5b-8f86d01a5638');
    }
    return [];
}