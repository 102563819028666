<template>
  <div class="mainContainer">
    <div class="infoContainer">
      <v-icon color="#607c24">
        mdi-information
      </v-icon>
    </div>
    <div class="textContainer">
       <span class="titleLinearProgress">
         {{ title }}
       </span>
      <span class="subTitleLinearProgress">
        {{ subTitle }}
      </span>
    </div>
    <v-progress-linear
        class="mt-5"
        color="#607c24"
        indeterminate
        stream
    ></v-progress-linear>
  </div>
</template>

<script>
export default {
  name: "InfiniteProgressModal",
  props: ['title', 'subTitle']
}
</script>

<style scoped>
.mainContainer {
  background-color: rgba(255, 255, 255, 1);
  border-radius: 12px;
  padding: 20px;
  position: relative;
}

.infoContainer {
  position: absolute;
  right: 5px;
  top: 5px;
}

.titleLinearProgress {
  color: #607c24;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
  font-size: 0.85rem;
}

.subTitleLinearProgress {
  color: rgba(0, 0, 0, 0.5);
  font-family: 'Poppins', sans-serif;
  font-size: 0.7rem;
}

.textContainer {
  display: flex;
  flex-direction: column;
}
</style>