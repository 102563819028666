<template>
  <v-row>
    <v-radio-group class="ma-3" v-model="typesOBJ.restrictionType">
      <template v-slot:label>
        <div :style="{color: secondaryColor}">{{$t('Type')}} <strong>{{$t('Restriction of commodity')}}</strong></div>
      </template>

      <v-radio
          value="FORBIDDEN"
          :color="primaryColor">
        <template v-slot:label>
          <div :style="{color: secondaryColor}">
            {{$t('Prohibited substances')}}
          </div>
        </template>
      </v-radio>
      <v-radio
          value="DANGER"
          :color="primaryColor"
      >
        <template v-slot:label>
          <div :style="{color: secondaryColor}">
            {{$t('Dangerous substances')}}
          </div>
        </template>
      </v-radio>
    </v-radio-group>

    <v-radio-group class="ma-3" v-model="typesOBJ.restrictedOn">
      <template v-slot:label>
        <div :style="{color: secondaryColor}">{{$t('Limited to')}} <strong>{{$t('Type of person')}}</strong></div>
      </template>

      <v-radio
          value="ALL"
          :color="primaryColor"
      >
        <template v-slot:label>
          <div :style="{color: secondaryColor}">
            {{$t('All persons')}}
          </div>
        </template>
      </v-radio>
      <v-radio
          value="INDIVIDUALS"
          :color="primaryColor"
      >
        <template v-slot:label>
          <div :style="{color: secondaryColor}">
            {{$t('Natural persons')}}
          </div>
        </template>
      </v-radio>
    </v-radio-group>
  </v-row>
</template>

<script>
export default {
  name: "HazardousCommodityTypes",
  props: {
    version: {
      required: false,
      type: String,
      default: 'admin'
    }
  },
  data: function () {
    return {
      typesOBJ: {
        'restrictionType': 'DANGER',
        'restrictedOn': 'ALL'
      }
    }
  },
  watch: {
    typesOBJ: {
      handler (newObj) {
        this.$emit('onTypeChange', newObj);
      },
      deep: true
    }
  },
  computed: {
    primaryColor () {
      return this.version === 'admin' ? 'white' : '#607c24';
    },
    secondaryColor () {
      return this.version === 'admin' ? 'black' : 'rgba(0, 0, 0, 0.4)'
    }
  }
}
</script>

<style scoped>

</style>
