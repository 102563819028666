import eventBus, {SOCKET_MSG_EVENT} from "../eventBus";
import SocketMessageHandler from "../store/socketMessageHandler";

/**
 * Manages websocket connection
 */
export default class WebsocketManager {

    socket = null
    token = ''
    socketMessageHandler = new SocketMessageHandler();

    // Construct Socket url from root and latest jwt token
    getWsUrl(){
        return `${process.env.VUE_APP_SOCKET_URL}/tariffic-sockets?jwt=${this.token}`;
    }

    // Creates socket client for backend/frontend communication
    startSocketClient() {
        console.log("Starting WebSocket client");
        const url = this.getWsUrl();
        this.socket = new WebSocket(url);
        this.socket.onopen = () => {
            console.log("Socket open");
        };
        this.socket.onerror = (event) => {
            console.error('Websocket has encountered an error', event);
        }

        this.socket.onmessage = (event) => {
            eventBus.$emit(SOCKET_MSG_EVENT, event);
            this.socketMessageHandler.handleMessage(event);
        };
        this.socket.onclose = (event) => {
            console.log("Socket closed with code [" + event.code + '] - ' + event.reason);
        };
    }

    // refreshed locally stored jwt token. Closes client if set to empty
    refreshToken(newToken) {
        this.token = newToken;

        if (this.token === '') {
            console.log('Closing websocket due to empty token');
            this.close();
        } else {
            console.log('JWT token updated, restarting socket');
            this.reInitSocketConnection();
        }

    }

    // Closes client if open
    close(){
        if (this.socket != null && this.socket.readyState === this.socket.OPEN) {
            this.socket.close();
            this.socket = null;
        }
    }

    // Reinitialize socket connection
    reInitSocketConnection(){
        this.close();

        setTimeout(() => {
            const socket = this.socket;
            if (socket == null || (socket.readyState === socket.CLOSED || socket.readyState !== socket.CLOSING)) {
                this.startSocketClient();
            }

        }, 2000);
    }
}