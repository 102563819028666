<template>
  <div class="mainContainer">
    <v-icon
        :color="color ? color : '#607c24'"
        size="150"
    >
      mdi-database-off
    </v-icon>
    <p
        class="mainText pa-0 ma-0"
        :style="{color: color ? color : '#607c24'}"
    >
      {{$t('No user added training data instances')}}
    </p>
    <p class="subText mt-1">
      {{$t('Looks like there are no training data instances added yet')}}
    </p>
  </div>
</template>

<script>
export default {
  name: "TarifficNoTrainingData",
  props: ['color']
}
</script>

<style scoped>
.mainContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.mainText {
  font-weight: 400;
  font-size: 1.2rem;
}
.subText {
  color: rgba(0, 0, 0, 0.4);
  font-size: 0.9rem;
  padding: 0;
  margin: 0;
}
</style>