<template>
  <v-form ref="form" class="mt-3">
    <v-text-field
        class="form-bnt-radius"
        label="Elektronski naslov"
        v-model="email"
        :rules="emailRules"
        type="input"
        solo
        flat
        @keyup.enter.native="onNewUser"
    />

    <div class="buttonContainer">
      <v-btn rounded class="pa-6"
             :disabled="!validEmail"
             @click="onNewUser"
      >
        Dodaj novega uporabnika
      </v-btn>
    </div>
  </v-form>
</template>

<script>
export default {
  name: "NewUserForm",
  data: function () {
    return {
      email: '',
      emailRules: [
        v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'Elektronski naslov mora biti veljaven'
      ]
    }
  },
  computed: {
    validEmail() {
      return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.email)
    }
  },
  methods: {
    onNewUser() {
      const validForm = this.$refs.form.validate();
      if (validForm) {
        this.$emit('onNewUser', {
          email: this.email
        });
      }
    },
    reset() {
      this.email = '';
    }
  }
}
</script>

<style scoped>
.form-bnt-radius {
  border-radius: 15px !important;
}
.buttonContainer {
  display: flex;
  justify-content: end;
}
</style>
