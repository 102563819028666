import {createCustomerAPI, deleteCustomer, getCustomers} from "../api/customers";
import {store} from "../store";
export const customer = {
    namespaced: true,
    state: {
        addCustomer: {
            email: '',
            name: '',
            password: '',
            customerType: '',

            committedId: '',
            contractId: '',
            branchId: '',
        },
        selectedLicense: '',
        customers: '',
        allTypes: [
            {
                name: 'API potrošnik',
                value: 1
            },
            {
                name: 'Lokalna instanca',
                value: 2
            }
        ],
        selectedType: ''
    },
    mutations: {
        setCustomer(state, customerPartial) {
            state.addCustomer = {
                ...state.addCustomer,
                ...customerPartial
            }
        },
        setCustomers(state, customers) {
            console.log('setting custoemr to', customers)
            state.customers = customers;
        },
        addCustomer(state, customers) {
            if (!state.customers) state.customers = [];
            state.customers = [
                ...state.customers,
                ...customers
            ];
        },
        clearAddCustomer(state) {
            state.addCustomer = {
                email: '',
                name: '',
                password: '',
                subscriptionId: '',
                customerType: '',

                committedId: '',
                contractId: '',
                branchId: '',
            }
            state.selectedLicense = '';
        },
        setSelectedType(state, type) {
            state.selectedType = type;
        },
        setSelectedLicence(state, lic) {
            state.selectedLicense = lic;
        },
        deleteCustomer(state, customerId) {
            state.customers = state.customers.filter((it) => it.id !== customerId);
        },
        updateCustomer(state, customer) {
            state.customers = state.customers.map((it) => {
                if (it.id === customer.id) {
                    return {
                        ...it,
                        ...customer
                    }
                } else {
                    return {
                        ...it
                    }
                }
            })
        }
    },
    actions: {
        setCustomerPartial(context, customerPartial) {
            context.commit('setCustomer', customerPartial);
        },
        async createCustomer(context) {
            const customerCreateReq = context.state.addCustomer;
            const subscription = context.state.selectedLicense;
            const cutomerType = context.state.selectedType;
            const customer = await createCustomerAPI(
                {
                    ...customerCreateReq,
                    subscriptionId: subscription.id,
                    customerType: cutomerType.value
                }
            );
            context.commit('clearAddCustomer');
            await store.dispatch('subscription/getCustomerSubscription', customer.id);
            context.commit('addCustomer', [customer]);
        },
        setSelectedType(context, type) {
            context.commit('setSelectedType', type);
        },
        setSelectedLicence(context, lic) {
            context.commit('setSelectedLicence', lic)
            if (lic) {
                let id = 1;
                switch (lic.id) {
                    case 'f76034a3-7ab9-4a8c-b217-9e2ae0e4bb8f':
                        id = 1;
                        break
                    case 'e1bf0870-cbdb-4873-b1ac-65718023d816':
                        id = 1;
                        break
                    case '862da9c2-2961-4a2b-8f5b-8f86d01a5638':
                        id = 2;
                        break
                }
                const selectedType = context.state.allTypes.find((it) => it.value === id);
                if (selectedType) {
                    context.commit('setSelectedType', selectedType);
                }
            }
        },
        async removeCustomer(context, customerId) {
            await deleteCustomer(customerId);
            context.commit('deleteCustomer', customerId)
        },
        async getCustomers(context) {
            const customers = await getCustomers();
            context.commit('setCustomers', customers);
        },
        async updateCustomer(context, customer) {
            context.commit('updateCustomer', customer);
        }
    },
    getters: {
        addCustomer: (state) => state.addCustomer,
        selectedLicence: (state) => state.selectedLicense,
        allTypes: (state) => state.allTypes,
        selectedType: (state) => state.selectedType,
        customers: (state) => state.customers
    }
}