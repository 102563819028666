<template>
  <div>
    <canvas id="chart"></canvas>
  </div>
</template>

<script>
import Chart from 'chart.js'
export default {
  name: "Chart",
  props: ['label'],
  data() {
    return {
      trafficData: {
        type: "bar",
        data: {
          labels: [],
          datasets: [
            {
              label: this.label,
              data: [],
              backgroundColor: "rgba(54,73,93,.5)",
              borderColor: "#36495d",
              borderWidth: 3
            }
          ]
        },
        options: {
          responsive: true,
          lineTension: 1,
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                  padding: 25
                }
              }
            ]
          }
        }
      },
      initialized: false
    }
  },
  async mounted() {
    try {
      const stats = (await this.$http.get(`${process.env.VUE_APP_API_URL}/traffic/report`)).data
      let data = []
      let labels = []
      let totalCount = 0
      stats.forEach((item) => {
        labels.push(
            `${item.from_hour < 10 ? `0${item.from_hour}:00` : item.from_hour}:00 - ${item.to_hour < 10 ? `0${item.to_hour}:00` : `${item.to_hour}:00`}`
        )
        data.push(
            item.request_count
        )
        totalCount += item.request_count
      })
      this.trafficData.data.datasets[0].data = data
      this.trafficData.data.labels = labels
      this.initialized = true
      const ctx = document.getElementById('chart')
      console.log(data, labels)
      console.log(this.trafficData)
      new Chart(ctx, this.trafficData)
      this.$emit('onCount', totalCount);
    }catch (e) {
      console.log(e)
    }
  }
}
</script>

<style scoped>

</style>
