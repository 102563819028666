import {store} from "../store";

export default class SocketMessageHandler {

    handleMessage(msg) {
        const msgContent = msg.data;
        if (!msgContent) {
            console.log('Could not decode message, skipping ...');
            return
        }

        let jOBJ = ''
        try {
            jOBJ = JSON.parse(msg.data);
        } catch (e) {
            console.log('Could not parse to json, skipping ...');
            return;
        }

        switch (jOBJ.type) {
            case "BULK_IMPORT_UPDATE":
                return this.handleBulkImportUpdate(jOBJ.payload);
            case "RETRAIN_PROCEDURE_STATE":
                return this.handleRefreshInProgressUpdate(jOBJ.payload)
            default:
                console.log(jOBJ)
                console.log('Unknown type of message, skipping ...');
        }
    }

    /**
     * Updates store values accordingly
     * based on totalCount and added fields
     * @param payload - payload of the socket message
     */
    async handleBulkImportUpdate({totalCount, added}) {
        const progress = totalCount > 0 ? added / totalCount : 0;
        if (progress === 1) {
            // Set state to finished
            await store.dispatch('bulk-settings/setStats', {
                currentState: 'WAITING'
            });
        }
        await store.dispatch('bulk-settings/setStats', {
            progress: Math.round(progress * 100)
        })
    }

    /**
     * Updates store value for refreshInProgress
     * @param payload - payload of the socket message
     */
    async handleRefreshInProgressUpdate(payload) {
        const state = payload.state
        await store.commit('bulk-settings/setRefreshInProgress', state);
    }
}