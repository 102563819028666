<template>
  <div v-if="initialized">
    <DecisionModal ref="decisionModal"/>
    <AddCustomerDialog
        ref="addCustomerDialog"
        @addCustomer="onAddCustomer"
    />
    <CustomersTable
        :customers="customers"
        @editItem="editItem"
        @deleteItem="deleteItem"
        @update:dialog="() => showAddCustomerDialog()"
        @infoItem="handleInfo"
        @planChange="planChange"
    />
    <AddEditDialog
        :id="editedItem.id ? editedItem.id : ''"
        :dialog="dialog"
        :editedItemProp="editedItem"
        @update:dialog="(val) => dialog = val"
        @update:editedItem="(val) => editedItem = val"
    />
    <DeleteDialog
        :id="editedItem.id ? editedItem.id : ''"
        :dialogDelete="dialogDelete"
        @update:dialogDelete="(val) => dialogDelete = val"
    />
  </div>
</template>

<script>
import DeleteDialog from "./DeleteDialog";
import AddEditDialog from "./AddEditDialog";
import CustomersTable from "./CustomersTable";
import DecisionModal from "../DecisionModal";
import {deleteCustomer} from "../../api/customers";
import {subscriptions} from "../../store/subscriptions";
import AddCustomerDialog from "./AddCustomerDialog";

export default {
  name: "CustomersManager",
  components: {AddCustomerDialog, CustomersTable, AddEditDialog, DeleteDialog, DecisionModal},
  data() {
    return {
      dialog: false,
      dialogDelete: false,
      editedItem: {email: '', name: '', password: '', subscriptionId: null, customerId: ''},
      initialized: false
    }
  },
  watch: {
    dialog(val) {
      return val || this.unsetEditedItem()
    }, // if dialog is changed to false unset editedItem
    dialogDelete(val) {
      return val || this.unsetEditedItem()
    },
    async customers(val) {
      console.log(val, "customer watcher")
      if (val) {
        const promises = []
        this.customers.forEach((customer) => {
          const promise = this.$store.dispatch('subscription/getCustomerSubscription', customer.id);
          promises.push(promise)
        });
        await Promise.all(promises)
        this.initialized = true
      }
    }
  },
  methods: {
    editItem(item) {
      this.editedItem = {...item, customerId: item.id, subscriptionId: subscriptions[item.id]}
      this.dialog = true
    },
    async deleteItem(item) {
      this.editedItem = {...item}
      const res = await this.$refs.decisionModal.showModal({
        message: `Ali ste prepičani, da želite izbrisati stranko ${item.name} s seznama ? Vse informacije o belezenju klicev bodo izbrisane, `,
        title: 'Brisanje stranke',
        positiveDecision: 'Da',
        negativeDecision: 'Ne'
      });
      if (res) {
        await this.$store.dispatch('customer/removeCustomer', item.id);
        await deleteCustomer(item.id);
      }
    },
    unsetEditedItem() {
      this.editedItem = {email: '', name: '', password: '', subscriptionId: null, customerId: ''}
    },
    async planChange({customer, plan}) {
      await this.$store.dispatch('subscription/updateCustomerSubscription', {
        customerId: customer.id,
        planId: plan ? plan.id : null
      });
    },
    handleInfo(it) {
      this.$router.push({
        name: 'CustomerInformation',
        params: {
          id: it.id
        }
      })
    },
    showAddCustomerDialog() {
      this.$refs.addCustomerDialog.openDialog();
    },
    async onAddCustomer() {
      try {
        await this.$store.dispatch('customer/createCustomer');
        this.$refs.addCustomerDialog.closeDialog();
      } catch (e) {
        // Do nothing
      }
    }
  },
  async created() {
    await this.$store.dispatch('customer/getCustomers');
  },
  computed: {
    subscriptions() {
      return this.$store.getters["subscription/subscriptionByCustomerId"];
    },
    customers() {
      return this.$store.getters['customer/customers'];
    }
  },
}
</script>

<style scoped>
</style>