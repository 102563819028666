export const hazardTypesMixin = {
    methods: {
        getTypeString(type) {
            switch (type) {
                case 'DANGER':
                    return this.$t('Dangerous commodity');
                case 'FORBIDDEN':
                    return this.$t('Forbidden commodity');
                default:
                    return '';
            }
        },
        getRestrictedUponString(restrictedUpon) {
            switch (restrictedUpon) {
                case 'ALL':
                    return this.$t('On all persons')
                case 'INDIVIDUALS':
                    return this.$t('On natural persons')
                default:
                    return '';
            }
        }
    }
}
