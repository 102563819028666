<template>
  <div class="spinnerContainer">
    <v-progress-circular v-if="show" indeterminate :color="color" />
  </div>
</template>

<script>
export default {
  name: "Spinner",
  props: {
    color: {
      type: String,
      default: "#fff"
    },
  },
  data: function () {
    return {
      show: false
    }
  },
  created() {
    this.$store.watch(
        state => state.spinner.pendingRequest, () => {
          this.show = this.$store.state.spinner.pendingRequest > 0;
        }
    );
  }
}
</script>

<style>
.spinnerContainer {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
}
</style>
