<template>
  <div class="mt-10">
    <DecisionModal ref="decisionModal"/>
    <div
        @scroll="onScroll"
        style="max-height: 50vh; overflow-y: auto">
      <v-data-table
          :headers="headers"
          :hide-default-footer="true"
          :items="data"
          :items-per-page="-1"
      >

        <template v-slot:item.actions="{ item }">
          <v-icon
              class="ma-2"
              medium
              @click="onDelete(item)"
          >
            mdi-delete
          </v-icon>
        </template>

        <template v-slot:item.restrictionType="{ item }">
          {{ getTypeString(item.restrictionType) }}
        </template>

        <template v-slot:item.restrictedOn="{ item }">
          {{ getRestrictedUponString(item.restrictedOn) }}
        </template>

      </v-data-table>
    </div>
  </div>
</template>

<script>
import DecisionModal from "./DecisionModal";
import {hazardTypesMixin} from "../mixins/hazardTypes";

export default {
  name: "HazardousCommoditiesTable",
  components: {DecisionModal},
  mixins: [hazardTypesMixin],
  data: function () {
    return {
      headers: [
        {
          text: this.$t('Prefix'),
          align: 'start',
          sortable: false,
          value: 'tariffPrefix'
        },
        {
          text: this.$t('Type of restriction'),
          align: 'start',
          sortable: false,
          value: 'restrictionType'
        },
        {
          text: this.$t('Restricted upon'),
          align: 'start',
          sortable: false,
          value: 'restrictedOn'
        },
        {
          text: this.$t('Actions'),
          align: 'start',
          sortable: false,
          value: 'actions'
        }
      ]
    }
  },
  props: ['data'],
  methods: {
    onScroll({target: {scrollTop, clientHeight, scrollHeight}}) {
      if (scrollTop + clientHeight >= scrollHeight) {
        this.$emit('nextPage');
      }
    },
    async onDelete(entry) {
      const result = await this.$refs.decisionModal.showModal({
        message: this.$t('Are you sure you want to delete hazardous commodity entry ?'),
        title: this.$t('Deleting hazardous commodity entry'),
        positiveDecision: this.$t('Delete'),
        negativeDecision: this.$t('Dismiss')
      });
      if (result) {
        this.$emit('onDelete', entry);
      }
    }
  }
}
</script>

<style scoped>
/* Remove Arrows/Spinners at number inputs for Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.table-commodities {
  background-color: transparent !important;
}

.table-commodities >>> td {
  background-color: #ffffff;
  border-radius: 8px !important;
}

.table-commodities >>> table {
  border-spacing: 0.3rem 0.4rem !important;
}

.table_item {
  display: flex;
  flex-direction: column;
}
</style>
