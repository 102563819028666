<template>
  <button
      :style="{'--btn-color': btnColor}"
      class="d-flex align-center"
      @click="$emit('onClick')"
      :disabled="btnDisabled"

  >
    <div
        class="button_text mr-2 d-none d-sm-block"
    >
      {{ buttonText }}
    </div>
    <div class="button_icon">
      <img src="@/assets/ic_search.svg">
    </div>
  </button>
</template>

<script>
export default {
  name: "Button",
  props: {
    btnDisabled: Boolean,
    buttonText: String,
    btnColor: {
      type: String,
      default: "#000",
    },
  },
  computed: {
    cssStyles() {
      return {
        '--btn-color': this.btnColor,
      }
    },
    isTariffic() {
      return this.type === 'TARIFFIC'
    }
  }
}
</script>

<style scoped>
button {
  border-radius: 100px;
  background-color: var(--btn-color);
  color: #fff;
  padding: 9px 20px;
  font-family: 'Poppins', sans-serif;
}
button img {
  display: block;
}
.button_icon {
  width: 30px;
  height: 30px;
}
@media screen and (min-width: 600px) {
  button {
    padding: 15px 30px;
  }
}
</style>