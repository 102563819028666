<template>
  <v-dialog
      v-model="dialog"
      max-width="600px"
  >
    <v-card class="pl-5 pr-5 pt-5 pb-5">
      <AddNewTrainingInstanceForm
          v-if="trainingInstance"
          ref="myForm"
          :edit-mode="true"
          @dismiss="handleDismiss"
          @save="handleSave"
          :current-hs-description="trainingInstance.description"
          :current-hs-number="trainingInstance.code"
      />
    </v-card>
  </v-dialog>
</template>

<script>
import AddNewTrainingInstanceForm from "./AddNewTrainingInstanceForm";
export default {
  name: "EditTrainingInstanceModal",
  components: {AddNewTrainingInstanceForm},
  data: function () {
    return {
      dialog: false,
      trainingInstance: undefined
    }
  },
  methods: {
    handleDismiss() {
      this.$emit('dismiss')
    },
    handleSave() {
      this.$emit('save')
    },
    showModal(trainingInstance) {
      this.dialog = true;
      this.trainingInstance = trainingInstance;
    },
    hideModal() {
      this.dialog = false;
      this.trainingInstance = undefined;
    }
  }
}
</script>

<style scoped>

</style>