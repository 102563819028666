<template>
  <div>
    {{text}}
    <v-btn class="ma-2" v-for="language in languages" :key="language.code" @click="setLanguage(language)" :disabled="language.selected">
      <country-flag :country="language.code"></country-flag>
    </v-btn>
  </div>
</template>

<script>
import CountryFlag from 'vue-country-flag'

export default {
  name: "LanguagePicker",
  props: ['text'],
  data: function () {
    return {
      languages: [
        {
          'code': 'si',
          'selected': false
        },
        {
          'code': 'gb',
          'selected': true
        }
      ]
    }
  },
  components: {
    CountryFlag
  },
  methods: {
    setLanguage: function (language) {
      this.languages.forEach((language) => language.selected = false);
      language.selected = true
      this.$store.dispatch('language/loadLanguage', {
        'language': language.code
      })
    }
  },
  mounted() {
    // set language to whatever is stored in store on mounted (language and selected language should be the same)
    this.setLanguage(this.languages.filter((lang) => {return lang.code === this.$store.getters['language/language']})[0])
  }
}
</script>

<style scoped>

</style>
