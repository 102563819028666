<template>
  <div style="display: flex; align-items: center">
    <DecisionModal
        ref="decisionModal"
    />
    <v-select
        :items="items"
        @change="change"
        v-model="mValue"
        :value="mValue"
        return-object
        item-text="name"
        clearable
        color="#FFD400"
    >

    </v-select>
  </div>
</template>

<script>
import DecisionModal from "../DecisionModal";

export default {
  name: "PlanSelect",
  components: {DecisionModal},
  props: ['items', 'value'],
  data: () => {
    return {
      mValue: ''
    }
  },
  methods: {
    discard() {
      this.mValue = this.value
    },
    accept() {
      this.mValue = this.value
    },
    async change(v) {
      const res = await this.$refs.decisionModal.showModal({
        message: `Ali ste prepičani, da želite spremeniti naročninski plan za stranko ?`,
        title: 'Sprememba naročninskega plana za stranko',
        positiveDecision: 'Da',
        negativeDecision: 'Ne'
      });
      if (res) {
        this.$emit('change', v)
      } else {
        this.discard()
      }
    }
  },
  mounted() {
    this.mValue = this.value;
  }
}
</script>

<style scoped>

</style>