import {getAvailableCustomerPlans, getCustomerPlan, updateCustomerSubscription} from "../api/customers";

export const subscriptions = {
    namespaced: true,
    state: {
        allSubscriptions: [],
        customerSubscriptions: {}
    },
    mutations: {
        setAllSubscriptions(state, data) {
            state.allSubscriptions = data;
        },
        setCustomerSubscriptions(state, { customerId, subDTO }) {
            const newOBJ = {
                ...state.customerSubscriptions,
            };
            newOBJ[customerId] = subDTO;
            state.customerSubscriptions = newOBJ;
        }
    },
    actions: {
        async getAllSubscriptions(ctx) {
            const subs = await getAvailableCustomerPlans();
            ctx.commit('setAllSubscriptions', subs);
        },
        async getCustomerSubscription(ctx, customerId) {
            const plan = await getCustomerPlan(customerId);
            ctx.commit('setCustomerSubscriptions', {customerId, subDTO: plan})
        },
        async updateCustomerSubscription(ctx, { customerId, planId }) {
            await updateCustomerSubscription(
                customerId,
                planId
            );
            const plan = await getCustomerPlan(customerId);
            ctx.commit('setCustomerSubscriptions', {customerId, subDTO: plan});
        }
    },
    getters: {
        allSubscriptions(state) {
            return state.allSubscriptions;
        },
        subscriptionByCustomerId(state) {
            return state.customerSubscriptions;
        }
    }
}