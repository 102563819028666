import httpClient from "../utils/httpClient";

/**
 * Sings in a user
 */
export const loginUser = async (username, password) => {
    const res = await httpClient.post(`${process.env.VUE_APP_API_URL}/auth/login`, {
        username,
        password
    })
    return res.data
}
