<template>
  <div class="mainContainer">
    <div class="genderContainer">
      <div class="genderItem mt-2"
           v-for="item in chartData"
           :key="item.name"
      >
        <div class="circle" :style="{backgroundColor: item.color}"/>
        <div class="ml-4 genderItemMainText">
          {{ item.name }}
          <v-tooltip bottom v-if="item.tooltip">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                  small
                  color="gray"
                  v-bind="attrs"
                  v-on="on"
              >mdi-information</v-icon>
            </template>
            <span>{{item.tooltip}}</span>
          </v-tooltip>
        </div>
        <div class="ml-8 genderItemPercentText" :style="{color: color ? color : '#607c24'}">
          {{ getPercentage(item) }} %
        </div>
      </div>
    </div>
    <div class="small-box ml-4">
      <canvas :id="id"></canvas>
    </div>
  </div>
</template>
<script lang="js">
import Chart from 'chart.js'

export default {
  name: "TarifficPie",
  props: ["chartData", "id", "color"],
  mounted() {
    this.drawGraph()
  },
  computed: {
    totalCount() {
      let count = 0
      this.chartData.forEach((it) => {
        count += it.count
      })
      return count
    },
    userGenderData() {
      return {
        type: "doughnut",
        data: {
          labels: this.chartData.map((it) => it.name),
          datasets: [{
            data: this.chartData.map((it) => it.count),
            backgroundColor: this.chartData.map((it) => it.color)
          }]
        },
        options: {
          maintainAspectRatio: false,
          rotation: Math.PI,
          circumference: Math.PI,
          responsive: true,
          legend: {
            display: false
          },
          tooltips: {
            enabled: true
          },
          elements: {
            arc: {
              borderWidth: 0
            }
          }
        }
      }
    }
  },
  methods: {
    getPercentage(it) {
      const totalCount = this.totalCount
      return Math.round(it.count / (totalCount) * 100)
    },
    drawGraph() {
      const ctx = document.getElementById(this.id);
      new Chart(ctx, this.userGenderData);
    }
  },
  watch: {
    userGenderData() {
      this.drawGraph()
    }
  }
}
</script>

<style scoped>

.mainContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: none;
}

.genderContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.circle {
  width: 14px;
  height: 14px;
  border-radius: 50%;
}

.genderItem {
  display: flex;
  justify-content: center;
  align-items: center;
}

.genderItemMainText {
  color: rgba(0, 0, 0, 0.4);
  font-family: 'Poppins', sans-serif;
  font-size: 0.9rem;
}

.genderItemPercentText {
  font-weight: bold;
  font-family: 'Poppins', sans-serif;
  margin-left: auto;
  font-size: 0.9rem;
}
</style>
