<template>
  <div class="mainContainer">
    <v-img
        class="logoImage"
        src="@/assets/solvesall_logo.png"
        max-width="500"
    />
  </div>
</template>

<script>
export default {
  name: "SolvesallSplash"
}
</script>

<style scoped>
.mainContainer {
  height: 100vh;
  width: 100vw;
  background-color: #2D323A;
  display: flex;
  justify-content: center;
  align-items: center;
}
.logoImage {
  animation-name: fade;
  animation-duration: 2.5s;
}

@keyframes fade {
  from {opacity: 0}
  to {opacity: 1}
}
</style>
