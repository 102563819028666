<template>
  <v-row style="overflow-x: hidden; overflow-y: hidden">
    <v-dialog
        v-model="dialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar
            dark
            color="#FBCB07"
        >
          <v-btn
              icon
              dark
              @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title class="app_bar_item">
            Dodajanje nove stranke
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
                dark
                text
                @click="dialog = false"
            >
              Zapri
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <v-row class="justify-center">
          <v-col class="col-lg-5">
            <v-form ref="customerInfoForm">

              <v-subheader class="subHeader">
                Osnovni podatki o stranki
              </v-subheader>

              <div class="ml-4">
                <v-text-field
                    :rules="customerNameRule"
                    color="#FBCB07"
                    :value="currentCustomer.name"
                    @change="(it) => $store.dispatch('customer/setCustomerPartial', { name: it })"
                    label="Ime stranke"
                />

                <v-text-field
                    :rules="emailRule"
                    color="#FBCB07"
                    :value="currentCustomer.email"
                    @change="(it) => $store.dispatch('customer/setCustomerPartial', { email: it })"
                    label="Elektronski naslov stranke"
                />

                <v-text-field
                    v-model="currentCustomer.password"
                    :rules="passwordRule"
                    label="Geslo"
                    color="#FBCB07"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="showPassword ? 'text' : 'password'"
                    @click:append="showPassword = !showPassword"
                />

                <v-text-field
                    :rules="committedIdRule"
                    color="#FBCB07"
                    :value="currentCustomer.committedId"
                    @change="(it) => $store.dispatch('customer/setCustomerPartial', { committedId : it })"
                    label="Komitent ID"
                />

                <v-text-field
                    :rules="contractIdRule"
                    color="#FBCB07"
                    :value="currentCustomer.contractId"
                    @change="(it) => $store.dispatch('customer/setCustomerPartial', { contractId: it })"
                    label="Pogodba ID"
                />

                <v-text-field
                    color="#FBCB07"
                    :value="currentCustomer.branchId"
                    @change="(it) => $store.dispatch('customer/setCustomerPartial', { branchId: it })"
                    label="Podružnica ID"
                />

                <v-text-field
                    color="#FBCB07"
                    :value="selectedCustomerType ? selectedCustomerType.name : ''"
                    item-text="name"
                    readonly
                    disabled
                    label="Tip stranke"
                />
              </div>
            </v-form>
          </v-col>

          <v-col class="col-lg-5">
            <v-subheader class="subHeader">
              Osnovni podatki o izbranem naročninskem planu
            </v-subheader>

            <div class="ml-4">
              <v-form ref="customerSubForm">
                <v-select
                    :items="customersSubscriptions"
                    @change="(v) => handleSubscriptionChange(v)"
                    :rules="subscriptionPlanRule"
                    item-text="name"
                    :hint="`${selectedLicence.name} - ${selectedLicence.shortName}`"
                    return-object
                    color="#FBCB07"
                    label="Narocninski plan"
                    class="mb-2"
                />
                <v-text-field
                    readonly
                    disabled
                    color="#FBCB07"
                    label="Cena klica z DDV"
                    :value="selectedLicence ? selectedLicence.priceVAT ? `${selectedLicence.priceVAT}€` : 'Po pogodbi' : ''"
                />
                <v-text-field
                    readonly
                    disabled
                    color="#FBCB07"
                    label="Cena klica brez DDV"
                    :value="selectedLicence ? selectedLicence.priceNonVAT ? `${selectedLicence.priceNonVAT}€` : 'Po pogodbi' : ''"
                />
                <v-text-field
                    readonly
                    disabled
                    color="#FBCB07"
                    label="Datum veljavnosti"
                    :value="selectedLicence ? convertToDateFormat(selectedLicence.dateValid) : ''"
                />
                <v-text-field
                    readonly
                    disabled
                    color="#FBCB07"
                    label="Enota mere"
                    :value="selectedLicence ? selectedLicence.unitOfMeasurement : ''"
                />
                <v-text-field
                    readonly
                    disabled
                    color="#FBCB07"
                    label="Šifra CRS"
                    :value="selectedLicence ? selectedLicence.numberCRS : ''"
                />
              </v-form>
            </div>
          </v-col>

        </v-row>

        <div class="buttonContainer ml-3 mt-10">
          <v-btn
              color="#FBCB07"
              text-color="white"
              class="pt-5 pb-5 pr-12 pl-12"
              @click="handleCustomerAdd"
          >
                  <span
                      style="color: white; text-decoration: none">
                    Dodaj novo stranko
                  </span>
          </v-btn>
        </div>

      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>

import {toDateFormat} from "../../utils/date";

export default {
  name: "AddCustomerDialog",
  data() {
    return {
      dialog: false,
      customerNameRule: [
        v => !!v || '* Ime stranke je obvezen parameter'
      ],
      emailRule: [
        v => !!v || '* Elektronski naslov stranke je obvezen parameter',
        v => /.+@.+\..+/.test(v) || '* Elektronski naslov mora biti veljaven'
      ],
      committedIdRule: [
        v => !!v || '* Komitent ID je obvezen parameter'
      ],
      contractIdRule: [
        v => !!v || '* Pogodova ID je obvezen parameter'
      ],
      subscriptionPlanRule: [
        v => {
          return !!v || '* Narocniski plan je obvezen parameter';
        }
      ],
      passwordRule: [
        v => !! v || '* Geslo je obvezen parameter'
      ],
      showPassword: false
    }
  },
  methods: {
    openDialog() {
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
    },
    convertToDateFormat(unixTimestamp) {
      return toDateFormat(unixTimestamp * 1000);
    },
    handleCustomerAdd() {
      const validCustomerForm = this.$refs.customerInfoForm.validate();
      const validSubscriptionForm = this.$refs.customerSubForm.validate();
      const validForm = validCustomerForm && validSubscriptionForm;

      if (validForm) {
        this.$emit('addCustomer');
      }
    },
    handleSubscriptionChange(newVal) {
      this.$store.dispatch('customer/setSelectedLicence', newVal);
    }
  },
  computed: {
    customersSubscriptions() {
      return this.$store.getters['subscription/allSubscriptions'];
    },
    currentCustomer() {
      return this.$store.getters['customer/addCustomer'];
    },
    allCustomerTypes() {
      return this.$store.getters['customer/allTypes'];
    },
    selectedCustomerType() {
      return this.$store.getters['customer/selectedType'];
    },
    selectedLicence() {
      return this.$store.getters['customer/selectedLicence'];
    }
  }
}
</script>

<style scoped>
.app_bar_item {
  font-weight: 500;
  font-size: 1.3rem;
  color: white;
  font-family: 'Poppins', sans-serif;
}

.buttonContainer {
  position: absolute;
  bottom: 120px;
  right: 120px;
}

.importProcessItem {
  color: #FBCB07;
  font-size: 1.25rem;
  font-weight: bold;
}

.subHeader {
  color: #FBCB07;
  font-weight: bold
}

.warnMsg {
  color: red;
  font-size: 0.75rem;
  margin-left: 10px;
  font-weight: 400;
}

.infoTitle {
  color: #FBCB07;
  font-weight: bold
}

.infoItem {
  color: rgba(0, 0, 0, 0.7);
  font-size: 0.95rem;
  margin-left: 5px;
  font-weight: 500;
}
</style>