<template>
  <v-row class="mt-2 justify-center">
    <v-col
        class="col-lg-5 col-sm-12"
    >
      <TarifficMonitoringSearchFilter
          class="ml-5"
          :selected-day="selectedDay"
          :selected-month="selectedMonth"
          :selected-year="selectedYear"
          @filter-change="onFilterChange"
          color="black"
      />
      <p class="ml-5 subSectionTitle">
        {{$t('Distribution of API calls')}}
      </p>
      <v-divider class="mt-2 ml-5 mb-5"/>
      <TarifficValueGraph
          v-if="statisticsReport && graphLabelData && graphValueData"
          :value-data="graphValueData"
          :value-label-data="graphLabelData"
          id="consumptionGraph"
          :invert-axis="true"
          min-height="60vh"
          color="rgba(255, 255, 255, 0.5)"
      />
    </v-col>
    <v-col
        class="col-lg-7 col-sm-12"
    >
      <p class="subSectionTitle">
        {{$t('Confidence of predictions')}}
      </p>
      <v-divider class="mt-2 mb-2"/>
      <TarifficPie
          v-if="pieGraphData"
          id="confidencePie"
          :chart-data="pieGraphData"
          color="black"
      />
      <TarifficNoData
          v-else-if="!isFetching"
          class="mt-3"
          color="black"
      />

      <p class="subSectionTitle mt-5">
        {{$t('Api calls statistics')}}
      </p>
      <v-divider class="mt-2 mb-2" />
      <TarifficNumberOfCalls
          :api-calls-obj="apiCallsCount"
          v-if="apiCallsCount"
          color="black"
      />
      <TarifficNoData
          v-else-if="!isFetching"
          class="mt-3"
          color="black"
      />
    </v-col>
  </v-row>
</template>

<script>
import TarifficMonitoringSearchFilter from "./tariffic/components/TarifficMonitoringSearchFilter";
import TarifficValueGraph from "./tariffic/components/TarifficValueGraph";
import TarifficPie from "./tariffic/components/TarifficPie";
import TarifficNoData from "./tariffic/components/TarifficNoData";
import TarifficNumberOfCalls from "./tariffic/components/TarifficNumberOfCalls";
export default {
  name: "TarifficMonitoring",
  components: {TarifficNumberOfCalls, TarifficNoData, TarifficPie, TarifficValueGraph, TarifficMonitoringSearchFilter},
  methods: {
    async onFilterChange(it) {
      await this.$store.dispatch('customers-statistics/getStats',{
        ...it,
        customerId: this.$route.params.id
      });
    }
  },
  computed: {
    statisticsReport() {
      return this.$store.getters["customers-statistics/statisticsReport"];
    },
    graphLabelData() {
      return this.$store.getters["customers-statistics/graphLabelData"];
    },
    graphValueData() {
      return this.$store.getters["customers-statistics/graphValueData"];
    },
    pieGraphData() {
      const data = this.$store.getters['customers-statistics/statisticsReport'];
      if (!data) return '';
      const res = [
        {
          name: this.$t('Confident predictions'),
          color: 'rgba(255, 255, 255, 0.5)',
          count: data.confidentPredictionCount
        },
        {
          name: this.$t('Non-confident predictions'),
          color: 'black',
          count: data.nonConfidentPredictionCount
        }
      ];
      if (data.confidentPredictionCount === 0 && data.nonConfidentPredictionCount === 0) {
        return ''
      }
      return res;
    },
    apiCallsCount() {
      const data = this.$store.getters['customers-statistics/statisticsReport'];
      if (!data) return ''
      return {
        totalApiCalls: data.totalSumCount,
        confidentPredictionCount: data.confidentPredictionCount,
        nonConfidentPredictionCount: data.nonConfidentPredictionCount,
        accessToTranslatorServiceCount: data.accessToGoogleApiTranslator,
        ...data
      }
    },
    isFetching() {
      return this.$store.getters['customers-statistics/isFetching'];
    }
  },
  data: () => {
    return {
      selectedDay: new Date().getDate(),
      selectedMonth: new Date().getMonth() + 1,
      selectedYear: new Date().getFullYear()
    }
  },
  destroyed() {
    this.$store.dispatch('customers-statistics/clearStats')
  },
  beforeDestroy() {
    this.$store.dispatch('customers-statistics/clearStats')
  }
}
</script>

<style scoped>
.searchFilterTitle {
  color: #e4c932;
}
.subSectionTitle {
  color: black;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
  font-size: 1.5rem;
}
</style>