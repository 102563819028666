<template>
  <div class="mainContainer">
    <div class="contentContainer">
      <div class="tarifficTitleContainer">
        <p class="tarifficTitle">
          Tariffic
        </p>
        <p class="tarifficText">
          Tariffic's AI based engine ensures that all goods, parcels and small packets you ship or receive have the correct HS Code automatically and quickly assigned.
        </p>
        <p class="tarifficText">
          A correct HS Code will help to ensure a fast and smooth customs procedure which will increase your service quality.
        </p>
        <div class="imageContainer">
          <div class="img-1">
            <img
                :src="`${appBaseURL}/tariffic_img/pack_scan.jpeg`"
                style="width: 300px"
            >
          </div>
          <div class="img-2">
            <img
                :src="`${appBaseURL}/tariffic_img/whouse.jpeg`"
                style="width: 200px"
            >
          </div>
          <div class="img-3">
            <img
                :src="`${appBaseURL}/tariffic_img/whouse2.jpeg`"
                style="width: 168px"  />
          </div>
          <div class="cube-1">

          </div>

          <div class="cube-2">

          </div>
        </div>
      </div>
      <div class="formContainerWrapper">
        <div class="formContainer">
          <p class="formTitle">
            LOGIN
          </p>
          <div class="form">
            <v-form ref="form">
              <v-text-field
                  color="#607c24"
                  placeholder="Email address"
                  :persistent-placeholder="true"
                  :rules="emailRules"
                  v-on:keyup.enter="handleLogin"
                  v-model="email"
                  single-line
              ></v-text-field>

              <v-text-field
                  color="#607c24"
                  placeholder="Password"
                  type="password"
                  :persistent-placeholder="true"
                  v-on:keyup.enter="handleLogin"
                  v-model="password"
                  single-line
              ></v-text-field>
            </v-form>

            <div class="buttonContainer">
              <v-btn
                  color="#607c24"
                  text-color="white"
                  class="pt-5 pb-5 pr-12 pl-12"
                  @click="handleLogin"
              >
                <span style="color: white">
                     Submit
                </span>
              </v-btn>
            </div>
            <div class="mt-10 bottomTextContainer">
              <div class="alreadyHaveAccount">
                Don't have an account ?
              </div>
              <div class="proceedText">
                <a :href="`${appBaseURL}/inquiry`" style="text-decoration: none; color: #607c24">
                  Request API access
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="footerContent">
        <div class="footerTitle">
          Developed by
        </div>
        <img  :src="require('@/assets/solvesall_logo.png')" style="height: 18px" />
      </div>
    </div>
  </div>
</template>

<script>
import {logIn} from "../../api/tariffic-customer";

export default {
  name: "Login",
  computed: {
    appBaseURL() {
      return process.env.VUE_APP_API_URL
    }
  },
  data: () => {
    return {
      email: '',
      emailRules: [
        v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid'
      ],
      password: ''
    }
  },
  methods: {
    async handleLogin() {
      const valid = this.$refs.form.validate();
      if (!valid) {
        return await this.$store.dispatch('snackbar/fireAlert', {
          message: 'Error while logging in, please check credentials or contact us at info@solvesall.com',
          color: 'error'
        });
      }
      const res = (await logIn(this.email, this.password));
      await this.$store.dispatch('auth/setTokens', res);
      await this.$router.push({
        name: 'Main'
      })
    }
  }
}
</script>

<style scoped>
.mainContainer {
  background-color: #607c24;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}

html::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.tarifficTitle {
  font-weight: normal;
  font-size: 6rem;
  color: white;
  font-family: 'Poppins', sans-serif;
}

.formContainer {
  background-color: rgba(255, 255, 255, 0.98);
  padding: 30px 25px;
  margin-top: 25px;
  border-radius: 5px;
  min-width: 400px;
  box-shadow:   0 2.8px 2.2px rgba(0, 0, 0, 0.034),
  0 6.7px 5.3px rgba(0, 0, 0, 0.048),
  0 12.5px 10px rgba(0, 0, 0, 0.06),
  0 22.3px 17.9px rgba(0, 0, 0, 0.072),
  0 41.8px 33.4px rgba(0, 0, 0, 0.086),
  0 100px 80px rgba(0, 0, 0, 0.12);
}

.contentContainer {
  display: flex;
  flex-direction: row;
  padding: 0 100px;
  justify-content: space-between;
}

.formTitle {
  text-align: center;
  font-family: 'Roboto', sans-serif;
  color: #607c24;
  font-weight: 500;
  font-size: 2rem;
}

.tarifficTitleContainer {
  align-self: start;
  max-width: 450px;
}

.formContainerWrapper  {
  max-width: 500px;
  align-self: center;
}

.tarifficText {
  color: white;
  font-family: 'Poppins', sans-serif;
}

.form {
  margin-top: 40px;
}

.buttonContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.imageContainer {
  position: relative;
  height: 370px;
  width: auto;
}

.img-1 {
  position: absolute;
  left: 75px;
}

.img-2 {
  position: absolute;
  top:237px;
  left: 75px
}

.img-3 {
  position: absolute;
  top: 237px;
  left: calc(75px + 200px);
}

.cube-1 {
  width: 100px;
  height: 100px;
  background-color: #e4c932;
  position: absolute;
  left: 375px;
  top: 100px;
}

.cube-2 {
  height: 300px;
  width: 75px;
  top: 50px;
  background-color: #88bc0b;
  position: absolute;
}

.bottomTextContainer {
  display: flex;
}

@media only screen and (max-width: 1100px) {
  .contentContainer {
    flex-direction: column;
  }

  .tarifficTitleContainer {
    align-self: center;
    justify-content: center;
  }

  .imageContainer {
    display: none;
  }

  .formContainer {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 590px) {
  .tarifficTitle {
    font-size: 5rem;
  }
  .tarifficText {
    font-size: 0.9rem;
  }
  .formContainer {
    min-width: calc(100vw - 40px);
  }
  .bottomTextContainer {
    flex-direction: column;
  }
  .contentContainer {
    padding: 0 20px;
  }
}
.footerContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 15px;
}
.footerTitle {
  color: white;
  font-size: 0.85rem;
  margin-bottom: 10px;
  font-weight: 500;
  font-family: 'Roboto', sans-serif;
}

.modalTitle {
  color: #607c24;
}
.alreadyHaveAccount {
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.9rem;
  margin-left: 3px;
}
.proceedText {
  color: #607c24;
  font-weight: bold;
  text-decoration: none;
  font-size: 0.9rem;
  margin-left: 3px;
}
.proceedText:hover {
  cursor: pointer;
}
.progressContainer {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.alert {
  position: fixed;
  top:0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  min-width: 300px;
}
</style>