import httpClient from "../utils/httpClient";

/**
 * Gets description for given tariff prefix
 * @param tariffPrefix - tariff number prefix
 */
export const getTariffDescription = async (tariffPrefix) => {
    const res = await httpClient.get(`${process.env.VUE_APP_API_URL}/description/${tariffPrefix}`);
    return res.data;
}
