<template>
  <v-app v-if="loggedIn">
    <Snackbar/>
    <v-navigation-drawer v-model="drawer" app temporary>
      <v-img src="@/assets/posta_logo.png" min-height="70px"></v-img>
      <v-list dense nav>
        <span v-for="(navItem, index) in navItems" :key="navItem.name" class="pa-5 nav_item">
          <router-link :to="{name:navItem.name}" class="nav_item" v-if="!navItem.handler">
            <v-list-item link @click="selectedIndex=index" :class="selectedIndex === index ? 'selected' : ''"
              v-if="navItem.display">
              <v-list-item-icon>
                <v-icon>{{ navItem.icon }}</v-icon>
                <v-list-item-content>
                  <v-list-item-title class="ml-2 mt-1 font-weight-medium">
                    {{ strings['ROOT'][`displayName${navItem.displayName}`] }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item-icon>
            </v-list-item>
          </router-link>

          <template v-else>
            <v-list-item link @click="navItem.handler" :class="selectedIndex === index ? 'selected' : ''"
              v-if="navItem.display">
              <v-list-item-icon>
                <v-icon>{{ navItem.icon }}</v-icon>
                <v-list-item-content>
                  <v-list-item-title class="ml-2 mt-1 font-weight-medium">
                    {{ strings['ROOT'][`displayName${navItem.displayName}`] }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item-icon>
            </v-list-item>
          </template>
        </span>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app color="#FBCB07" min-height="70px" elevation="0">
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <div class="app_bar_container">
        <b class="app_bar_item" v-if="show">{{ strings['ROOT'][`appBar${navItems[selectedIndex].displayName}`] }}</b>
      </div>
      <LanguagePicker class="language_picker" :text="strings.ROOT.languagePicker" v-if="false"/>
    </v-app-bar>
    <v-main class="main_container">
      <v-container>
        <Spinner :color="secondaryColor"/>
        <router-view :secondaryColor="secondaryColor"></router-view>
      </v-container>
    </v-main>

    <v-footer app color="transparent footer">
      <div>
        Version: {{apiVersion}} Solvesall - CA
      </div>
    </v-footer>
  </v-app>
  <v-app v-else>
    <Snackbar/>
    <Spinner :color="secondaryColor"/>
    <router-view/>
  </v-app>
</template>

<script>
import Spinner from "../components/Spinner";
import LanguagePicker from "../components/LanguagePicker";
import Snackbar from "../components/Snackbar";
import {bus} from "../main";

export default {
  name: "PostaSLapp",
  components: {
    Spinner,
    Snackbar,
    LanguagePicker
  },
  props: {
    secondaryColor: {
      type: String,
      default: "#000"
    }
  },
  data: function () {
    return {
      drawer: false,
      navItems: [
        {
          'displayName': 'Classifier',
          'name': 'HSClassifier',
          'icon': 'mdi-chart-bell-curve',
          'appBar': 'HS-klasifikator demo aplikacija',
          'display': true
        },
        {
          'displayName': 'Monitoring',
          'name': 'Monitoring',
          'icon': 'mdi-monitor',
          'appBar': 'Pregled treninga in prometa',
          'display': true
        },
        {
          'displayName': 'HazardousCommodities',
          'name': 'MainDashboard',
          'icon': 'mdi-database-eye',
          'appBar': 'Pregled klasifikacijskih modelov',
          'display': true
        },
        {
          'displayName': 'UserManagement',
          'name': 'UserManager',
          'icon': 'mdi-account-multiple',
          'appBar': 'Pregled registriranih adminsitratorjev sistema',
          'display': false
        },
        {
          'displayName': 'CustomersManager',
          'name': 'CustomersManager',
          'icon': 'mdi-account-multiple',
          'appBar': 'Pregled strank',
          'display': true
        },
        {
          'displayName': 'HSMapping',
          'name': 'HsMapping',
          'icon': 'mdi-table-arrow-right',
          'appBar': 'Pregled preslikovalnih tabel',
          'display': false
        },
        {
          'displayName': 'HSHistory',
          'name': 'HSHistory',
          'icon': 'mdi-history',
          'appBar': 'Pregled zgodovine klasifikatorja',
          'display': true
        },
        {
          'displayName': 'HSDescription',
          'name': 'HSDescription',
          'icon': 'mdi-database',
          'display': false
        },
        {
          'displayName': 'Logout',
          'name': 'Odjava',
          'icon': 'mdi-logout',
          'display': true,
          'handler': () => this.$store.dispatch('auth/logout')
        }
      ],
      selectedIndex: 0
    }
  },
  computed: {
    strings() {
      return this.$store.getters['language/strings']
    },
    show() {
      const screen = this.$vuetify.breakpoint.name;
      return screen !== 'xs' && screen !== 'sm'
    },
    loggedIn() {
      return this.$store.getters['auth/isLoggedIn'] || this.$store.getters['auth/validRefreshToken']
    },
    apiVersion() {
      return this.$store.getters['version/apiVersion'];
    }
  },
  mounted() {
    const role = this.$store.getters['auth/getRole']
    document.title = 'Pošta Slovenije - HS klasifikator';
    if (!role || role !== 'ADMIN') {
      this.$store.dispatch('auth/logout');
      return this.$router.push({
        name: 'Login'
      });
    }

    this.$store.dispatch('auth/setLanguage', 'sl');
    if (this.$route.path !== '/classifier' && this.loggedIn) {
      this.$router.push({
        name: 'HSClassifier'
      });
    } else if (this.$route.path !== '/login' && !this.loggedIn) {
      this.$router.push({
        name: 'Login'
      })
    }
  },
  watch: {
    loggedIn(newVal) {
      if (!newVal && this.$route.path !== '/login') {
        this.$router.push({
          name: 'Login'
        })
      }
      if (!newVal) {
        this.selectedIndex = 0
      }
    },
    drawer(val) {
      bus.$emit('headerChange', val);
    }
  }
}
</script>

<style scoped>
body {
  font-family: 'Roboto', sans-serif;
}

.app_bar_item {
  font-weight: 500;
  font-size: 1.3rem;
}

.app_bar_container {
  margin-left: 2%;
}

.main_container {
  background-color: #FBCB07;
  color: #000;
}

.nav_item {
  text-decoration: none;
}

.selected {
  background-color: rgba(0, 0, 0, 0.1);
}

.language_picker {
  position: absolute;
  right: 2%;
  top: 20px;
}

.logo_container {
  width: 100%;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 0.9rem;
}
</style>
