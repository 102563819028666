<template>
  <div class="mt-10">
    <DecisionModal ref="decisionModal"/>
    <div
        @scroll="onScroll"
        style="max-height: 70vh; overflow-y: auto">
      <v-data-table
          class="mt-5"
          :headers="headers"
          :items="data"
          hide-default-footer
          :items-per-page="-1"
      >

        <template v-slot:item.notifyAnomaly="{ item }">
          <div class="switchContainer">
            <v-switch
                :false-value="false"
                :input-value="item.notifyAnomaly"
                color="success"
                @change="(e) => onNotifyAnomalyChange(e, item)"
            />
          </div>
        </template>
        <template v-slot:item.notifyReports="{ item }">
          <div class="switchContainer">
            <v-switch
                :false-value="false"
                :input-value="item.notifyReports"
                color="success"
                @change="(e) => onNotifyReportsChange(e, item)"
            />
          </div>
        </template>

        <template v-slot:item.actions="{ item  }">
          <v-icon
            class="ma-2"
            medium
            @click="onDelete(item)" >
            mdi-delete
          </v-icon>

        </template>

      </v-data-table>
    </div>
  </div>
</template>

<script>
import DecisionModal from "./DecisionModal";
export default {
  name: "UserTable",
  components: {DecisionModal},
  data: function () {
    return {
      headers: [
        {
          text: 'Elektronski naslov',
          align: 'start',
          sortable: false,
          value: 'email'
        },
        {
          text: 'Obveščevanje glede anomalij',
          align: 'center',
          sortable: false,
          value: 'notifyAnomaly'
        },
        {
          text: 'Prejemanje dnevnih poročil',
          align: 'center',
          sortable: false,
          value: 'notifyReports'
        },
        {
          text: 'Akcije',
          align: 'center',
          sortable: false,
          value: 'actions'
        }
      ]
    }
  },
  props: ['data'],
  methods: {
    onScroll({target: {scrollTop, clientHeight, scrollHeight}}) {
      if (scrollTop + clientHeight >= scrollHeight) {
        this.$emit('nextPage');
      }
    },
    async onDelete(item) {
      const result = await this.$refs.decisionModal.showModal({
        message: `Ali ste prepičani, da želite izbrisati uporabnika ${item.email} s sezanma ?`,
        title: 'Brisanje uporabnika',
        positiveDecision: 'Da',
        negativeDecision: 'Ne'
      })

      if (result) {
        this.$emit('onDelete', item);
      }
    },
    onNotifyAnomalyChange(change, item) {
      this.$emit('onUpdate', {
        ...item,
        notifyAnomaly: change ? change : false
      })
    },
    onNotifyReportsChange(change, item) {
      this.$emit('onUpdate', {
        ...item,
        notifyReports: change ? change : false
      });
    }
  }
}
</script>

<style scoped>
/* Remove Arrows/Spinners at number inputs for Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.table-commodities {
  background-color: transparent !important;
}

.table-commodities >>> td {
  background-color: #ffffff;
  border-radius: 8px !important;
}

.table-commodities >>> table {
  border-spacing: 0.3rem 0.4rem !important;
}

.table_item {
  display: flex;
  flex-direction: column;
}

.switchContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
