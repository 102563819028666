<template>
  <v-app class="background-color" v-if="!splash">
    <v-container>
      <Spinner :color="secondaryColor"/>
      <v-row class="solvesall-container">
        <v-spacer />
        <v-col class="pa-5" cols="12" sm="10" md="9" lg="7" xl="5">
          <Logo>
            <img src="@/assets/solvesall_logo.png">
          </Logo>
  
          <Search @onSearch="onSearch" :btnColor="secondaryColor"/>
          <div class="pt-5"></div> <!-- Padding compenzation for no translation switch -->
          <Results :secondaryColor="secondaryColor" :tariffColor="secondaryColor"/>
        </v-col>
        <v-spacer />
      </v-row>
    </v-container>
  </v-app>
  <SolvesallSplash v-else />
</template>

<script>
import Spinner from "../components/Spinner";
import SolvesallSplash from "./SolvesallSplash";
import Logo from "@/components/HSClassifier/Logo"
import Search from "@/components/HSClassifier/Search"
import Results from "@/components/HSClassifier/Results"

export default {
  name: "SolvesallApp",
  components: {SolvesallSplash, Spinner, Search, Results, Logo},
  props: {
    secondaryColor: {
      type: String,
      default: "#446C93",
    },
  },
  data: function () {
    return {
      splash: true
    }
  },
  methods: {
    onSearch(description) {
      this.$store.dispatch('prediction/getPrediction', {
        description: description,
        langDetection: true
      })
    },
    setLanguage(langCode) {
      this.$store.dispatch('language/loadLanguage', {
        'language': langCode
      })
    }
  },
  mounted() {
    this.setLanguage('gb')
    
    setTimeout(() => {
      this.splash = false;
    }, 1500)
  }
}
</script>

<style scoped>
.background-color {
  background-color: #2D323A;
}
.solvesall-container {
  background-color: #2D323A;
  color: #fff;
  font-weight: 300;
  padding-top: 90px;
  margin-bottom: 0;
  height: 100%;
}
.container { /* vuetify container: v-container */
  height: 100%;
}
</style>
