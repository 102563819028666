<template>
  <div class="lockedContainerMessage">
    <div style="display: flex;align-items: center;justify-content: center; flex-direction: column">
      <v-icon size="120" color="error">
        mdi-lock
      </v-icon>
      <p class="message">
        {{message}}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Locked",
  props: ['message']
}
</script>

<style scoped>
.lockedContainerMessage {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 45%;
}
.message {
  text-align: center;
  font-family: 'Poppins', sans-serif;
  color: red;
  font-weight: bold;
}
</style>