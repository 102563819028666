<template>
  <v-row>
    <v-col class="col-lg-12 pa-10">
      <v-col class="col-lg-3 mt-4">
        <DatePicker
            @onDate="onDate"
            color="#607c24"
        />
      </v-col>
      <ClassificationHistoryTable
          v-if="historyData"
          :data="historyData"
          @nextPage="fetchData"
      />
    </v-col>

    <v-fab-transition>
      <v-btn
          class="ma-2"
          color="white"
          fab
          dark
          small
          absolute
          bottom
          right
          large
          @click="downloadFile"
      >
        <v-icon color="#607c24">mdi-export-variant</v-icon>
      </v-btn>
    </v-fab-transition>
  </v-row>
</template>

<script>
import DatePicker from "../DatePicker";
import ClassificationHistoryTable from "../ClassificationHistoryTable";
import {paginationMixin} from "../../mixins/pagination";
import {downloadHistoryTariffic} from "../../api/tariffic-customer";
export default {
  name: "TarifficHistory",
  components: {ClassificationHistoryTable, DatePicker},
  mixins: [paginationMixin],
  methods: {
    async onDate(fromDate) {
      await this.$store.dispatch('tariffic-history/resetActiveDate', fromDate);
      this.setToDefault();
      await this.fetchData();
    },
    async fetchData() {
      if (this.fetchInProgress) {
        return false;
      }

      this.fetchInProgress = true;
      this.paginationInfo.limit = 20;

      await this.$store.dispatch('tariffic-history/fetchHistoryData', {
        offset: this.paginationInfo.offset,
        limit: this.paginationInfo.limit
      });
      this.fetchInProgress = false;
      this.markPageFetch();

      return true
    },
    async downloadFile() {
      const ONE_DAY_SECONDS = 3600 * 24;

      await downloadHistoryTariffic(
          this.activeDate,
          this.activeDate + ONE_DAY_SECONDS
      );
    }
  },
  computed: {
    historyData() {
      return [...this.$store.getters["tariffic-history/historyData"]];
    },
    isFetching() {
      return this.$store.getters["tariffic-history/isFetching"];
    },
    activeDate() {
      return this.$store.getters["tariffic-history/activeDate"];
    }
  }
}
</script>

<style scoped>

</style>