<template>
  <v-row justify="center" style="overflow-x: hidden; overflow-y: hidden">
    <v-dialog
        v-model="dialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar
            dark
            color="#607c24"
        >
          <v-btn
              icon
              dark
              @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title class="app_bar_item">
            {{ $t('Algorithm training data bulk import') }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
                dark
                text
                @click="dialog = false"
            >
              {{ $t('Close') }}
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <v-row class="justify-center align-center">
          <v-col class="col-lg-5">
            <v-form ref="importForm"
                    :disabled="this.importStats.currentState !== 'WAITING'"
            >
              <v-list
                  three-line
                  subheader
              >
                <v-subheader class="subHeader">
                  {{ $t('Bulk import settings') }}
                  <div>
                    <span v-if="!translationAllowed" class="warnMsg">
                      {{$t('(Your subscription plan does not support translations)')}}
                    </span>
                  </div>
                </v-subheader>
                <v-list-item>
                  <v-list-item-action>
                    <v-checkbox
                        :disabled="!languageDetectionEnabled || !translationAllowed"
                        :input-value="mySettings.languageAutoDetection"
                        color="#607c24"
                        @change="updateSettings({languageAutoDetection: !mySettings.languageAutoDetection})"
                    >

                    </v-checkbox>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t('Language auto detection') }}</v-list-item-title>
                    <v-list-item-subtitle>
                      If your descriptions are in unknown language or in mixed languages,
                      you can use auto detection feature to automatically detect language and
                      translate it
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-action>
                    <v-checkbox
                        :input-value="mySettings.translation"
                        :disabled="!translationEnabled || !translationAllowed"
                        color="#607c24"
                        @change="updateSettings({translation: !mySettings.translation})"
                    ></v-checkbox>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ $t('Translation') }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      If your descriptions are not in slovene language,
                      you can translate them from source language to slovene.
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-slide-y-transition>
                  <v-list-item v-if="mySettings.translation">
                    <v-list-item-action>
                      <v-select
                          @change="handleLanguageChange"
                          :value="myLanguage"
                          color="#607c24"
                          :items="langData"
                          item-value="value"
                          item-text="display"
                      />
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ $t('Source language') }}
                      </v-list-item-title>

                      <v-list-item-subtitle>
                        Please select source language of tariff number descriptions
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-slide-y-transition>
              </v-list>

              <v-divider class="ml-4"/>
              <v-subheader class="subHeader">
                {{ $t('Import format settings') }}
                <v-tooltip max-width="250px" right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        icon
                        class="ml-2"
                        v-bind="attrs"
                        v-on="on"
                    >
                      <v-icon
                          small
                      >
                        mdi-information
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>
                    {{ $t('Entered data has to be either in JSON or CSV format. For CSV format use "\\t" as separator, where first value is description and second is tariff number') }}
                  </span>
                </v-tooltip>
              </v-subheader>
              <v-container class="ma-0 pa-0 pl-1">
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      Import data format
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      Specify one of the supported formats of import data
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item-action>
                  <v-radio-group
                      :value="myFormat ? myFormat.value : ''"
                      @change="handleFormatChange"
                  >
                    <v-radio
                        color="#607c24"
                        v-for="format in formatOptions"
                        :key="format.value"
                        :label="`${format.display}`"
                        :value="format.value"
                    ></v-radio>
                  </v-radio-group>
                </v-list-item-action>
              </v-container>

              <v-list-item>
                <v-list-item-action>
                  <v-file-input
                      @change="handleFileUpload"
                      style="min-width: 200px"
                      color="#607c24"
                      :accept="`.${myFormat.value}`"
                      :error-message="readingFileErrMsg"
                  />
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ $t('Upload file') }}
                  </v-list-item-title>

                  <v-list-item-subtitle>
                    Upload file in selected format
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <div class="buttonContainer ml-3 mt-10">
                <v-btn
                    :disabled="importStats.currentState === 'WAITING'"
                    color="#607c24"
                    text-color="white"
                    class="pt-5 pb-5 pr-12 pl-12"
                    @click="$emit('onBulkStart')"
                >
                  <span
                      style="color: white; text-decoration: none">
                    {{ $t('Start bulk import') }}
                  </span>
                </v-btn>

                <v-btn
                    @click="clearImportData"
                    :disabled="importStats.currentState === 'WAITING'"
                    color="#607c24"
                    text-color="white"
                    class="ml-5 pt-5 pb-5 pr-12 pl-12"
                >
                  <span
                      style="color: white; text-decoration: none">
                    {{ $t('Clean imported data') }}
                  </span>
                </v-btn>
              </div>
            </v-form>
          </v-col>

          <v-col class="col-lg-6">
            <v-subheader class="subHeader">
              {{ $t('Import process progress') }}
            </v-subheader>
            <v-row class="align-center">
              <v-col class="col-lg-6">
                <v-list>
                  <v-list-item>
                    <v-list-item-title style="color: rgba(0, 0, 0, 0.5)">
                      {{ $t('Total sum of entries') }}
                    </v-list-item-title>
                    <span class="importProcessItem">
                      {{ importStats.totalCount }}
                    </span>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-title style="color: rgba(0, 0, 0, 0.5)">
                      {{ $t('Unique tariff number codes') }}
                    </v-list-item-title>
                    <span class="importProcessItem">
                        {{ importStats.totalCount }}
                    </span>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-title style="color: rgba(0, 0, 0, 0.5)">
                      Status of import process:
                    </v-list-item-title>
                    <span class="importProcessItem">
                        {{
                        importStats.currentState.split("").map((it, index) => index > 0 ? it.toLowerCase() : it.toUpperCase()).join("")
                      }}
                    </span>
                  </v-list-item>
                </v-list>
              </v-col>
              <v-col class="col-lg-6 justify-center" v-if="false">
                <v-progress-circular
                    :rotate="360"
                    :size="180"
                    :width="30"
                    color="#607c24"
                    :value="0"
                >
                  {{ importStats.progress ? importStats.progress : 'Waiting ...' }}
                </v-progress-circular>
              </v-col>
            </v-row>
            <v-divider class="mt-2"/>

            <v-subheader class="subHeader">
              {{ $t('Data to be imported') }}
            </v-subheader>

            <TrainingInstancesTable
                max-height="45vh"
                :data="entriesToBeExported"
                :data-fetch-in-progress="false"
                :no-action="true"
                max-line-width="465px"
            />
          </v-col>

        </v-row>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import languages from '../../../assets/languages.json';
import TrainingInstancesTable from "./TrainingInstancesTable";

export default {
  name: "AlgorithmDataImportForm",
  components: {TrainingInstancesTable},
  data() {
    return {
      dialog: false,
      langData: [...languages],
      formatOptions: [
        {
          value: 'json',
          display: 'JSON'
        },
        {
          value: 'csv',
          display: 'CSV'
        }
      ]
    }
  },
  methods: {
    openDialog() {
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
    },
    updateSettings(partialSettings) {
      this.$store.dispatch('bulk-settings/setSettings', partialSettings);
    },
    async handleFileUpload(files) {
      if (!files || files.length <= 0) {
        // Do nothing
      } else {
        try {
          await this.$store.dispatch('snackbar/startLoading');
          await this.$store.dispatch('bulk-settings/setRawEntries', files);
        } finally {
          await this.$store.dispatch('snackbar/stopLoading');
        }
      }
    },
    handleFormatChange(format) {
      if (!format) {
        return
      } else {
        this.$store.dispatch('bulk-settings/setSettings', {
          importDataFormat: format
        })
      }
    },
    clearImportData() {
      this.$store.dispatch('bulk-settings/clearImportData');
    },
    handleLanguageChange(lang) {
      this.$store.dispatch('bulk-settings/setSettings', {
        sourceLanguage: lang
      })
    }
  },
  computed: {
    languageDetectionEnabled() {
      return !this.$store.getters['bulk-settings/settings'].translation;
    },
    translationEnabled() {
      return !this.$store.getters['bulk-settings/settings'].languageAutoDetection;
    },
    mySettings() {
      return this.$store.getters['bulk-settings/settings'];
    },
    myLanguage() {
      const lang = this.$store.getters['bulk-settings/settings'].sourceLanguage;
      return this.langData.find((it) => it.value === lang);
    },
    myFormat() {
      const format = this.$store.getters['bulk-settings/settings'].importDataFormat;
      return this.formatOptions.find((it) => it.value === format);
    },
    readingFileErrMsg() {
      return this.$store.getters['bulk-settings/errMsg'];
    },
    importStats() {
      return this.$store.getters['bulk-settings/importStats'];
    },
    entriesToBeExported() {
      return this.$store.getters['bulk-settings/dataToImport']
    },
    translationAllowed() {
      const mSub = this.$store.getters['tariffic-account-information/mySubscription'];
      if (mSub) {
        return mSub.translationAllowed;
      } else {
        return false;
      }
    }
  }
}
</script>

<style scoped>
.app_bar_item {
  font-weight: 500;
  font-size: 1.3rem;
  color: white;
  font-family: 'Poppins', sans-serif;
}

.buttonContainer {
  display: flex;
  align-items: center;
}

.importProcessItem {
  color: #607c24;
  font-size: 1.25rem;
  font-weight: bold;
}

.subHeader {
  color: #607c24;
  font-weight: bold
}

.warnMsg {
  color: red;
  font-size: 0.75rem;
  margin-left: 10px;
  font-weight: 400;
}
</style>