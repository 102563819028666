import httpClient from "../utils/httpClient";

/**
 * Creates user
 * @param userReq - user create req body
 */
export const createUser = async (userReq) => {
    const res = await httpClient.post(`${process.env.VUE_APP_API_URL}/user`, userReq);
    return res.data;
}

/**
 * Gets users
 * @param offset - pagination offset
 * @param limit - pagination limit
 */
export const getUsers = async (offset, limit) => {
    const res = await httpClient.get(`${process.env.VUE_APP_API_URL}/user?offset=${offset}&limit=${limit}`);
    return res.data;
}

/**
 * Deletes user
 * @param userId - identifier of user
 */
export const deleteUser = async (userId) => {
    await httpClient.delete(`${process.env.VUE_APP_API_URL}/user/${userId}`);
}

/**
 * Updates user
 * @param userId - identifier of user
 * @param req - update request
 */
export const updateUser = async (userId, req) => {
    await httpClient.put(`${process.env.VUE_APP_API_URL}/user/${userId}`, req);
}
