<template>
  <TarifficCard
      icon="mdi-help-circle-outline"
      :title="$t('Customer support')"
  >
    <div class="customerSupportInfoContainer" style="color: rgba(0, 0, 0, 0.5)">
      {{$t('Customer support text')}}
      <br>
      {{$t('Feel free to contact us at:')}}
      <p class="mb-0 mt-1">
        <a
            style="color: #607c24; text-decoration: none; font-weight: bold"
            href="mailto: info@solvesall.com?subject=Tariffic API"
            target="_blank"
        >
          info@solvesall.com
        </a>
      </p>
    </div>
    <div class="buttonContainer mt-10">
      <v-btn
          color="#607c24"
          text-color="white"
          class="pt-6 pb-5 pr-12 pl-12"
          @click="downloadTarifficApiManuals"
      >
        <span
            style="color: white; text-decoration: none">
          {{$t('Download tariffic api manuals')}}
        </span>
      </v-btn>
    </div>
  </TarifficCard>
</template>

<script>
import TarifficCard from "./TarifficCard";
import {getManuals} from "@/api/tariffic-customer";
export default {
  name: "TarifficCustomerSupportCard",
  components: {TarifficCard},
  methods: {
    async downloadTarifficApiManuals() {
      const language = this.$store.getters['auth/getLanguage'];
      await getManuals(language);
    }
  }
}
</script>

<style scoped>
.buttonContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>