export const language = {
    namespaced: true,
    state: {
        string: require('../strings/si'),
        language: 'si',
        company: 'postaSL'
    },
    mutations: {
        setStrings(state, payload)  {
            state.string = require(`../strings/${payload.language}`)
            state.language = payload.language
        },
        setCompany(state, company) {
            state.company = company;
        }
    },
    actions: {
        loadLanguage: (context, payload) => {
            context.commit('setStrings', payload)
        },
        setCompany: (context, payload) => {
            context.commit('setCompany', payload)
        }
    },
    getters: {
        strings: state => state.string,
        language: state => state.language,
        company: state => state.company
    }
};
