<template>
  <v-row class="justify-center">
    <v-col class="pl-12 pr-12" :class="!isSubscriptionFetching && !mySubscription ? 'locked' : ''" cols="12" sm="10" md="9" lg="8" xl="5">
      <div class="logoContainer mt-2 mb-10">
        <span class="tarifficTitle">
          Tariffic
        </span>
      </div>
      <Search
          @onSearch="onSearch"
          type="TARIFFIC"
          btnColor="#607c24"
      />

      <transition name="switch" appear v-if="langDetectVisible">
        <v-switch color="#607c24" v-model="langDetection">
          <template v-slot:label>
            <span class="input__label">
               {{ langDetection ? $t('Disable language detection') : $t('Enable language detection') }}
            </span>
          </template>
        </v-switch>
      </transition>

      <Results tariffColor="#607c24"/>
    </v-col>
    <FuncLocked v-if="!isSubscriptionFetching && !mySubscription"/>
  </v-row>
</template>

<script>
import Search from "@/components/HSClassifier/Search";
import Results from "@/components/HSClassifier/Results";
import FuncLocked from "@/components/tariffic/components/FuncLocked";

export default {
  name: "TarifficClassifierDemoApp",
  components: {FuncLocked, Search, Results},
  methods: {
    onSearch(description) {
      this.$store.dispatch('prediction/getPrediction', {
        description: description,
        langDetection: this.langDetection
      })
    }
  },
  data() {
    return {
      langDetection: false, // switch v-model
    }
  },
  mounted() { // set switch state on mounted
    this.langDetection = (this.lang !== 'si')
  },
  computed: {
    lang() {
      return this.$store.getters['language/language']
    },
    langDetectVisible() {
      return this.$store.getters['tariffic-account-information/mySubscription'] ? this.$store.getters['tariffic-account-information/mySubscription'].translationAllowed : false;
    },
    isSubscriptionFetching() {
      return this.$store.getters['tariffic-account-information/subscriptionInFetch'];
    },
    mySubscription() {
      return this.$store.getters['tariffic-account-information/mySubscription'];
    },
  },
}
</script>

<style scoped>
.tarifficTitle {
  font-weight: normal;
  font-size: 4.5rem;
  color: #607c24;
  font-family: 'Poppins', sans-serif;
}

.locked {
  -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(5px);
  pointer-events: none;
}
</style>