export const errorHandler = (error) => {
    console.log(error.response)
    if (error.response) {
        //Request was made and server responded
        if (error.response.data.errorMessage) {
            return error.response.data.errorMessage
        }
        else {
            return 'Unexpected error has occurred'
        }
    }
    else if (error.request) {
        //Request was made but no response was received
        return 'Request was sent but no response had been received'
    }

    return 'Unexpected error has occurred'
}
