<template>
  <div class="lockedContainerMessage">
    <div style="display: flex;align-items: center;justify-content: center; flex-direction: column">
      <v-icon size="120" color="error">
        mdi-lock
      </v-icon>
      <p class="lockedText">
        {{$t('Functionality is not supported in your current subscription plan')}}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "FuncLocked"
}
</script>

<style scoped>
.lockedContainerMessage {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 30%;
}
.lockedText {
  font-family: 'Poppins', sans-serif;
  color: red;
  font-weight: 600;
}
</style>