import {getStatistics} from "../../api/tariffic-customer";
import {daysInMonth, getNameOfMonth} from "../../utils/date";
import {i18n} from "@/i18n/i18n";

export const statistics = {
    namespaced: true,
    state: {
        statisticsReport: '',
        graphValueData: [],
        graphLabelData: [],
        fetchInProgress: false,
        xAxis: ''
    },
    mutations: {
        setStatisticsReport(state, { params, data }) {
            // params contains - day, year, month
            const dataSize = params.day ?
                24 : params.month
                    ? daysInMonth(params.month, params.year) : 12;

            const graphData = [...new Array(dataSize).keys()].map(() => 0);
            let labelData = [...new Array(dataSize).keys()].map(() => 0);

            state.statisticsReport = data;
            state.graphValueData = data.periods.map((it) => it.count)

            if (params.day) {
                state.xAxis = i18n.t('Hour of the day');
                labelData = labelData.map((_, index) => {
                    return index;
                })
                data.periods.forEach((it) => {
                    const hour = parseInt(it.periodName.split(":")[1])
                    graphData[hour] = it.count
                })
            } else if (params.month) {
                state.xAxis = i18n.t('Day of the month');
                labelData = labelData.map((_, index) => {
                    return index + 1;
                })
                data.periods.forEach((it) => {
                    const day = parseInt(it.periodName.split("/")[0] - 1)
                    graphData[day] = it.count
                })
            } else {
                state.xAxis = i18n.t('Month of the year')
                labelData = labelData.map((_, index) => {
                    return getNameOfMonth(index)
                })
                data.periods.forEach((it) => {
                    const month = parseInt(it.periodName.split("/")[0] - 1)
                    graphData[month] = it.count
                })
            }

            state.graphValueData = graphData;
            state.graphLabelData = labelData;
        },
        clearStats(state) {
            state.statisticsReport = '';
            state.graphLabelData = [];
            state.graphValueData = [];
        },
        setProgress(state, progress) {
            state.fetchInProgress= progress;
        }
    },
    actions: {
        async getStats(context, data) {
            context.commit('setProgress', true);

            const res = await getStatistics(data.year, data.month, data.day);
            context.commit('setProgress', false);

            context.commit('setStatisticsReport', {
                data: res,
                params: data
            })
        },
        clearStats(context) {
            context.commit('clearStats');
        }
    },
    getters: {
        graphValueData: (state) => state.graphValueData,
        graphLabelData: (state) => state.graphLabelData,
        statisticsReport: (state) => state.statisticsReport,
        isFetching: (state) => state.fetchInProgress,
        xAxis: (state) => state.xAxis
    }
}