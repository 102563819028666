import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import { router } from "./router";
import http from './utils/httpClient'
import {store} from "./store";
import {setupInterceptor} from "./utils/httpInterceptors";
import {i18n} from "./i18n/i18n";
import eventBus from "./eventBus";
import httpClient from "./utils/httpClient";
import quietHttpClient from "./utils/quietHttpClient";

Vue.config.productionTip = false
Vue.prototype.$http = http;
Vue.prototype.$quietHttp = quietHttpClient
export const bus = eventBus;
new Vue({
  vuetify,
  store: store,
  router: router,
  render: h => h(App),
  created() {
    setupInterceptor( false, httpClient)(store)
    setupInterceptor(true, quietHttpClient)(store)
  },
  i18n
}).$mount('#app')
