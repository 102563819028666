export const spinner = {
    namespaced: true,
    name: "spinner",
    state: {
        pendingRequest: 0
    },
    mutations: {
        mutatePendingRequests (state, value) {
            state.pendingRequest = state.pendingRequest + value;
        }
    },
    actions: {
        addPendingRequest (context) {
            context.commit('mutatePendingRequests', 1);
        },
        removePendingRequest (context) {
            context.commit('mutatePendingRequests', -1);
        }
    }
};
