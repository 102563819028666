/**
 * Stores pagination information
 */
export const paginationMixin = {
    data: function () {
        return {
            paginationInfo: {
                offset: 0,
                limit: 10,
            },
            fetchInProgress: false
        }
    },
    methods: {
        setToDefault() {
            this.paginationInfo = defaultState();
        },
        markPageFetch() {
            this.paginationInfo.offset += this.paginationInfo.limit
        }
    }
}


const defaultState = () => {
    return {
        totalCount: 0,
        offset: 0,
        limit: 10,
        currentPage: 1,
        totalPages: 0
    }
}
