<template>
  <v-row justify="center">
    <v-dialog
        v-model="dialog"
        max-width="400"
        persistent
    >
      <v-card>
        <v-card-title class="text-h5">
          {{modalOBJ.title}}
        </v-card-title>
        <v-card-text>{{modalOBJ.message}}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="green darken-1"
              text
              @click="()=>handleClick(true)"
          >
            {{modalOBJ.positiveDecision}}
          </v-btn>
          <v-btn
              color="red darken-1"
              text
              @click="()=>handleClick(false)"
          >
            {{modalOBJ.negativeDecision}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: "DecisionModal",
  data: function () {
    return {
      modalOBJ: {
        message: '',
        title: '',
        positiveDecision: '',
        negativeDecision: '',
      },
      resolve: null,
      reject: null,
      dialog: false
    }
  },
  methods: {
    showModal(obj) {
      this.dialog = true;
      this.modalOBJ = obj;

      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      })
    },
    handleClick(result) {
      this.resolve(result);
      this.dialog = false;
    }
  }
}
</script>

<style scoped>

</style>
