<template>
  <div class="mt-0">
    <div
        @scroll="onScroll"
        style="max-height: 65vh; overflow-y: auto"
    >
      <v-data-table
          :hide-default-footer="true"
          :headers="headers"
          :items="myData"
          :items-per-page="-1"
      >
        <template v-slot:item.rawText="{ item }">
          <div class="entryContainer" v-if="!isOverflowing(item.rawText)">
            {{ item.rawText }}
          </div>
          <div class="truncatedEntryContainer" v-else>

            <div :class="item.rawTextActiveClass">
              <v-btn
                  icon
                  @click="onArrowClickRawText(item)"
              >
                <v-icon>
                  mdi-chevron-down
                </v-icon>
              </v-btn>
            </div>

            <v-expand-transition v-if="item.isRawTextExpanded">
              <div>
                {{ item.rawText }}
              </div>
            </v-expand-transition>

            <v-expand-transition v-else>
              <div>
                {{ truncateText(item.cleanText) }}
              </div>
            </v-expand-transition>
          </div>
        </template>

        <template v-slot:item.detectedLang="{ item }">
          <p class="detectedLangContainer">
            {{item.detectedLang !== '' ? item.detectedLang : 'N/A'}}
          </p>
        </template>

        <template v-slot:item.cleanText="{ item }">
          <div class="entryContainer" v-if="!isOverflowing(item.cleanText)">
            {{ item.cleanText }}
          </div>
          <div class="truncatedEntryContainer" v-else>
            <div :class="item.cleanTextActiveClass">
              <v-btn
                  icon
                  @click="onArrowClickCleanText(item)"
              >
                <v-icon>
                  mdi-chevron-down
                </v-icon>
              </v-btn>
            </div>

            <v-expand-transition v-if="item.isCleanTextExpanded ">
              <div>
                {{ item.cleanText }}
              </div>
            </v-expand-transition>

            <v-expand-transition v-else>
              <div>
                {{ truncateText(item.cleanText) }}
              </div>
            </v-expand-transition>
          </div>
        </template>

        <template v-slot:item.predictedTariff="{ item }">
          <v-expansion-panels style="width: 20vw" :value="true">
            <v-expansion-panel>
              <v-expansion-panel-header>
                {{ item.predictedTariff }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <TariffDescriptionContent :tariff-number="item.predictedTariff" />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </template>

        <template v-slot:item.isConfident="{ item }">
          <div style="width: 2vw">
            <p v-if="item.isConfident">
              {{ $t('No') }}
            </p>
            <p v-else>
              {{ $t('Yes') }}
            </p>
          </div>
        </template>

      </v-data-table>

    </div>
  </div>
</template>

<script>
import TariffDescriptionContent from "./TariffDescriptionContent";
export default {
  name: "ClassificationHistoryTable",
  components: {TariffDescriptionContent},
  methods: {
    onScroll({target: {scrollTop, clientHeight, scrollHeight}}) {
      if (scrollTop + clientHeight >= scrollHeight) {
        this.$emit('nextPage');
      }
    },
    isOverflowing(text) {
      return text.length > 100
    },
    onArrowClickRawText(item) {
      if (!item.isRawTextExpanded) {
        item.isRawTextExpanded = true;
        item.rawTextActiveClass = 'arrow-down.open';
      } else {
        item.isRawTextExpanded = false;
        item.rawTextActiveClass = 'arrow-down';
      }
    },
    onArrowClickCleanText(item) {
      if (!item.isCleanTextExpanded) {
        item.isCleanTextExpanded = true;
        item.cleanTextActiveClass = 'arrow-down.open';
      } else {
        item.isCleanTextExpanded = false;
        item.cleanTextActiveClass = 'arrow-down';
      }
    },
    truncateText(item) {
      return `${item.substring(0, 100)} ...`
    }
  },
  data: function () {
    return {
      headers: [
        {
          text: this.$t('Entered text'),
          align: 'start',
          sortable: false,
          value: 'rawText'
        },
        {
          text: this.$t('Processed text'),
          align: 'start',
          sortable: false,
          value: 'cleanText'
        },
        {
          text: this.$t('Detected language'),
          align: 'start',
          sortable: false,
          value: 'detectedLang'
        },
        {
          text: this.$t('Predicted tariff number'),
          align: 'start',
          sortable: false,
          value: 'predictedTariff'
        },
        {
          text: this.$t('Detected low confidentiality'),
          align: 'start',
          sortable: false,
          value: 'isConfident'
        }
      ],
      myData: []
    }
  },
  props: ['data'],
  watch: {
    data: function (newVal) {
      const mapped = newVal.map((it) => {
        return {
          ...it,
          isRawTextExpanded: false,
          rawTextActiveClass: '',
          isCleanTextExpanded: false,
          cleanTextActiveClass: ''
        }
      });
      this.myData = mapped;
    }
  }
}
</script>

<style scoped>
/* Remove Arrows/Spinners at number inputs for Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.table-commodities {
  background-color: transparent !important;
}

.table-commodities >>> td {
  background-color: #ffffff;
  border-radius: 8px !important;
}

.table-commodities >>> table {
  border-spacing: 0.3rem 0.4rem !important;
}

.table_item {
  display: flex;
  flex-direction: column;
}

.truncatedEntryContainer {
  display: flex;
  flex-direction: row;
  width: 15vw;
}

.arrow-down {
  -webkit-transform: rotate(0deg); /* Safari and Chrome */
  -moz-transform: rotate(0deg);   /* Firefox */
  -ms-transform: rotate(0deg);   /* IE 9 */
  -o-transform: rotate(0deg);   /* Opera */
  transform: rotate(0deg);
  transition: transform 1s linear;
}

.arrow-down.open {
  -webkit-transform: rotate(90deg); /* Safari and Chrome */
  -moz-transform: rotate(90deg);   /* Firefox */
  -ms-transform: rotate(90deg);   /* IE 9 */
  -o-transform: rotate(90deg);   /* Opera */
  transform: rotate(90deg);
  transition: transform 1s linear;
}

.entryContainer {
  width: 15vw;
  display: flex;
  overflow-x: auto;
}

.detectedLangContainer {
  width: 1rem;
}
</style>
