<template>
  <v-main class="mainContainer  align-center justify-center">
    <v-container class="justify-center align-center">
      <v-row class="justify-center align-center">
        <v-col class="col-lg-6 loginFormContainer">
          <div style="display: flex;align-items: center;justify-content: center">
            <img src="@/assets/posta_logo.png"/>
          </div>
          <div class="loginCard">
            <v-row class="justify-center align-center">
              <v-col class="col-lg-8 mt-0 pa-0" cols="8">
                <v-text-field
                    v-model="username"
                    class="form-bnt-radius"
                    label="Uporabniško ime"
                    type="input"
                    solo
                    flat
                    v-on:keyup.enter="login"
                />
              </v-col>

              <v-col class="col-lg-8 mt-0 pa-0" cols="8">
                <v-text-field
                    v-model="password"
                    class="form-bnt-radius"
                    label="Geslo"
                    type="password"
                    solo
                    flat
                    v-on:keyup.enter="login"
                />
              </v-col>
            </v-row>
            <div class="buttonContainer">
              <v-btn
                  rounded
                  class="pa-6"
                  @click="login"
              >
                Prijava
              </v-btn>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import {loginUser} from "../api/auth";

export default {
  name: "Login",
  data () {
    return {
      username: '',
      password: ''
    }
  },
  methods: {
    async login () {
      try {
        const res = await loginUser(this.username, this.password)
        await this.$store.dispatch('auth/setTokens', res)
        await this.$router.push({
          name: 'HSClassifier'
        })
      }catch (e) {
        console.log(e)
        this.$store.dispatch('snackbar/fireAlert', {
          message: 'Prijava neuspela, preverite uporabniško ime in geslo',
          color: 'error'
        })
      }
    }
  }
}
</script>

<style scoped>
.mainContainer {
  background-color: #FBCB07;
}
.loginFormContainer {
  margin-bottom: 10%;
}
.loginCard {
  box-shadow: #2D323A;
  margin-top: 5%;
}
.form-bnt-radius {
  border-radius: 15px !important;
}
.buttonContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 25px;
}
</style>
