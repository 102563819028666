import httpClient from "../utils/httpClient";
import {store} from "../store";

/**
 * Gets training instances
 * @param offset - pagination offset
 * @param limit - pagination limit
 */
export const getTrainingInstances = async (offset, limit) => {
    const res = await httpClient.get(`${process.env.VUE_APP_API_URL}/tariffic/training-instances?offset=${offset}&limit=${limit}`);
    return res.data.result;
}

/**
 * Create training instance
 * @param createReq - create train instance request
 */
export const createTrainingInstance = async (createReq) => {
    const res = await httpClient.post(`${process.env.VUE_APP_API_URL}/tariffic/training-instances`, createReq);
    return res.data
}

/**
 * Gets training basic info
 */
export const getTrainingBasicInfo = async () => {
    const res = await httpClient.get(`${process.env.VUE_APP_API_URL}/tariffic/training-instances/basic-info`);
    return res.data;
}

/**
 * Deletes training instance
 * @param trainingInstanceId - training instance identifier
 */
export const deleteTrainingInstance = async (trainingInstanceId) => {
    return await httpClient.delete(`${process.env.VUE_APP_API_URL}/tariffic/training-instances/${trainingInstanceId}`)
}

/**
 * Gets translation from given language to slovene
 * @param text - text we want to translate
 */
export const getTranslation = async (text) => {
    const accountInfo = store.getters["tariffic-account-information/accountInformation"];
    const res = await httpClient.post(`${process.env.VUE_APP_API_URL}/tariffic/api/google-translate`, {
        text: text
    }, {headers: {'tariffic-api-key': accountInfo ? accountInfo.key : ''}});
    return res.data
}