<template>
  <div>
    <DecisionModal ref="decisionModal"/>
    <p class="rowTitle mt-10">
      Obrazec za dodajanje nove preslikave
    </p>
    <v-divider/>
    <v-form ref="form">
      <v-col class="col-lg-7">
        <v-text-field
            class="form-bnt-radius"
            label="Stara tarifa številka"
            v-model="oldTariff"
            type="input"
            solo
            flat
        ></v-text-field>
      </v-col>

      <v-col class="col-lg-7">
        <v-text-field
            class="form-bnt-radius"
            label="Nova tarifna številka"
            v-model="newTariff"
            type="input"
            solo
            flat
        ></v-text-field>
      </v-col>
    </v-form>

    <div class="buttonContainer">
      <v-btn
          rounded
          class="pa-6"
          :disabled="!isAddingEnabled"
          @click="addNewMapping"
      >
        Dodaj novo preslikavo
      </v-btn>
    </div>
  </div>
</template>

<script>
import DecisionModal from "./DecisionModal";
export default {
  name: "HsMappingForm",
  components: {DecisionModal},
  data: function () {
    return {
      oldTariff: '',
      newTariff: ''
    }
  },
  computed: {
    isAddingEnabled: function () {
      const rule = (tariff) => tariff !== "" && /^\d+$/.test(tariff) && tariff.length === 6;
      return rule(this.oldTariff) && rule(this.newTariff);
    }
  },
  methods: {
    async addNewMapping() {
      const res = await this.$refs.decisionModal.showModal({
        message: 'Ali ste prepičani, da želite dodati nov vnos v preslikovalno tabelo',
        title: 'Dodajanje novega vnosa v preslikovalno tabelo',
        positiveDecision: 'Potrdi',
        negativeDecision: 'Zavrni'
      });
      if (res) {
        this.$emit('onAdd', {
          'oldTariff': this.oldTariff,
          'newTariff': this.newTariff
        })
      }
    },
    clearForm() {
      this.oldTariff = '';
      this.newTariff = '';
    }
  }
}
</script>

<style scoped>
.form-bnt-radius {
  border-radius: 15px !important;
}

.buttonContainer {
  display: flex;
  justify-content: end;
}
</style>
