<template>
  <div>
    <p
        v-for="tariffDescription in tariffDescriptions"
        :key="tariffDescription.id"
    >
      {{tariffDescription.hsDescription}}
    </p>
  </div>
</template>

<script>
export default {
  name: "TariffDescriptionContent",
  data: function () {
    return {
      tariffDescriptions: [],
      tariffSplit: []
    }
  },
  props: ['tariffNumber'],
  async mounted() {
    let currentPrefix = '';
    const tariffsSplit = this.tariffNumber.match(/.{1,2}/g);
    this.tariffSplit = tariffsSplit;

    for (const currentTariffPrefix of tariffsSplit) {
      currentPrefix += currentTariffPrefix;
      if (!this.$store.getters["tariffDescriptions/getTariffDescription"](currentPrefix)) {
        await this.$store.dispatch('tariffDescriptions/fetchTariffDescription', currentPrefix);
      } else {
        this.tariffDescriptions.push(this.$store.getters["tariffDescriptions/getTariffDescription"](currentPrefix))
      }
    }
  },
  watch: {
    tariffNumberDescriptions: {
      handler(value) {
        if (this.tariffDescriptions.length >= 3) {
          return;
        }
        let currentPrefix = "";

        this.tariffSplit.forEach((split) => {
          currentPrefix += split;
          const presentValue = this.tariffDescriptions.find((it) => it.hsPrefix === currentPrefix)
          if (value[currentPrefix] && !presentValue) {
            this.tariffDescriptions.push(value[currentPrefix]);
          }
        })
        this.tariffNumberDescriptions.sort((a, b) => {
          return a.tariffPrefix.length - b.tariffPrefix.length;
        })
      },
      deep: true
    }
  },
  computed: {
    tariffNumberDescriptions() {
      return this.$store.getters["tariffDescriptions/getTariffDescriptions"];
    }
  }
}
</script>

<style scoped>

</style>
