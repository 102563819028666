<template>
  <div class="main" :class="locked ? 'locked' : ''">
    <div class="translateButtonContainer">
      <v-btn
          @click="handleTranslate"
          color="#607c24"
          fab
          width="30"
          height="30"
          :disabled="!translationAllowed"
      >
        <v-icon x-small color="white">
          mdi-translate
        </v-icon>
      </v-btn>
      <span class="translateButtonInstructionsText">
        {{ $t('Translate HS-tariff description to Slovene') }}
      </span>
      <span v-if="!translationAllowed" class="warnMsg">
        {{$t('(Your subscription plan does not support translations)')}}
      </span>
    </div>
    <v-form
        ref="myForm"
        @submit="addNewInstance"
    >
      <v-textarea
          :disabled="formLoading"
          :loading="formLoading"
          v-model="hsDescription"
          no-resize
          counter
          height="70"
          class="mt-10"
          :rules="hsDescriptionRule"
          color="#607c24"
          :label="$t('HS-tariff description (in Slovene language)')"
      ></v-textarea>

      <v-text-field
          :disabled="formLoading"
          :loading="formLoading"
          v-model="hsNumber"
          :label="$t('HS-tariff number')"
          :rules="hsTariffRule"
          color="#607c24"
      />
    </v-form>

    <div class="buttonContainer mt-10" v-if="!editMode">
      <v-btn
          color="#607c24"
          text-color="white"
          class="pt-5 pb-5 pr-12 pl-12"
          @click="addNewInstance"
      >
        <span
            style="color: white; text-decoration: none">
          {{ $t('Add new instance') }}
        </span>
      </v-btn>
    </div>

    <div class="buttonContainer mt-10" v-else>
      <v-btn
          color="#607c24"
          text-color="white"
          class="pt-5 pb-5 pr-12 pl-12"
          @click="saveChanges"
      >
        <span
            style="color: white; text-decoration: none">
          {{ $t('Save changes') }}
        </span>
      </v-btn>

      <v-btn
          color="#607c24"
          text-color="white"
          class="pt-5 pb-5 pr-12 pl-12 ml-5"
          @click="dismissChanges"
      >
        <span
            style="color: white; text-decoration: none">
          {{ $t('Dismiss') }}
        </span>
      </v-btn>
    </div>

  </div>
</template>

<script>
import {isValidTariff} from "../../../utils/tariffs";

export default {
  name: "AddNewTrainingInstanceForm",
  components: {},
  props: ['editMode', 'currentHsDescription', 'currentHsNumber', 'locked'],
  data: function () {
    return {
      hsDescription: '',
      hsNumber: '',
      hsTariffRule: [
        (v) => isValidTariff(v) || this.$t('HS-tariff number must contain 6 or 8 digits')
      ],
      hsDescriptionRule: [
        (v) => v.length > 0 || this.$t('HS-tariff number description cannot be empty')
      ],
      formLoading: false
    }
  },
  methods: {
    handleTranslate() {
      this.$emit('requestTranslate', this.hsDescription);
    },
    addNewInstance() {
      const validForm = this.$refs.myForm.validate();
      if (validForm) {
        this.$emit('onAdd', {
          hsDescription: this.hsDescription,
          hsNumber: this.hsNumber
        });
      }
    },
    cleanupForm() {
      this.hsNumber = '';
      this.hsDescription = '';
      this.$refs.myForm.resetValidation();
    },
    saveChanges() {
      this.$emit('save');
    },
    dismissChanges() {
      this.$emit('dismiss');
    }
  },
  watch: {
    currentHsDescription: function (newVal) {
      if (newVal) {
        this.hsDescription = newVal;
      }
    },
    currentHsNumber: function (newVal) {
      if (newVal) {
        this.hsNumber = newVal;
      }
    }
  },
  computed: {
    translationAllowed() {
      const mSub = this.$store.getters['tariffic-account-information/mySubscription'];
      if (mSub) {
        return mSub.translationAllowed;
      } else {
        return false;
      }
    }
  }
}
</script>

<style scoped>
.translateButtonContainer {
  display: flex;
  flex-direction: row;
  margin-top: 12px;
  align-items: center;
}

.translateButtonInstructionsText {
  color: rgba(0, 0, 0, 0.3);
  margin-left: 6px;
  font-size: 0.85rem;
  font-weight: 500;
}

.warnMsg {
  color: red;
  font-size: 0.75rem;
  margin-left: 10px;
  font-weight: 400;
}

.locked {
  -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(5px);
  pointer-events: none;
}

.main {
  position: relative;
}
</style>