<template>
  <v-dialog v-model="dialogModel" max-width="550px">
    <v-card>
      <v-card-title class="text-h5">{{ strings.CUSTOMERS.formTitleDelete }}</v-card-title>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="#000" text @click="dialogModel = false">{{ strings.CUSTOMERS.formCancel }}</v-btn>
        <v-btn color="#000" text @click="deleteItemConfirm">{{ strings.CUSTOMERS.formOk }}</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { deleteCustomer } from "@/api/customers";

export default {
  name: "DeleteDialog",
  props: {
    id: String,
    dialogDelete: Boolean,
  },
  computed: {
    strings() { return this.$store.getters['language/strings'] },
    dialogModel: {
      get () { return this.dialogDelete },
      set (value) { this.$emit('update:dialogDelete', value) },
    },
  },
  methods: {
    async deleteItemConfirm () {
      await deleteCustomer(this.id)
      this.dialogModel = false
      this.$emit('fetchCustomers')
    },
  },
}
</script>

<style scoped>
</style>