<template>
  <v-row class="justify-center mt-5">
    <v-col class="col-lg-6" v-if="users" cols="12">
      <p class="pa-1 rowTitle">{{ strings.USERS.userTitle }}</p>
      <v-divider/>
      <UserTable
          :data="users"
          @nextPage="fetchUsers"
          @onDelete="onDelete"
          @onUpdate="onUpdate"
      />
    </v-col>
    <v-col class="col-lg-4" cols="12">
      <p class="pa-1 rowTitle">{{ strings.USERS.addNewUser }}
        <v-tooltip
            top
            max-width="500px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                icon
                v-bind="attrs"
                v-on="on"
            >
              <v-icon color="blaack">
                mdi-information
              </v-icon>
            </v-btn>
          </template>
          <span>
            {{ strings.USERS.userInfo }}
          </span>
        </v-tooltip>
      </p>
      <v-divider/>

      <NewUserForm
          @onNewUser="onNewUser"
          ref="newUserForm"
      />

    </v-col>
  </v-row>
</template>

<script>
import NewUserForm from "./NewUserForm";
import {createUser, deleteUser, getUsers, updateUser} from "../api/users";
import UserTable from "./UserTable";
import {paginationMixin} from "../mixins/pagination";

export default {
  name: "Users",
  components: {UserTable, NewUserForm},
  mixins: [paginationMixin],
  data: function () {
    return {
      users: [],
      emailRules: [
        v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid'
      ]
    }
  },
  async mounted() {
    try {
      await this.fetchUsers();
    } catch (e) {
      console.log(e)
    }
  },
  computed: {
    strings() {
      return this.$store.getters['language/strings']
    }
  },
  methods: {
    async onNewUser(user) {
      const userData = await createUser(user);
      this.users.push(userData);

      await this.$store.dispatch('snackbar/fireAlert', {
        color: 'success',
        message: 'Uspešno ste dodali novega uporabnika'
      });
      this.$refs.newUserForm.reset();
    },
    async fetchUsers() {
      if (!this.fetchInProgress) {
        this.fetchInProgress = true;
        const users = await getUsers(this.paginationInfo.offset, this.paginationInfo.limit);
        this.users = [...this.users, ...users];
        console.log(this.users, "users")
        this.fetchInProgress = false;
        this.markPageFetch();
      }
    },
    async onDelete(item) {
      await deleteUser(item.id);
      this.users = this.users.filter((it) => it.id !== item.id);
      await this.$store.dispatch('snackbar/fireAlert', {
        color: 'success',
        message: `Uspešno ste izbrisali uporabnika ${item.email} s seznama`
      })
    },
    async onUpdate(item) {
      console.log(item, "update")
      await updateUser(item.id, {
        notifyAnomaly: item.notifyAnomaly,
        notifyReports: item.notifyReports
      });
      this.users = this.users.map((it) => {
        if (it.id === item.id) {
          return {
            ...item
          }
        } else {
          return it
        }
      })
    }
  }
}
</script>

<style scoped>
input {
  outline: none;
}

.rowTitle {
  color: black;
  font-weight: 100;
  font-size: 1.5rem;
}

.rowSubTitle {
  color: black;
  font-weight: 100;
}
</style>
