import {isExpired, parseJWT} from "../utils/jwt";
import axios from "axios";

export const auth = {
    namespaced: true,
    state: {
        authToken: window.localStorage.getItem('authToken') || '',
        refreshToken: window.localStorage.getItem('refreshToken') || '',
        language: window.localStorage.getItem('language') || 'sl'
    },
    mutations: {
        setRefreshToken(state, token) {
            state.refreshToken = token
            localStorage.setItem('refreshToken', token)
        },
        setAuthToken(state, token) {
            state.authToken = token
            localStorage.setItem('authToken', token)
        },
        setLanguage(state, lang) {
            localStorage.setItem('language', lang)
            state.language = lang
        }
    },
    actions: {
        setTokens (context, data) {
            context.commit('setAuthToken', data.authToken)
            context.commit('setRefreshToken', data.refreshToken)
        },
        /**
         * Returns promise with true if success
         */
        refreshTokens (context, refreshToken) {
            return new Promise((resolve) => {
                console.log(refreshToken)
                if (refreshToken) {
                    const obj = parseJWT(refreshToken)
                    if (isExpired(obj.exp)) {
                        return resolve(false)
                    }
                } else {
                    return resolve(true)
                }

                // Try to obtain new set from api
                const URL = process.env.VUE_APP_VERSION === 'TARIFFIC-CUSTOMER' ?
                    `${process.env.VUE_APP_API_URL}/tariffic/refresh-token` :
                    `${process.env.VUE_APP_API_URL}/auth/refresh-token`
                axios.post(URL, {refreshToken})
                    .then((res) => {
                        context.commit('setAuthToken', res.data.authToken)
                        context.commit('setRefreshToken', res.data.refreshToken)
                        resolve(true)
                    })
                    .catch((err) => {
                        resolve(false)
                        console.log(err)
                    })
            })
        },
        /**
         * Logout
         */
        logout (context) {
            context.commit('setAuthToken', '')
            context.commit('setRefreshToken', '')
        },

        /**
         * Sets language
         */
        setLanguage(context, lang) {
            context.commit('setLanguage', lang)
        }
    },
    getters: {
        /**
         * Takes auth token from store and checks if it is valid
         */
        isLoggedIn(state) {
            if (!state.authToken) {
                return false;
            }
            const payload = parseJWT(state.authToken);
            return !isExpired(payload.exp);
        },
        /**
         * Takes refresh token from store and checks if it is valid
         */
        validRefreshToken(state) {
            if (state.refreshToken === '') {
                return false;
            }
            const refreshPayload = parseJWT(state.refreshToken);
            return !isExpired(refreshPayload.exp);
        },
        /**
         * Returns auth token from store
         */
        getAuthToken(state) {
            return state.authToken;
        },
        /**
         * Returns refresh token from store
         */
        getRefreshToken(state) {
            return state.refreshToken;
        },

        getLanguage(state) {
            return state.language
        },
        getUser(state) {
            return parseJWT(state.authToken)
        },
        getRole(state) {
            if (state.authToken) {
                try {
                    const user = parseJWT(state.authToken);
                    return user.role
                } catch (e) {
                    return undefined;
                }
            }
            return undefined;
        }
    }
}
