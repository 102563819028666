module.exports = {
    ROOT: {
        displayNameDashboard: 'DASHBOARD',
        appBarDashboard: 'Dashboard',
        displayNameMonitoring: 'MONITORING',
        appBarMonitoring: 'Overview of API traffic',
        displayNameClassifier: 'HS CLASSIFIER',
        appBarClassifier: 'HS-classifier demo application',
        displayNameUserManagement: 'USERS',
        appBarUserManagement: 'Users management',
        languagePicker: 'Select your language',
        displayNameHazardousCommodities: 'HAZARD. COMMODITIES',
        displayNameHSDescription: 'HS DESCRIPTIONS',
        appBarHSDescription: 'HS-descriptions demo application',
        displayNameCustomersManager: 'CUSTOMERS',
        appBarCustomersManager: 'Customers manager',
        displayNameHSHistory: 'HISTORY',
        displayNameLogout: 'LOG OUT',
    },
    CLASSIFIER: {
        instruction: 'Please provide a description of the goods',
        buttonText: 'CLASSIFY',
        detectionTextFalse: 'Enable language detection and translation',
        detectionTextTrue: 'Disable language detection and translation',
        classificationTitle: 'Classified HS tariff number:',
        noInformationText: 'No additional information available',
        notConfident: 'Predicted tariff number has low level of confidence'
    },
    DASHBOARD: {
        levelClassificationTitle: 'Weighted classification accuracy by levels',
        levelClassificationGraphTitle: 'Weighted classification accuracy by levels - visualization',
        levelDataSupport: 'Data support',
        levelDataSupportInfo: 'Data support is calculated as average on each level',
        trainingInfoTitle: 'Training information',
        trainingStatusRun: 'System is in training',
        trainingStatusEnd: 'Training has ended, system is waiting for new cycle of training',
        trainingPlanTitle: 'Training plan',
        trainingPlanTitleButton: 'Update training plan',
        trainingPlanDataMarginText: 'Minimal number of newly obtained data',
        trainingPlanErrorMarginText: 'Maximal allowed classification error when training new models',
        trainingPlanIntervalUpdateText: 'System is being updated in intervals of',
        trainingStatus: 'Status',
        trainingInfoText: 'Next training job is being scheduled for',
        trainingPlanUpdateTitle: 'Update current training plan',
        trainingPlanUpdateSubtitle: 'Setup new parameters for model training',
        trainingPlanUpdateDataMargin: 'Minimal amount of data',
        trainingPlanUpdateErrorMargin: 'Minimal absolute error in %',
        trainingPlanUpdateMonth: 'Months',
        trainingPlanUpdateDay: 'Days',
        trainingPlanUpdateHour: 'Hours',
        trainingPlanRadioWait: 'Wait up for currently waiting training in queue',
        trainingPlanRadioContinue: 'Do not wait for currently waiting training in queue',
        trainingPlanUpdate: 'UPDATE',
        trainingPlanClose: 'CLOSE',
        trainingPlanDataMarginInfo: 'The minimum number of new data represents the threshold for each of the models at all levels, which is the minimum required number of new data for re-training the model.'+
            'Suppose we already have a trained model for prefix 10, new data for this model is e.g. 30, if we set the minimum number to 20, the algorithm will perform retraining, but if e.g. at 50'+
            'the algorithm will skip the training of this model and try again next time',
        trainingPlanErrorMarginInfo: 'The minimum absolute error of the model tells us by how many% the classification accuracy of the model may be damaged. Suppose that model 10 predicts with a classification accuracy of 86.4% if we set'+
            'the number of minimum absolute errors of the model to 10% and is a newly trained model, due to new data slightly less accurate e.g. 85%, it is accepted as the active classification model in case '+
            'this number would be set to 1%, the system would reject the newly trained model and use the old one.',
        trainingPlanIntervalInfo: 'Setting the interval tells us at what intervals the automatic training will be performed.',
        trainingPlanWaitPolicyInfo: 'If you want to wait for the pending training to be done, you can do so by clicking on Wait for the pending training, which means that the system will'+
            'waited as long as he had left until the planned training, carried it out, and continued to plan the training according to the new given interval' +
            'In case we decide not to wait for the already waiting training, the following happens: the training that is planned is removed from the waiting list, into the waiting list' +
            'enters training at a given interval.',
        trainingPlanUpdateSuccess: 'Training plan has been update successfully',
        trainingPlanUpdateError: 'An error has occurred when updating training plan',
        tableTitle: 'Mapping table',
        tableContent: 'Currently active mapping table',
        successTableUpdate: 'Successfully updated mapping table',
        failTableUpdate: 'Error updating mapping table'
    },
    MONITORING: {
        trainingInfoEnded: 'Training has already ended, here are present latest 30 history logs (Model training logs)',
        trainingInfoLive: 'Live training data (Model training logs) ',
        trainingInfoNone: 'No training data available, wait for next training (Model training logs)',
        trafficTitle: 'Overview of traffic / access to HS classifier',
        graphTitle: 'Number of API requests'
    },
    USERS: {
        headerEmail: 'Email address',
        headerID: 'Unique identifier',
        headerActions: 'Actions',
        errorDeleting: 'An error has occured when trying to delete user from database',
        successDeleting: 'Successfully delete user from database',
        addNewUser: 'Add new user',
        userTitle: 'Users for e-mail reporting',
        userInfo : 'If you want users to be informed by email about current status and anomalies in system add their email to this list. For example when classifier fails to predict tariff number ' +
            ', training fail occurs, database where we are fetching data from is not responding etc. people on this list will be informed about such events',
        errorCreating: 'An error has occurred when trying to add new user',
        successCreating: 'Successfully added new user',
        detectedLanguage: function (language, original, translate) {
            return `Detected language was not slovene but ${language}, so we translated it from: "${original}" to "${translate}" , therefore quality of prediction will not be as good if text was to be entered in slovene.`
        }
    },
    CUSTOMERS: {
        tableTitle: 'Customers:',
        btnNewCustomerText: 'New customer',
        tableHeaderEmail: 'Email',
        tableHeaderName: 'Name',
        tableHeaderApiKey: 'API key',
        tableHeaderStatus: 'Status',
        tableHeaderCreatedAt: 'Created at',
        tableHeaderUpdatedAt: 'Updated at',
        tableHeaderActions: 'Actions',
        formTitleNew: 'New customer',
        formTitleEdit: 'Edit customer',
        formTitleDelete: 'Are you sure you want to delete this customer?',
        formCancel: 'cancel',
        formSave: 'save',
        formOk: 'ok',
        statusActivated: 'Activated',
        statusDeactivated: 'Deactivated',
    },
}
