<template>
  <div>
    <p class="pb-0 mb-0">
      <span class="mainText">
       {{$t('Number of instances used in algorithm:')}}
      </span>
      <span class="contentText">
        {{basicInfo.inUseCount}}
      </span>
    </p>

    <p class="mt-0 pt-0 pb-0 mb-0">
      <span class="mainText">
        {{$t('Number of instances waiting to be appended to algorithm:')}}
      </span>
      <span class="contentText">
        {{basicInfo.notInUseCount}}
      </span>
    </p>

    <p class="mt-0 pt-0 pb-0 mb-0" v-if="false">
      <span class="mainText">
        {{$t('Number of instances waiting to be removed from algorithm:')}}
      </span>
      <span class="contentText">
        {{basicInfo.toBeDeletedCount}}
      </span>
    </p>

    <div class="buttonContainer mt-7">
      <v-btn
          color="#607c24"
          text-color="white"
          class="pt-5 pb-5 pr-6 pl-6"
          @click="$emit('onIssue')"
      >
        <span
            style="color: white; text-decoration: none">
          {{$t('Issue retrain procedure')}}
        </span>
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: "TrainingInstancesBasicInfo",
  props: ['basicInfo']
}
</script>

<style scoped>
.mainText {
  font-size: 0.9rem;
  color: rgba(0, 0, 0, 0.25);
}
.contentText {
  font-size: 1.3rem;
  color: rgba(0, 0, 0, 0.6);
  font-weight: 500;
}
</style>