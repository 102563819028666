/**
 * Parses raw bulk data to json objects
 * @param data - raw string of data
 * @param format - [json, csv]
 */
export const parseBulkData = (data, format) => {
    switch (format) {
        case 'csv':
            return data
                .split("\n")
                .map((it) => {
                    const [description, tariffNumber] = it.split("\t");
                    return {
                        code: tariffNumber,
                        description: description
                    }
                })
        case 'json':
            return JSON.parse(data)
        default:
            return [];
    }
}

/**
 * Prepares basic statistic for import process
 * @param data - bulk data parsed to json format
 */
export const prepBasicStatistics = (data) => {
    const seenTariffs = new Set();

    data.forEach((tariffEntry) => {
        seenTariffs.add(tariffEntry.code)
    })

    return {
        uniques: seenTariffs.size,
        totalCount: data.length
    }
}