<template>
  <v-app v-if="loggedIn">
    <Snackbar/>
    <v-navigation-drawer
        v-model="drawer"
        app
        temporary
        color="#607c24"
    >
      <div class="navigationHeaderTitle">
        Tariffic
      </div>

      <v-list dense nav>
        <span
            v-for="(navItem, index) in navItems.filter((it) => it.display)"
            :key="navItem.name"
            class="pa-5 nav_item"
        >
          <router-link
              :to="{name:navItem.name}"
              class="nav_item"
              v-if="!navItem.handler"
          >
            <v-list-item
                link
                @click="selectedIndex=index"
                :class="selectedIndex === index ? 'selected' : ''"

            >
              <v-list-item-icon>
                <v-icon color="white">
                  {{ navItem.icon }}
                </v-icon>
                <v-list-item-content>
                  <v-list-item-title
                      class="ml-2 mt-1 font-weight-medium"
                      style="color: white; font-family: 'Poppins', sans-serif"
                  >
                    {{ $t(navItem.displayName).toUpperCase() }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item-icon>
            </v-list-item>
          </router-link>

          <template v-else>
            <v-list-item link
                         @click="navItem.handler"
                         :class="selectedIndex === index ? 'selected' : ''"
                         v-if="navItem.display"
            >
              <v-list-item-icon>
                <v-icon color="white">{{ navItem.icon }}</v-icon>
                <v-list-item-content>
                  <v-list-item-title
                      class="ml-2 mt-1 font-weight-medium"
                      style="color: white; font-family: 'Poppins', sans-serif"
                  >
                    {{ $t(navItem.displayName).toUpperCase() }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item-icon>
            </v-list-item>
          </template>

        </span>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app color="#607c24" min-height="70px" elevation="0">
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" color="white"></v-app-bar-nav-icon>
      <div class="app_bar_container">
        <b class="app_bar_item" v-if="show">
          {{ $t(navItems[selectedIndex].appBar) }}
        </b>
      </div>
    </v-app-bar>
    <v-main class="main_container pa-10 mt-10">
      <Spinner color="#e4c932"/>
      <router-view/>
    </v-main>
    <v-footer
        v-if="false"
        fixed
        style="color: rgba(0, 0, 0, 0.5)"
        class="footer"
    >
      <div>
        Version: {{ apiVersion }} / Solvesall - CA
      </div>
    </v-footer>
  </v-app>
  <v-app v-else>
    <Snackbar/>
    <Spinner color="#e4c932"/>
    <router-view/>
  </v-app>
</template>

<script>
import Snackbar from "../components/Snackbar";
import Spinner from "../components/Spinner";
import {bus} from "../main";
import WebsocketManager from "../utils/socketManager";
import {store} from "../store";

export default {
  name: "TarifficCustomerApp",
  components: {Spinner, Snackbar},
  data: function () {
    return {
      drawer: false,
      navItems: [
        {
          displayName: 'Home',
          name: 'Main',
          icon: 'mdi-home-outline',
          appBar: 'Home',
          display: true
        },
        {
          displayName: 'Classifier',
          name: 'Classifier',
          icon: 'mdi-chart-bell-curve',
          appBar: 'Tariffic HS-classifier',
          display: true
        },
        {
          displayName: 'Monitoring',
          name: 'Monitoring',
          icon: 'mdi-monitor',
          appBar: 'Tariffic API call overview',
          display: true
        },
        {
          displayName: 'History',
          name: 'History',
          icon: 'mdi-history',
          appBar: 'Tariffic API history overview',
          display: true
        },
        {
          displayName: 'Hazardous Commodities',
          name: 'Hazardous-Commodities',
          icon: 'mdi-database',
          appBar: 'Hazardous commodities',
          display: true
        },
        {
          displayName: 'Algorithm training data',
          name: 'AlgorithmTraining',
          icon: ' mdi-timer-outline',
          appBar: 'Algorithm training data',
          display: false
        },
        {
          displayName: 'Logout',
          icon: 'mdi-logout',
          display: true,
          handler: this.handleLogout
        }
      ],
      selectedIndex: 0,
      socket: new WebsocketManager()
    }
  },
  computed: {
    loggedIn() {
      return this.$store.getters['auth/isLoggedIn'] || this.$store.getters['auth/validRefreshToken'];
    },
    show() {
      const screen = this.$vuetify.breakpoint.name;
      return screen !== 'xs' && screen !== 'sm';
    },
    apiVersion() {
      return this.$store.getters['version/apiVersion'];
    }
  },
  mounted() {
    const role = this.$store.getters['auth/getRole'];
    document.title = 'Tariffic - AI';
    if (!role || role !== 'CUSTOMER') {
      this.$store.dispatch('auth/logout');
      return this.$router.push({
        name: 'Login'
      });
    }
    if (this.$route.path !== '/' && this.loggedIn) {
      this.$router.push({
        name: 'Main'
      });
    } else if (this.$route.path !== '/login' && !this.loggedIn) {
      this.$router.push({
        name: 'Login'
      });
    }
  },
  watch: {
    loggedIn(newVal) {
      if (!newVal && this.$route.path !== '/login') {
        this.$router.push({
          name: 'Login'
        });
      }
    },
    drawer(val) {
      bus.$emit('headerChange', val);
    }
  },
  methods: {
    handleLogout() {
      this.$store.dispatch('auth/logout');
      window.location.reload();
    }
  },
  created() {
    store.watch(
        state => state.auth.authToken,
        (newVal) => {
          this.socket.refreshToken(newVal);
        }
    );
    this.socket.refreshToken(this.$store.getters['auth/getAuthToken']);
    this.$store.dispatch('bulk-settings/getBulkImportProcessInfo');
    this.$store.dispatch('bulk-settings/fetchRefreshInProgress');
  },
  beforeDestroy() {
    this.socket.close()
  }
}
</script>

<style scoped>
.navigationHeaderTitle {
  display: flex;
  justify-content: center;
  color: white;
  font-family: 'Poppins', sans-serif;
  font-size: 3rem;
}

.nav_item {
  text-decoration: none;
}

.app_bar_container {
  margin-left: 2%;
}

.app_bar_item {
  font-weight: 500;
  font-size: 1.3rem;
  color: white;
  font-family: 'Poppins', sans-serif;
}

.selected {
  background-color: rgba(0, 0, 0, 0.1);
}

.main_container {
  background-color: rgba(0, 0, 0, 0.04);
}

.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 0.9rem;
}
</style>