<template>
  <TarifficCard
      icon="mdi-account"
      :title="$t('Account information')"
  >
    <div class="accInfoContainer">

      <v-dialog
          v-model="dialog"
          persistent
          max-width="400px"
      >
        <v-card>
          <v-card-title>
            <span class="text-h5" style="color: #607c24">
              {{$t('Change your password')}}
            </span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>

              </v-row>
            </v-container>
            <v-form ref="changePwForm">
              <v-text-field
                  v-model="passReset.password"
                  color="#607c24"
                  type="password"
                  outlined
                  :rules="[passRules]"
                  :label="$t('Password')"
              />
              <v-text-field
                  v-model="passReset.repeatPassword"
                  color="#607c24"
                  type="password"
                  outlined
                  :rules="[passRules, resetPasswordMatchRule]"
                  :label="$t('Repeat password')"
              />
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="red"
                text
                @click="dialog = false"
            >
              {{$t('Close')}}
            </v-btn>
            <v-btn
                color="#607c24"
                text
                @click="handlePasswordReset"
            >
              {{$t('Save')}}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <div class="accForm" v-if="this['tariffic-account-information/accountInformation']">
        <v-text-field
            outlined
            readonly
            :label="$t('Name')"
            :value="this['tariffic-account-information/accountInformation'].name || 'Unknown'"
            color="#607c24"
        />

        <v-text-field
            outlined
            readonly
            :label="$t('Email address')"
            :value="this['tariffic-account-information/accountInformation'].email"
            color="#607c24"
        />

        <v-text-field
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            readonly
            outlined
            :type="showPassword ? 'text' : 'password'"
            :label="$t('API key')"
            :value="this['tariffic-account-information/accountInformation'].key"
            @click:append="showPassword = !showPassword"
            color="#607c24"
        />

        <v-text-field
            readonly
            outlined
            :label="$t('Account created on')"
            value="27/4/2022"
            color="#607c24"
        />

        <v-select
            :label="$t('Language')"
            outlined
            color="#607c24"
            item-color="#607c24"
            :items="languages"
            :value="selectedLanguage"
            @change="handleLanguageChange"
            return-object
            item-text="name"
            item-value="value"
        />

        <div>
          {{$t('Account status')}}
          <span
              :class="this['tariffic-account-information/accountInformation'].status ? 'active' : 'inactive'"
              style="font-weight: bold"
          >
            {{this['tariffic-account-information/accountInformation'].status ? this.$t('ACTIVE') : this.$t('INACTIVE')}}
          </span>
        </div>

        <div class="buttonContainer pb-5">
          <v-btn
              color="#607c24"
              text-color="white"
              class="pt-5 pb-5 pr-12 pl-12"
              @click="dialog = true"
          >
                <span style="color: white">
                     {{$t('Change your password')}}
                </span>
          </v-btn>
        </div>
      </div>

    </div>
  </TarifficCard>
</template>

<script>
import TarifficCard from "./TarifficCard";
import {mapGetters} from "vuex";
import {issuePasswordResetReq} from "../../../api/tariffic-customer";
export default {
  name: "TariffAccountInformationCard",
  components: {
    TarifficCard
  },
  data: function () {
    return {
      showPassword: false,
      dialog: false,
      passReset: {
        password: '',
        repeatPassword: ''
      },
      passRules: [
          v => v.length  > 6 || this.$t('Password must be at least 6 characters long')
      ],
      languages: [
        {
          value: 'en',
          name: this.$t('English')
        },
        {
          value: 'sl',
          name: this.$t('Slovenian')
        }
      ]
    }
  },
  mounted() {
    const data = this.$store.getters["tariffic-account-information/accountInformation"]
    if (!data) {
      this.$store.dispatch('tariffic-account-information/getAccountInformation');
    }
  },
  computed: {
    ...mapGetters([
        'tariffic-account-information/accountInformation'
    ]),
    resetPasswordMatchRule() {
      return  () =>  {
        return this.passReset.password === this.passReset.repeatPassword || this.$t('Passwords must match');
      }
    },
    selectedLanguage() {
      return this.languages.find((it) => it.value === this.$store.getters['auth/getLanguage'])
    }
  },
  methods: {
    async handlePasswordReset() {
      const validForm = this.$refs.changePwForm.validate();
      if (!validForm) {
        return;
      }
      await issuePasswordResetReq(this.passReset)
      await this.$store.dispatch('snackbar/fireAlert', {
        message: this.$t('You have successfully reset your password'),
        color: 'success'
      });
      this.dialog = false;
      this.passReset = {
        password: '',
        repeatPassword: ''
      }
    },
    handleLanguageChange(lang) {
      if (lang) {
        this.$store.dispatch('auth/setLanguage', lang.value)
        location.reload()
      }
    }
  }
}
</script>

<style scoped>
.accInfoContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}
.accForm {
  margin-top: 12px;
  width: 100%;
}
.buttonContainer {
  margin-top: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.active {
  color: #607c24;
}
.inactive {
  color: red;
}
</style>