<template>
  <v-row justify="center">
    <v-dialog
        ref="dialog"
        v-model="modal"
        :return-value.sync="picker"
        persistent
        width="290px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
            v-model="picker"
            :label="$t('Date')"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            :color="color ? color : '#FBCB07'"
            v-on="on"
        ></v-text-field>
      </template>
      <v-date-picker
          v-model="picker"
          scrollable
          :color="color ? color : '#FBCB07'"
      >
        <v-spacer></v-spacer>
        <v-btn
            text
            :color="color ? color : '#FBCB07'"
            @click="modal = false"
        >
          {{$t('Cancel')}}
        </v-btn>
        <v-btn
            text
            :color="color ? color  : '#FBCB07'"
            @click="$refs.dialog.save(picker)"
        >
          {{$t('Ok')}}
        </v-btn>
      </v-date-picker>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: "DatePicker",
  props: ['color'],
  data: function () {
    return {
      picker: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      modal: false
    }
  },
  watch: {
    picker: function (newDate) {
      const fromDate = Math.floor(new Date(newDate).getTime() / 1000);
      this.$emit('onDate', fromDate);
    }
  },
  mounted() {
    this.$emit('onDate', Math.floor(new Date(this.picker).getTime() / 1000));
  }
}
</script>

<style scoped>

</style>
