import {getTariffDescription} from "../api/tariffs";

export const tariffDescriptions = {
    namespaced: true,
    name: "tariffDescriptions",
    state: {
        tariffDescriptions: {}
    },
    mutations: {
        storeTariffDescription (state, value) {
            state.tariffDescriptions = {
                ...state.tariffDescriptions,
            }
            state.tariffDescriptions[value.hsPrefix.toString()] = value;
        }
    },
    actions: {
        async fetchTariffDescription (context, prefix) {
            const tariffDescription = await getTariffDescription(prefix);
            context.commit('storeTariffDescription', tariffDescription);
        }
    },
    getters: {
        getTariffDescription: (state) => (tariffPrefix) => {
            return state.tariffDescriptions[tariffPrefix];
        },
        getTariffDescriptions : (state) => state.tariffDescriptions
    }
};
