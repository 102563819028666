import {i18n} from "../i18n/i18n";
import {parseBulkData, prepBasicStatistics} from "../utils/bulkimportParser";
import {getBulkProcessState, getRefreshState, issueBulkImport, issueRefreshState} from "../api/tariffic-customer";

export const bulkImport = {
    namespaced: true,
    state: {
        importSettings: {
            languageAutoDetection: false,
            translation: false,
            importDataFormat: 'csv',
            sourceLanguage: 'en',
        },
        entries: [],
        errorReadingFile: '',
        readingFilePending: false,
        importStats: {
            currentState: 'WAITING',
            totalCount: 0,
            uniqueCount: 0,
            progress: ''
        },
        refreshInProgress: false
    },
    mutations: {
        clearImportData(state) {
          state.importStats = {
              currentState: 'WAITING',
              totalCount: 0,
              uniqueCount: 0,
              progress: ''
          };
          state.entries = [];
        },
        setSettings(state, settings) {
            state.importSettings = {
                ...state.importSettings,
                ...settings
            }
        },
        setEntries(state, entries) {
            state.entries = entries;
        },
        setErrorReadingFile(state, errMessage) {
            state.errorReadingFile = errMessage;
        },
        setReadingFilePending(state, pending) {
            state.readingFilePending = pending;
        },
        setImportStats(state, stats) {
            state.importStats = {
                ...state.importStats,
                ...stats
            }
        },
        setRefreshInProgress(state, refreshInProgress) {
            state.refreshInProgress = refreshInProgress;
        }
    },
    actions: {
        setSettings(context, partialSettings) {
            context.commit('setSettings', partialSettings);
        },
        setStats(context, partialStats) {
          context.commit('setImportStats', partialStats);
        },
        setRawEntries(context, file) {
            return new Promise((resolve, reject) => {
                context.commit('setReadingFilePending', true);
                const reader = new FileReader();

                const setErrorMessage = () => {
                    context.commit('setErrorReadingFile', i18n.t('Error reading file ...'))
                    context.commit('setReadingFilePending', false);
                    reject();
                }

                reader.onload = () => {
                    const format = context.state.importSettings.importDataFormat;
                    if (!format) setErrorMessage()
                    else {
                        const bulkData = parseBulkData(reader.result, format)
                        console.log(bulkData)
                        context.commit('setEntries', bulkData);

                        const basicStats = prepBasicStatistics(bulkData);
                        context.commit('setImportStats', {
                            ...basicStats,
                            currentState: 'Ready'
                        });
                        resolve();
                    }
                }
                reader.onerror = () => {
                    setErrorMessage();
                }

                reader.onabort = () => {
                    setErrorMessage();
                }
                reader.readAsText(file);
            });
        },
        clearImportData(context) {
            context.commit('clearImportData');
        },
        async getBulkImportProcessInfo(context) {
            const res = await getBulkProcessState();
            context.commit('setImportStats', {
                currentState: res.state === 'FINISHED' ? 'WAITING' : res.state
            });
        },
        async issueImportBulkImport(context) {
            context.commit('setImportStats', {
                currentState: "IN_PROGRESS",
                progress: 0,
                totalCount: 0,
                uniqueCount: 0,
            });
            const entries = context.state.entries;
            const useTranslation = context.state.importSettings.translation;
            const useAutoDetection = context.state.importSettings.languageAutoDetection;
            const sourceLang = context.state.importSettings.sourceLanguage;
            context.commit('setEntries', [])
            await issueBulkImport({
                sourceLang: useTranslation ? sourceLang: null,
                useAutoDetect: useAutoDetection,
                trainingInstances: entries
            });
        },
        async fetchRefreshInProgress(context) {
            const refreshInProgress = await getRefreshState();
            context.commit('setRefreshInProgress', refreshInProgress.state);
        },
        async issueRefresh(context) {
            await issueRefreshState();
            context.commit('setRefreshInProgress', true);
        }
    },
    getters: {
        settings: (state) => state.importSettings,
        errMsg: (state) => state.errorReadingFile,
        importStats: (state) => state.importStats,
        dataToImport: (state) => state.entries,
        refreshInProgress: (state) => state.refreshInProgress
    }
}