import {
    createTrainingInstance,
    deleteTrainingInstance,
    getTrainingBasicInfo,
    getTrainingInstances, getTranslation
} from "../api/training-instance";

export const trainingInstance = {
    namespaced: true,
    state: {
        algorithmData: [],
        algorithmDataFetchInProgress: undefined,
        trainingDataStatus: undefined,
        translationData: undefined
    },
    mutations: {
        setAlgorithmData(state, data) {
            state.algorithmData = data;
        },
        setAlgorithmDataFetchInProgress(state, progress) {
            state.algorithmDataFetchInProgress = progress;
        },
        appendAlgorithmData(state, data) {
            state.algorithmData = [
                ...state.algorithmData,
                ...data
            ];
        },
        appendAlgorithmInstance(state, data) {
            state.algorithmData.push(data);
        },
        removeFromAlgorithmData(state, item) {
            state.algorithmData = state.algorithmData.filter((it) => it.id !== item.id);
        },
        setTrainingDataStatus(state, item) {
            state.trainingDataStatus = item;
        },
        incrementItem(state, {key, increment}) {
            const trainDataStatus = {...state.trainingDataStatus}
            if (trainDataStatus[key]) trainDataStatus[key] += increment
            state.trainingDataStatus = trainDataStatus;
        },
        setTranslationData(state, data) {
            state.translationData = data;
        }
    },
    actions: {
        async getAlgorithmData(context, {offset, limit, init}) {
            if (init) {
                context.commit('setTranslationData', undefined);
                context.commit('setAlgorithmData', []);
                context.commit('setAlgorithmDataFetchInProgress', true);
            }
            try {
                const res = await getTrainingInstances(offset, limit);
                context.commit('appendAlgorithmData', res)
            } catch (e) {
                console.log(e)
            } finally {
                context.commit('setAlgorithmDataFetchInProgress', false)
            }
        },
        async addInstanceToAlgorithm(context, req) {
            const res = await createTrainingInstance(req);
            context.commit('appendAlgorithmInstance', res);
        },
        async deleteInstanceFromAlgorithm(context, item) {
            await deleteTrainingInstance(item.id)
            context.commit('removeFromAlgorithmData', item)
            if (item.inVector === 0) {
                // Meaning it was not included, so we need to decrease count
                context.commit('incrementItem', { key: 'notInUseCount', increment: -1 })
                context.commit('incrementItem', { key: 'toBeDeletedCount', increment: 1 })
            }
        },
        async getTrainingDataStatus(context) {
            const basicInfo = await getTrainingBasicInfo();
            context.commit('setTrainingDataStatus', basicInfo)
        },
        async getTranslationData(context, text) {
            const result = await getTranslation(text);
            context.commit('setTranslationData', result);
        },
        clearTranslation(context) {
            context.commit('setTranslationData', undefined)
        }
    },
    getters: {
        algorithmData: (state) => state.algorithmData,
        algorithmDataFetchInProgress: (state) => state.algorithmDataFetchInProgress,
        trainingDataStatus: (state) => state.trainingDataStatus,
        translationData: (state) => state.translationData
    }
}