import {store} from "../../store";
import {createCommodity, deleteCommodity, getHazardousCommodities} from "../../api/tariffic-customer";

export const hazardousCommodities = {
    namespaced: true,
    state: {
        commoditiesData: [],
        filterCommoditiesValue: {
            'restrictionType': 'DANGER',
            'restrictedOn': 'ALL',
        },
        fetchInProgress: false
    },
    mutations: {
        setFetchInProgress(state, data) {
            state.fetchInProgress = data
        },
        appendCommoditiesData(state, data) {
            state.commoditiesData = [
                ...state.commoditiesData,
                ...data
            ]
        },
        clearCommoditiesData(state) {
            state.commoditiesData = [];
        },
        deleteCommodity(state, id) {
            state.commoditiesData = state.commoditiesData.filter((it) => it.id !== id)
        },
        addCommodity(state, commodity) {
            state.commoditiesData = [
                ...state.commoditiesData,
                commodity
            ]
        },
        setFilter(state, filter) {
            state.filterCommoditiesValue = filter;
        }
    },
    actions: {
        async fetchHazardousCommodities(ctx, { offset, limit }) {
            const mStore = store.state["hazardous-commodities"]
            const isAlreadyFetching = mStore.fetchInProgress;
            const filter = mStore.filterCommoditiesValue

            if (isAlreadyFetching) return;
            try {
                ctx.commit('setFetchInProgress', true);
                const result = await getHazardousCommodities(
                    offset,
                    limit,
                    filter.restrictionType,
                    filter.restrictedOn
                );
                ctx.commit('appendCommoditiesData', result);
            } catch (e) {
                console.log(e)
            } finally {
                ctx.commit('setFetchInProgress', false)
            }
        },
        clearData(ctx) {
            ctx.commit('clearCommoditiesData')
        },
        async deleteHazardousCommodity(ctx, { id }) {
            await deleteCommodity(id);
            ctx.commit('deleteCommodity', id)
        },
        async createHazardousCommodity(ctx, data) {
            const res = await createCommodity(data)
            const filter = store.state["hazardous-commodities"].filterCommoditiesValue;
            if (filter.restrictedOn === res.restrictedOn && filter.restrictionType === res.restrictionType) {
                ctx.commit('addCommodity', res)
            }
        },
        changeFilter(ctx, data) {
            ctx.commit('setFilter', data);
        }
    },
    getters: {
        commoditiesData: (state) => state.commoditiesData,
        fetchInProgress: (state) => state.fetchInProgress,
        filterCommoditiesValue: (state) => state.filterCommoditiesValue
    }
}