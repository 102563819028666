import {i18n} from "@/i18n/i18n";

export function daysInMonth (month, year) {
    return new Date(year, month, 0).getDate();
}

export const getNameOfMonth = (month) => {
    const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];
    return i18n.t(monthNames[month]);
}

export const toDateFormat = (seconds) => {
    const date = new Date(seconds);
    return `${date.getDate() + 1}.${date.getMonth() + 1}.${date.getFullYear()}`
}