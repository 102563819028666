import http from '@/utils/httpClient'
import {store} from "../store";
import {i18n} from "@/i18n/i18n";
import quietHttpClient from "../utils/quietHttpClient";

export const prediction = {
    namespaced: true,
    state: {
        predictionOBJ: null,
        predictionArray: [],
        additionalInfoArray: [],
        notConfident: false,
        loading: false, // true while waiting for response
    },
    mutations: {
        setPredictionObj: (state, predictionObj) => state.predictionOBJ = predictionObj,
        setPredictionArray: (state, predictionArray) => state.predictionArray = predictionArray,
        setAdditionalInfoArray: (state, additionalInfoArray) => state.additionalInfoArray = additionalInfoArray,
        addPredictionArrayItem: (state, predictionArrayItem) => state.predictionArray.push(predictionArrayItem),
        addAdditionalInfoArrayItem(state, additionalInfoArrayItem) { state.additionalInfoArray.push(additionalInfoArrayItem); state.additionalInfoArray.sort((a, b) => a.tariff - b.tariff) },
        setNotConfident: (state, notConfident) => state.notConfident = notConfident,
        setLoading: (state, loading) => state.loading = loading,
    },
    actions: {
        setPredictionObj: (context, payload) => context.commit('setPredictionObj', payload),
        setPredictionArray: (context, payload) => context.commit('setPredictionArray', payload),
        setAdditionalInfoArray: (context, payload) => context.commit('setAdditionalInfoArray', payload),
        addPredictionArrayItem: (context, payload) => context.commit('addPredictionArrayItem', payload),
        addAdditionalInfoArrayItem: (context, payload) => context.commit('addAdditionalInfoArrayItem', payload),
        setNotConfident: (context, payload) => context.commit('setNotConfident', payload),
        setLoading: (context, payload) => context.commit('setLoading', payload),
        getPrediction: async (context, payload) => {
            context.dispatch('setLoading', true).then()
            context.dispatch('setPredictionArray', []).then()
            context.dispatch('setAdditionalInfoArray', []).then()

            if (!accountInfo && process.env.VUE_APP_VERSION === 'TARIFFIC-CUSTOMER') {
                await store.dispatch('tariffic-account-information/getAccountInformation');
            }
            const accountInfo = process.env.VUE_APP_VERSION === 'TARIFFIC-CUSTOMER' ? store.getters["tariffic-account-information/accountInformation"] : ''

            const BASE_URL = process.env.VUE_APP_VERSION === 'TARIFFIC-ADMIN' ? `${process.env.VUE_APP_API_URL}/tariffe_predict` : `${process.env.VUE_APP_API_URL}/tariffic/api/predict`

            const COLOR = process.env.VUE_APP_VERSION === 'TARIFFIC-ADMIN' ?  '#000' : '#607c24'

            http.post(
                `${BASE_URL}?lang_detect=${payload.langDetection}&conf_detect=true&restriction_detect=true`,
                { 'description': payload.description },
                { headers: { 'tariffic-api-key': accountInfo ? accountInfo.key : '' } }
                ).then((res) => {
                context.dispatch('setLoading', false).then()
                if (res.data.detectedLanguage !== 'sl' && payload.langDetection && res.data.translatedText.length > 0) { // when description is translated
                    context.dispatch('setPredictionObj', res.data.translatedPrediction).then()
                    context.dispatch('snackbar/fireAlert', {
                        message: i18n.t('Detected language note')
                            .replace("${language}", res.data.detectedLanguage)
                            .replace("${original}", payload.description)
                            .replace("${translate}", res.data.translatedText),
                        color: COLOR
                    }, { root: true }).then()
                } else {
                    context.dispatch('setPredictionObj', res.data.originalPrediction).then()
                }
                context.dispatch('setAdditionalInfo').then()
            }).catch((err) => {
                context.dispatch('setLoading', false).then()
                console.log(err);
            })
        },
        setAdditionalInfo: (context) => {
            const accuracy_obj = context.state.predictionOBJ.accuracy;
            const tariff_number = context.state.predictionOBJ.tariff_number;
            const confidence_obj = context.state.predictionOBJ.isConfident;
            context.dispatch('setNotConfident', !confidence_obj['L0']).then()
            for (const property in tariff_number) {
                const obj = {
                    prefix: tariff_number[property],
                    precission: (accuracy_obj[property] * 100).toFixed(2) + "%",
                    confidence: confidence_obj[property]
                }
                context.dispatch('addPredictionArrayItem', obj).then()
            }
            let prefix = ''
            for (const obj of context.state.predictionArray) {
                prefix += obj.prefix;
                const mPrefix = prefix;
                    quietHttpClient.get(`${process.env.VUE_APP_API_URL}/description/${prefix}`).then((res) => {
                    const result = res.data
                    if (result.hsDescription) {
                        context.dispatch('addAdditionalInfoArrayItem', {
                            info: (result.hsDescription).replace(/<[^>]*>?/gm, '').replace(result.hsPrefix, ' - ').replace('&hellip;', '').toLowerCase(),
                            tariff: result.hsPrefix
                        }).then()
                    }
                }).catch((err) => {
                    console.log(err, obj)
                    http.get(`${process.env.VUE_APP_API_URL}/description/official/${mPrefix}`).then((res) => {
                        const result = res.data
                        console.log(result)
                        if (result.match) {
                            /*data class HSOfficialDescriptionDTO(
                                val hsTariffPrefix: String,
                                val hsDescription: String,
                                val language: LanguageDTO) */
                            context.dispatch('addAdditionalInfoArrayItem', {
                                info: ` - ${result.match.hsDescription}`,
                                tariff: result.match.hsTariffPrefix
                            })
                        } else {
                            context.dispatch('addAdditionalInfoArrayItem', {
                                info: ' - /',
                                tariff: prefix
                            })
                        }
                    })
                })
            }
        },
    },
    getters: {
        predictionOBJ: state => state.predictionOBJ,
        predictionArray: state => state.predictionArray,
        additionalInfoArray: state => state.additionalInfoArray,
        notConfident: state => state.notConfident,
        loading: state => state.loading,
    }
};
