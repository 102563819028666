<template>
  <div>
    <Locked
        v-if="refreshInProgress"
        style="z-index: 1000"
        :message="$t('Functionality is disabled while retrain is in progress')"/>

    <DecisionModal ref="decisionModal" />
    <AlgorithmDataImportForm
        ref="algorithmDataImportModal"
        @onBulkStart="onBulkStart"
    />
    <EditTrainingInstanceModal
        ref="trainingInstanceModal"
        @dismiss="onDismiss"
        @save="onSave"
    />

    <v-row class="mt-12 justify-center">
      <v-col class="col-lg-6" cols="12">
        <p class="rowTitle">
          {{$t('Algorithm training data instances')}}
        </p>
        <v-divider />

        <TrainingInstancesTable
            :data="algorithmTrainingDataInstances"
            :data-fetch-in-progress="algorithmDataInstancesFetchInProgress"
            @editItem="onTrainingInstanceEdit"
            @deleteItem="onTrainingInstanceDelete"
            @nextPage="onNextPage"
            :locked="refreshInProgress"
        />
      </v-col>

      <v-col class="col-lg-6" cols="12">
        <p class="rowTitle">
          {{$t('Enter new training instance')}}
        </p>
        <v-divider />

        <AddNewTrainingInstanceForm
            ref="trainingInstanceForm"
            @requestTranslate="handleRequestTranslate"
            @onAdd="handleNewAdd"
            :locked="refreshInProgress"
        />

        <template  v-if="!refreshInProgress">
          <p class="rowTitle mt-15">
            {{$t('Status of training data')}}
          </p>
          <v-divider/>

          <TrainingInstancesBasicInfo
              :basic-info="trainingDataStatus"
              class="mt-1"
              @onIssue="onIssue"
          />
        </template>
      </v-col>
    </v-row>
    <v-fab-transition>
      <v-btn
          v-if="bulkImportProcessState !== 'IN_PROGRESS' && !refreshInProgress"
          @click="onFabClick"
          class="ma-1"
          color="white"
          fab
          dark
          small
          absolute
          bottom
          right
          large
      >
        <v-icon color="#607c24">mdi-database-import</v-icon>
      </v-btn>
    </v-fab-transition>
    <div class="linearProgressContainer">
      <v-slide-y-transition>
        <InfiniteProgressModal
            v-if="refreshInProgress"
            :title="$t('Retrain in progress')"
            :sub-title="$t('Your training data is being loaded, please wait ...')"
        />

        <LinearProgressModal
            v-else-if="bulkImportProcessState === 'IN_PROGRESS'"
            :title="$t('Bulk import process progress')"
            :sub-title="$t('Your bulk import process is in progress ...')"
            :progress="bulkImportProcessProgress"
        />
      </v-slide-y-transition>
    </div>
  </div>
</template>

<script>
import TrainingInstancesBasicInfo from "./components/TrainingInstancesBasicInfo";
import AddNewTrainingInstanceForm from "./components/AddNewTrainingInstanceForm";
import TrainingInstancesTable from "./components/TrainingInstancesTable";
import {paginationMixin} from "../../mixins/pagination";
import DecisionModal from "../DecisionModal";
import EditTrainingInstanceModal from "./components/EditTrainingInstanceModal";
import AlgorithmDataImportForm from "./components/AlgorithmDataImportForm";
import LinearProgressModal from "./components/LinearProgressModal";
import InfiniteProgressModal from "./components/InfiniteProgressModal";
import Locked from "./components/Locked";
export default {
  name: "TarifficAlgorithmTrainingData",
  components: {
    Locked,
    InfiniteProgressModal,
    LinearProgressModal,
    AlgorithmDataImportForm,
    EditTrainingInstanceModal,
    DecisionModal, TrainingInstancesTable, TrainingInstancesBasicInfo, AddNewTrainingInstanceForm},
  mixins: [paginationMixin],
  methods: {
    async handleRequestTranslate(text) {
      this.$refs.trainingInstanceForm.formLoading = true;
      await this.$store.dispatch('training-instance/getTranslationData', text);
    },
    async getNextPageForTrainingInstanceData(init) {
      if (this.algorithmDataInstancesFetchInProgress) return
      await this.$store.dispatch('training-instance/getAlgorithmData', {
        offset: this.paginationInfo.offset,
        limit: this.paginationInfo.limit,
        init: init
      });
      this.markPageFetch();
    },
    onTrainingInstanceEdit(item) {
      this.$refs.trainingInstanceModal.showModal(item);
    },
    async onTrainingInstanceDelete(item) {
      const result = await this.$refs.decisionModal.showModal({
        message: this.$t('Are you sure you want to delete training instance ?'),
        title: this.$t('Deleting training instance'),
        positiveDecision: this.$t('Delete'),
        negativeDecision: this.$t('Dismiss'),
      });
      if (result) await this.$store.dispatch('training-instance/deleteInstanceFromAlgorithm', item);
    },
    onNextPage() {
      this.getNextPageForTrainingInstanceData(false);
    },
    async handleNewAdd({ hsDescription, hsNumber }) {
      await this.$store.dispatch('training-instance/addInstanceToAlgorithm', {
        code: hsNumber,
        description: hsDescription
      });
      this.$refs.trainingInstanceForm.cleanupForm();
    },
    async onIssue() {
      const result = await this.$refs.decisionModal.showModal({
        message: this.$t('Issuing retrain procedure means models will be reloaded which can last several minutes. During loading time Tariffic API will not be able to  resolve any issued requests'),
        title: this.$t('Issuing retrain procedure'),
        positiveDecision: this.$t('Continue'),
        negativeDecision: this.$t('Dismiss')
      });
      if (result) {
        await this.$store.dispatch('bulk-settings/issueRefresh');
      }
    },
    onDismiss() {
      this.$refs.trainingInstanceModal.hideModal()
    },
    onSave() {
      // TODO: issue update request
    },
    onFabClick() {
      this.$refs.algorithmDataImportModal.openDialog();
    },
    async onBulkStart() {
      const result = await this.$refs.decisionModal.showModal({
        message: this.$t('Are you sure you want to start bulk import process ? If you used translation or language auto detection setting costs of translation will be added to your current monthly API consumption'),
        title: this.$t("Bulk import process"),
        positiveDecision: this.$t('Continue'),
        negativeDecision: this.$t('Dismiss')
      })
      if (result) {
        await this.$store.dispatch('bulk-settings/issueImportBulkImport');
        this.$refs.algorithmDataImportModal.closeDialog()
      }
    }
  },
  computed: {
    algorithmTrainingDataInstances() {
      return this.$store.getters['training-instance/algorithmData'];
    },
    algorithmDataInstancesFetchInProgress() {
      return this.$store.getters['training-instance/algorithmDataFetchInProgress'];
    },
    trainingDataStatus() {
      return this.$store.getters['training-instance/trainingDataStatus'];
    },
    translationData() {
      return this.$store.getters['training-instance/translationData'];
    },
    bulkImportProcessProgress() {
      return this.$store.getters['bulk-settings/importStats'].progress;
    },
    bulkImportProcessState() {
      return this.$store.getters['bulk-settings/importStats'].currentState;
    },
    refreshInProgress() {
      return this.$store.getters['bulk-settings/refreshInProgress'];
    }
  },
  watch: {
    translationData: function (newVal) {
      if (!newVal && this.$refs.trainingInstanceForm.formLoading === true) {
        this.$refs.trainingInstanceForm.formLoading = false;
      } else if (newVal) {
        this.$refs.trainingInstanceForm.formLoading = true;
        this.$refs.trainingInstanceForm.hsDescription = newVal.translatedText;
        this.$store.dispatch('training-instance/clearTranslation');
      }
    }
  },
  async mounted() {
    this.paginationInfo.limit = 20;
    await this.getNextPageForTrainingInstanceData(true);
    await this.$store.dispatch('training-instance/getTrainingDataStatus');
  },
}
</script>

<style scoped>
.rowTitle {
  color: #607c24;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
  font-size: 1.4rem;
}
.linearProgressContainer{
  position: absolute;
  bottom: 5px;
  right: 5px;
  width: 400px;
  filter: drop-shadow(10px 5px 4px rgba(0, 0, 0, 0.5));
  z-index: 100;
}
</style>