<template>
  <div class="mainContainer">

    <p class="pa-0 pb-2 ma-0 sectionTitle">
      {{$t('Tariffic API')}}
    </p>

    <p class="listItem pa-0 ma-0">
      {{$t('Sum of all API calls')}}:
      <span class="listItemIt" :style="textStyle">
        {{apiCallsObj.totalApiCalls}}
      </span>
    </p>

    <p class="listItem pa-0 ma-0 mt-1">
      {{$t('Confident prediction count')}}:
      <span class="listItemIt" :style="textStyle">
        {{apiCallsObj.confidentPredictionCount}}
      </span>
    </p>

    <p class="listItem pa-0 ma-0 mt-1 pb-2">
      {{$t('Non confident prediction count')}}:
      <span class="listItemIt" :style="textStyle">
        {{apiCallsObj.nonConfidentPredictionCount}}
      </span>
    </p>

    <p class="pa-0 pb-2 ma-0 sectionTitle">
      {{$t('Google API')}}
    </p>

    <p class="listItem pa-0 ma-0 mt-1">
      {{$t('Access to translator service')}}:
      <span class="listItemIt" :style="textStyle">
        {{apiCallsObj.accessToTranslatorServiceCount}}
      </span>
    </p>

    <p class="listItem pa-0 ma-0 mt-1">
      {{$t('Access to language detection')}}:
      <span class="listItemIt" :style="textStyle">
        {{apiCallsObj.accessToGoogleDetect}}
      </span>
    </p>

    <p class="listItem pa-0 ma-0 mt-1">
      {{$t('Access to text translation')}}:
      <span class="listItemIt" :style="textStyle">
        {{apiCallsObj.accessToGoogleTranslate}}
      </span>
    </p>
  </div>
</template>

<script>
export default {
  name: "TarifficNumberOfCalls",
  props: ['apiCallsObj', 'color'],
  computed: {
    textStyle() {
      return {
        color: this.color ? this.color : '#607c24'
      }
    }
  }
}
</script>

<style scoped>
.mainContainer {
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: column;
}
.listItem {
  color: rgba(0, 0, 0, 0.5);
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 1rem;
}
.listItemIt {
  font-weight: 500;
}
.sectionTitle {
  color: #607c24;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
}
</style>