<template>
  <TarifficCard
      icon="mdi-file-document-multiple"
      :title="$t('Subscription plan information')"
  >

    <div class="subscriptionInfoContainer">
      <template v-if="mySubscription">
        <v-row>
          <v-col class="col-lg-8 col-sm-12 mt-5 pt-1 pb-1" cols="12">
          <span>
            {{ $t('Subscription plan') }}
          </span>
            <span>
            <b>
              {{ mySubscription.name }}
            </b>
          </span>
          </v-col>
          <v-col class="col-lg-8 col-sm-12 pt-1 pb-1" cols="12" v-if="false">
          <span>
            {{ $t('Subscription plan activated') }}
          </span>
            <span>
            <b>
              13.5.2021
            </b>
          </span>
          </v-col>
          <v-col class="col-lg-8 col-sm-12 pt-1 pb-1" cols="12">
          <span>
            {{ $t('Translation enabled') }}
          </span>
            <span>
            <b>
              {{ mySubscription.translationAllowed ? $t('Yes') : $t('No') }}
            </b>
          </span>
          </v-col>
          <v-col class="col-lg-8 col-sm-12 pt-1 pb-1" cols="12">
          <span>
            {{ $t('Hazardous commodities enabled') }}
          </span>
            <span>
            <b>
              {{ mySubscription.hazardousComAllowed ? $t('Yes') : $t('No') }}
            </b>
          </span>
          </v-col>
          <v-col class="col-lg-8 col-sm-12 pt-1 pb-1" cols="12">
          <span>
            {{$t('Number of calls per month')}}
          </span>
            <span>
            <b>
              {{ numberOfApiCalls }} / {{ mySubscription.numberOfCallsPerMonth ? mySubscription.numberOfCallsPerMonth : '∞' }}
            </b>
          </span>
          </v-col>
        </v-row>
      </template>
      <template v-else-if="!mySubscription && !isSubscriptionFetching">
        <div class="noSubscriptionContainer">
          <v-icon color="error" x-large>
            mdi-alert
          </v-icon>
          <p class="noSubscriptionText mt-5">
            {{$t('No subscription is currently active')}}
          </p>
        </div>
      </template>
      <div class="buttonContainer mt-10">
        <v-btn
            color="#607c24"
            text-color="white"
            class="pt-5 pb-5 pr-12 pl-12"
        >
          <a
              style="color: white; text-decoration: none"
              href="mailto: info@solvesall.com?subject=Tariffic API - Subscription plan change"
              target="_blank"
          >
            {{$t('Change your subscription plan')}}
          </a>
        </v-btn>
      </div>
    </div>

  </TarifficCard>
</template>

<script>
import TarifficCard from "./TarifficCard";

export default {
  name: "TarifficSubscriptionInfoCard",
  components: {TarifficCard},
  computed: {
    isSubscriptionFetching() {
      return this.$store.getters['tariffic-account-information/subscriptionInFetch'];
    },
    mySubscription() {
      return this.$store.getters['tariffic-account-information/mySubscription'];
    },
    numberOfApiCalls() {
      return this.$store.getters['tariffic-account-information/apiCount'];
    }
  },
  created() {
    this.$store.dispatch('tariffic-account-information/getMySubscription');
    this.$store.dispatch('tariffic-account-information/getApiCount');
  }
}
</script>

<style scoped>
.buttonContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.noSubscriptionContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.noSubscriptionText {
  color: red;
  font-family: 'Poppins', sans-serif;
  font-size: 2rem;
  text-align: center;
}
</style>