<template>
  <div class="mt-10 main">
    <div
        :class="locked ? 'locked' : ''"
        @scroll="onScroll"
        :style="{maxHeight: maxHeight ? maxHeight :'65vh', overflowY: 'auto'}"
    >
      <TarifficNoTrainingData
          v-if="data.length === 0 && dataFetchInProgress === false"
      />
      <v-data-table
          v-else-if="data.length > 0"
          :hide-default-footer="true"
          :items-per-page="-1"
          :items="data"
          :headers="headers"
      >
        <template v-slot:item.actions="{ item }" v-if="!noAction">
          <v-btn
              icon
          >
            <v-icon
                class="ma-1"
                medium
                @click="onDelete(item)"
            >
              mdi-delete
            </v-icon>
          </v-btn>
          <v-btn
              icon
          >
            <v-icon
                class="ma-1"
                medium
                v-if="false"
                @click="onEdit(item)"
            >
              mdi-pencil
            </v-icon>
          </v-btn>
        </template>

        <template v-slot:item.description="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <span
                  class="descriptionEntry"
                  :style="{maxWidth: maxLineWidth ? maxLineWidth : '300px'}"
                  v-bind="attrs"
                  v-on="on"
              >
                {{item.description}}
              </span>
            </template>
            <div :style="{maxWidth: maxLineWidth ? maxLineWidth : '300px'}">
              <span>{{item.description}}</span>
            </div>
          </v-tooltip>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import TarifficNoTrainingData from "./TarifficNoTrainingData";
export default {
  name: "TrainingInstancesTable",
  components: {TarifficNoTrainingData},
  props: ['data', 'dataFetchInProgress', 'noAction', 'maxHeight', 'maxLineWidth', 'locked'],
  data: function () {
    return {

    }
  },
  methods: {
    onScroll({target: {scrollTop, clientHeight, scrollHeight}}) {
      if (scrollTop + clientHeight >= scrollHeight) {
        this.$emit('nextPage')
      }
    },
    async onDelete(item) {
      this.$emit('deleteItem', item);
    },
    async onEdit(item) {
      this.$emit('editItem', item);
    }
  },
  computed: {
    headers() {
      const base = [
        {
          text: this.$t('HS-tariff number'),
          align: 'start',
          sortable: false,
          value: 'code',
          width: '150px'
        },
        {
          text: this.$t('Description'),
          align: 'start',
          sortable: false,
          value: 'description'
        }
      ]
      if (!this.noAction) {
        base.push({
          text: this.$t('Actions'),
          align: 'start',
          sortable: false,
          value: 'actions'
        })
      }
      return base;
    }
  }
}
</script>

<style scoped>
.descriptionEntry {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
  max-width: 300px;
}
.descriptionEntry:hover {
  cursor: help;
  max-width: 300px;
}
.locked {
  -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(5px);
  pointer-events: none;
}
.main {
  position: relative;
}
</style>