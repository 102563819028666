<template>
  <v-container>
    <v-row class="mainContainer">
      <v-col class="col-lg-7 mt-10">
        <div>
          Vnesite tarifno stevilko
        </div>
        <div class="inputContainer mt-5">
          <div class="inputTariffContainer" style="flex:4">
            <input class="bar" v-model="tariffNumber" @keyup.enter="getPrediction">
          </div>
          <div style="flex:2;margin-left: 25px">
            <button class="button" @click="getPrediction">
              <div class="button_text">
                PREVERI STEVILKO
              </div>

              <div class="button_icon">
                <img src="@/assets/ic_search.svg">
              </div>
            </button>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row class="resultContainer">
      <v-col class="col-lg-7 mt-2" cols="7">
        <TariffDescription
            v-for="(item, index) in descriptionArr"
            :key="item.match ? item.match.hsTariffPrefix : `tariff-number-${index}`"
            :tariff-prefix="item.match ? item.match.hsTariffPrefix : ''"
            :tariff-description="item.match ? item.match.hsDescription : ''"
        />
      </v-col>
      <v-col class="col-lg-7 mt-1" cols="7" v-if="validTariff !== ''">
        <p class="validTariffTitle">
          Veljavnost tarifne stevilke:
          <span style="font-weight: 500" :class="validTariff ? 'valid' : 'invalid'">
            {{ validTariff ? 'VELJAVNA' : 'NEVELJVNA' }}
          </span>
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import TariffDescription from "./TariffDescription";
export default {
  name: "HSDescriptions",
  components: {TariffDescription},
  data: () => {
    return {
      tariffNumber: '',
      descriptionArr: [],
      validTariff: ''
    }
  },
  methods: {
    async getPrediction() {
      this.descriptionArr = []
      this.validTariff = ''

      if (!this.isValidTariff(this.tariffNumber)) {
        return this.$store.dispatch('snackbar/fireAlert', {
          message: 'Tarifna stevilka mora biti sode dolzine, vsebovati stevke ter biti dolzine vsaj 6',
          color: 'error'
        })
      }
      let tariff = ''
      for (let i = 0; i < this.tariffNumber.length - 1; i = i + 2) {
        const chunk = `${this.tariffNumber[i]}${this.tariffNumber[i+1]}`;
        tariff += chunk
        const res = await this.$http.get(`${process.env.VUE_APP_API_URL}/description/official/${tariff}`);
        this.descriptionArr.push(res.data);
      }
      this.validTariff = !!this.descriptionArr[this.descriptionArr.length - 1].match
    },
    isValidTariff(tariffNumber) {
      const isEven = tariffNumber.length % 2 === 0;
      const isTariff = tariffNumber.length >= 6;
      const isNumeric = /^\d+$/.test(tariffNumber);

      return isEven && isNumeric && isTariff
    }
  }
}
</script>

<style scoped>
.mainContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}
.inputTariffContainer {
  background-color: white;
  border-radius: 100px;
}
.bar {
  height: 60px;
  border-radius: 100px;
  background-color: white;
  border: none;
  text-align: left;
  transition: 1s;
  margin-left: 30px;
  font-size: 1.2rem;
}
input:focus {
  outline: none;
}
.inputContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.button_text {
  font-family: 'Montserrat', sans-serif;
  font-size: 0.85rem;
  font-weight: 100;
}
.button_icon {
  border: 1px solid black;
  margin-left: 10px;
  height: 20px;
  width: 20px;
}

.button {
  display: flex;
  background-color: black;
  color: white;
  border-radius: 100px;
  height: 60px;
  justify-content: center;
  align-items: center;
  min-width: 100px;
  padding: 0 15px;
}
.resultContainer {
  display: flex;
  justify-content: center;
}
.validTariffTitle {
  font-weight: 600;
  font-size: 2rem;
}
.valid {
  color: green;
}
.invalid {
  color: red;
}
</style>