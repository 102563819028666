<template>
  <div class="result_container" v-if="this['prediction/predictionArray'].length > 0">
    <v-alert type="error" :color="secondaryColor"
             v-if="this['prediction/notConfident'] || (this['prediction/predictionOBJ'] && this['prediction/predictionOBJ'].restrictions && this['prediction/predictionOBJ'].restrictions.length > 0)">
      <p v-if="this['prediction/notConfident']" class="pa-0 ma-0">{{ $t('Predicted tariff number has low confidence') }}</p>
      <p v-for="restriction in this['prediction/predictionOBJ'].restrictions" :key="restriction.id" class="pa-0 ma-0">
        <b>{{ restriction.tariffPrefix }}</b> - {{$t('Restriction type')}}<b
          class="ml-1">{{ getTypeString(restriction.restrictionType) }}</b>
        <span class="ml-2">
          {{$t('Restricted upon')}}<b class="ml-1">{{ getRestrictedUponString(restriction.restrictedOn) }}</b>
        </span>
      </p>
    </v-alert>
    <transition name="results-main" appear>
      <div class="result_container_main d-sm-flex align-end" v-if="predictionAvailable">
        <span class="pb-7">
          {{this.$t('Predicted tariff number')}}:
        </span>
        <div class="d-flex pl-sm-4 mb-1">
          <TariffPredictionBlock v-for="(predOBJ, index) in this['prediction/predictionArray']" :key="index"
                                 :prediction="predOBJ" :color="tariffColor"/>
        </div>
      </div>
    </transition>
    <transition name="results-description" appear>
      <ul class="list" v-if="predictionAvailable">
        <li v-for="(addOBJ, index) in this['prediction/additionalInfoArray']" :key="index" :data-index="index">
          <span class="tariff_prefix">{{ addOBJ.tariff }}</span>
          <span v-if="addOBJ.info.length > 0">{{ addOBJ.info }}</span>
          <span v-else> - {{ strings.CLASSIFIER.noInformationText }}</span>
        </li>
      </ul>
    </transition>
  </div>
</template>

<script>
import TariffPredictionBlock from "@/components/HSClassifier/TariffPredictionBlock";
import {mapGetters} from "vuex";
import {hazardTypesMixin} from "../../mixins/hazardTypes";

export default {
  name: "Results",
  props: {
    secondaryColor: String,
    tariffColor: String,
  },
  components: {TariffPredictionBlock},
  computed: {
    ...mapGetters([
      'prediction/predictionArray',
      'prediction/predictionOBJ',
      'prediction/additionalInfoArray',
      'prediction/notConfident',
    ]),
    predictionAvailable() {
      return this['prediction/predictionArray'].length > 0 && this['prediction/additionalInfoArray'].length > 0
    },
    strings() {
      return this.$store.getters['language/strings']
    },
  },
  destroyed() { // Life hook Vue 2
    this.$store.dispatch('prediction/setPredictionObj', {})
    this.$store.dispatch('prediction/setPredictionArray', [])
    this.$store.dispatch('prediction/setAdditionalInfoArray', [])
  },
  mixins: [hazardTypesMixin]
}
</script>

<style scoped>
.result_container_main {
  margin-top: 22px;
  margin-bottom: 60px;
}

.result_container_main span {
  font-size: 18px;
  line-height: 21px;
}

ul {
  list-style: none;
  padding: 0;
}

ul li {
  font-size: 18px;
  line-height: 18px;
  margin-bottom: 15px;
}

.tariff_prefix {
  font-weight: bold;
}

/* Transition */
.results-main-enter-active,
.results-main-leave-active {
  transition: all 0.3s ease-in-out;
}

.results-main-enter,
.results-main-leave-to {
  transform: translateX(-20px);
  opacity: 0;
}

.results-description-enter-active,
.results-description-leave-active {
  transition: all 0.3s ease-in-out 0.1s;
}

.results-description-enter,
.results-description-leave-to {
  transform: translateY(-20px);
  opacity: 0;
}

@media screen and (min-width: 600px) {
  .result_container_main {
    margin-bottom: 90px;
  }

}
</style>