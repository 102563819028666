<template>
  <transition name="search" appear>
    <div>
      <p v-if="!isTariffic">{{ strings.CLASSIFIER.instruction }}</p>
      <p v-else>
        {{$t('Enter the description of the goods in Slovenian language')}}
      </p>
      <div class="d-flex align-center mt-7">
        <input v-model="inputText" @keyup.enter="onSearch" class="tarifficInput">
        <Button
            :btn-disabled="inputText===''"
            :buttonText="$t('Classify').toString().toUpperCase()"
            @onClick="onSearch" :btnColor="btnColor"
            :type="type"
        />
      </div>
    </div>
  </transition>
</template>

<script>
import Button from "@/components/HSClassifier/Button";

export default {
  name: "Search",
  components: { Button },
  props: {
    btnColor: String,
    type: String
  },
  data() {
    return {
      inputText: '',
    }
  },
  computed: {
    strings() {
      return this.$store.getters['language/strings']
    },
    isTariffic() {
      return this.type === 'TARIFFIC'
    }
  },
  methods: {
    onSearch() {
      if(!this.$store.getters['prediction/loading']) { // wait until response from previous request is received
        this.$emit('onSearch', this.inputText)
      }
    }
  }
}
</script>

<style scoped>
div {
  margin-bottom: 20px; 
}
p {
  line-height: 19px;
}
input {
  outline: none;
  width: 100%;
  border-radius: 100px;
  background-color: white;
  padding: 12px 20px;
  margin-right: 20px;
}
@media screen and (min-width: 600px) {
  input {
    padding: 18px 30px;
  }
}

/* Transition */
.search-enter-active,
.search-leave-active {
  transition: all 0.3s ease-in-out 0.1s;
}
.search-enter,
.search-leave-to {
  transform: translateY(20px);
  opacity: 0;
}

.tarifficInput {

}
</style>