import httpClient from "../utils/httpClient";
import download from "downloadjs";

/**
 * Logs customer in
 */
export const logIn = async (email, password) => {
    const res  = await httpClient.post(`${process.env.VUE_APP_API_URL}/tariffic/login`, {
        emailAddress: email,
        password: password
    });
    return res.data;
}

/**
 * Gets user account information
 */
export const getAccountInformation = async () => {
    const res = await httpClient.get(`${process.env.VUE_APP_API_URL}/tariffic/accountInfo`);
    return res.data;
}

/**
 * Issues password reset request
 * @param req - request body
 */
export const issuePasswordResetReq = async (req) => {
    await httpClient.post(`${process.env.VUE_APP_API_URL}/tariffic/passwordReset`, req);
}

/**
 * Gets subscription plan
 */
export const getMySubPlan = async () => {
    const res = await httpClient.get(`${process.env.VUE_APP_API_URL}/tariffic/mySub`);
    return res.data
}

/**
 * Gets number of api calls
 */
export const getNumberOfApiCalls = async () => {
    const res = await httpClient.get(`${process.env.VUE_APP_API_URL}/tariffic/api/access-count`);
    return res.data;
}

/**
 * Gets commodities according to parameters
 * @param offset - pagination offset
 * @param limit - pagination limit
 * @param restrictionType - type of restriction
 * @param restrictedOn - on which persons is restriction opposed upon
 */
export const getHazardousCommodities = async (offset, limit, restrictionType, restrictedOn) => {
    const res = await httpClient.get(`${process.env.VUE_APP_API_URL}/tariffic/hazardous-commodities?restrictionType=${restrictionType}&restrictedOn=${restrictedOn}&offset=${offset}&limit=${limit}`);
    return res.data;
}

/**
 * Creates new entry in commodity
 * @param req - request body
 */
export const createCommodity = async (req) => {
    const res = await httpClient.post(`${process.env.VUE_APP_API_URL}/tariffic/hazardous-commodities`, req);
    return res.data
}

/**
 * Deletes commodity entry
 * @param commodityId - identifier of commodity
 */
export const deleteCommodity = async (commodityId) => {
    await httpClient.delete(`${process.env.VUE_APP_API_URL}/tariffic/hazardous-commodities/${commodityId}`);
}

/**
 * Gets statistics
 * @param year - which year
 * @param month - which year
 * @param day - which day
 */
export const getStatistics = async (year, month, day) => {
    const yearParam = year ? `?year=${year}` : '?';
    const monthParam = month ? `&month=${month}` : '';
    const dayParam = day ? `&day=${day}` : '';

    const res = await httpClient.get(`${process.env.VUE_APP_API_URL}/tariffic/statistics${yearParam}${monthParam}${dayParam}`);
    return res.data;
}

/**
 * Gets history data
 * @param fromDate - from which date (UNIX timestamp)
 * @param toDate - to which date (UNIX timestamp)
 * @param offset - pagination offset
 * @param limit - pagination limit
 */
export const getHistoryData = async (fromDate, toDate, offset, limit) => {
    const res = await httpClient.get(`${process.env.VUE_APP_API_URL}/tariffic/classificationHistory?fromDate=${fromDate}&toDate=${toDate}&offset=${offset}&limit=${limit}`);
    return res.data.result;
}

/**
 * Gets download history data
 * @param fromDate - from which date
 * @param toDate - to which date
 */
export const downloadHistoryTariffic = async (fromDate, toDate) => {
    const res = await httpClient.get(`${process.env.VUE_APP_API_URL}/tariffic/classificationHistory/csv?fromDate=${fromDate}&toDate=${toDate}`);
    download(res.data, `classificationHistory-${new Date(fromDate * 1000).toUTCString()}.csv`);
}

/**
 * Gets current api version
 */
export const getApiVersion = async () => {
    const res = await httpClient.get(`${process.env.VUE_APP_API_URL}/tariffic/version`);
    return res.data;
}

/**
 * Downloads manuals based on language
 * @param lang - language
 */
export const getManuals = async (lang) => {
    // const res = await httpClient.get(`${process.env.VUE_APP_API_URL}/sol_tariffic_manuals_${lang}.pdf`)
    // download(res.data, 'Tariffic-API-manuals.pdf')
    window.open(`${process.env.VUE_APP_API_URL}/sol_tariffic_manuals_${lang}.pdf`);
}

/**
 * Gets current information about bulk import process
 */
export const getBulkProcessState = async () => {
    const res = await httpClient.get(`${process.env.VUE_APP_API_URL}/tariffic/training-instances/bulk-import-state`);
    return res.data
}

/**
 * Issues bulk import
 * @param req - TrainingInstanceBulkDataReq (
 *  { sourceLang: String? = null, useAutoDetect: Boolean = false, trainingInstances: [{ code: String, description: String }]} )
 */
export const issueBulkImport = async (req) => {
    await httpClient.post(`${process.env.VUE_APP_API_URL}/tariffic/training-instances/bulk-import`, req)
}

/**
 * Gets refresh state
 */
export const getRefreshState = async () => {
    const res = await httpClient.get(`${process.env.VUE_APP_API_URL}/tariffic/training-instances/refresh-state`);
    return res.data
}

export const issueRefreshState = async () => {
    await httpClient.post(`${process.env.VUE_APP_API_URL}/tariffic/training-instance/refresh`);
}