import httpClient from "../utils/httpClient";

/**
 * Gets commodities according to parameters
 * @param offset - pagination offset
 * @param limit - pagination limit
 * @param restrictionType - type of restriction
 * @param restrictedOn - on which persons is restriction opposed upon
 */
export const getHazardousCommodities = async (offset, limit, restrictionType, restrictedOn) => {
    const res = await httpClient.get(`${process.env.VUE_APP_API_URL}/hazardousCommodities?restrictionType=${restrictionType}&restrictedOn=${restrictedOn}&offset=${offset}&limit=${limit}`);
    return res.data;
}

/**
 * Creates new entry in commodity
 * @param req - request body
 */
export const createCommodity = async (req) => {
    const res = await httpClient.post(`${process.env.VUE_APP_API_URL}/hazardousCommodities`, req);
    return res.data;
}

/**
 * Deletes commodity entry
 * @param commodityId - identifier of commodity
 */
export const deleteCommodity = async (commodityId) => {
    await httpClient.delete(`${process.env.VUE_APP_API_URL}/hazardousCommodities/${commodityId}`);
}
