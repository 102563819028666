import Vuex from 'vuex'
import Vue from 'vue'

Vue.use(Vuex)
Vue.config.devtools = true

// Importing modules
import {language} from "./store/language";
import {snackbar} from "./store/snackbar";
import {spinner} from "./store/spinner";
import {tariffDescriptions} from "./store/tariffDescriptions";
import {auth} from "./store/auth";
import {prediction} from "./store/prediction";
import {accountInfo} from "./store/tariffic/accountInfo";
import {subscriptions} from "./store/subscriptions";
import {hazardousCommodities} from "./store/tariffic/commodities";
import {statistics} from "./store/tariffic/statistics";
import {tarifficHistory} from "./store/tariffic/history";
import {customerStatistics} from "./store/customer-statistics";
import {version} from "./store/version";
import {trainingInstance} from "./store/trainingInstance";
import {bulkImport} from "./store/bulkImport";
import {customer} from "./store/customer";

export const store = new Vuex.Store({
    modules: {
        "language": language,
        "snackbar": snackbar,
        "spinner": spinner,
        "tariffDescriptions": tariffDescriptions,
        "auth": auth,
        "prediction": prediction,
        "tariffic-account-information": accountInfo,
        "subscription": subscriptions,
        "hazardous-commodities": hazardousCommodities,
        "tariffic-statistics": statistics,
        "tariffic-history": tarifficHistory,
        "customers-statistics": customerStatistics,
        "version": version,
        "training-instance": trainingInstance,
        "bulk-settings": bulkImport,
        "customer": customer
    }
})
