module.exports = {
    ROOT: {
        displayNameDashboard: 'NADZORNA PLOSCA',
        appBarDashboard: 'Nadzorna plošča',
        displayNameMonitoring: 'MONITORIRANJE',
        appBarMonitoring: 'Pregled dostopa do API-ja',
        displayNameClassifier: 'HS KLASIFIKATOR',
        appBarClassifier: 'HS-klasifikator demo aplikacija',
        displayNameUserManagement: 'UPORABNIKI',
        appBarUserManagement: 'Upravljanje z uporabniki',
        languagePicker: 'Izberite jezik',
        displayNameHazardousCommodities: 'NEVARNE SNOVI',
        appBarHazardousCommodities: 'Pregled nevarnih snovi',
        displayNameHSMapping: 'PRESLIKOVALNE TABELE',
        appBarHSMapping: 'Pregled preslikovalnih tabel',
        displayNameHSHistory: 'ZGODOVINA',
        displayNameLogout: 'ODJAVA',
        appBarHSHistory: 'Pregled zgodovine klasifikatorja',
        displayNameHSDescription: 'HS OPISI',
        appBarHSDescription: 'HS-opisi demo aplikacija',
        displayNameCustomersManager: 'STRANKE',
        appBarCustomersManager: 'Upravljanje s strankami',
    },
    CLASSIFIER: {
        instruction: 'Vnesite opis blaga v slovenskem jeziku',
        buttonText: 'KLASIFICIRAJ',
        detectionTextFalse: 'Vkljuci avtomatsko detekcijo jezika',
        detectionTextTrue: 'Izkljuci avtomatsko detekcijo jezika',
        classificationTitle: 'Napovedana HS tarifna stevilka:',
        noInformationText: 'Na voljo ni infromacij o tarifni stevilki',
        notConfident: 'Napovedana tarifna stevilka ima nizko zanesljivost'
    },
    DASHBOARD: {
        levelClassificationTitle: 'Utezena klasifikacijska tocnost modelov po nivojih',
        levelClassificationGraphTitle: 'Klasifikacijska tocnost modelov po nivojih vizualno',
        levelDataSupport: 'Podatkovna zastopanost',
        levelDataSupportInfo: 'Podatkovna zastopanost je izracunana kot povprecje kolicine podatkov, ki se nahaja na dolocenem nivoju',
        trainingInfoTitle: 'Informacije o treningu',
        trainingStatusRun: 'Trening je v teku',
        trainingStatusEnd: 'Trening se je zakljucil, sistem je v stanju cakanja na nov cikel treninga',
        trainingPlanTitle: 'Trening plan',
        trainingPlanTitleButton: 'Posodobi trening plan',
        trainingPlanDataMarginText: 'Minimalno stevilo novih podatkov za vnovicno treniranje',
        trainingPlanErrorMarginText: 'Maksimalna absolutna klasifikacijska napaka pri vnovicnem treniranju',
        trainingPlanIntervalUpdateText: 'Sistem se posodobalja v intervalih',
        trainingStatus: 'Stanje',
        trainingInfoText: 'Naslednji trening je nacrtovan',
        trainingPlanUpdateTitle: 'Posodobitev trenutnega trening plana',
        trainingPlanUpdateSubtitle: 'Nastavite nove parametre treniranja',
        trainingPlanUpdateDataMargin: 'Minimalno stevilo novih podatkov',
        trainingPlanUpdateErrorMargin: 'Minimalna absolutna napaka v %',
        trainingPlanUpdateMonth: 'Mesecev',
        trainingPlanUpdateDay: 'Dni',
        trainingPlanUpdateHour: 'Ur',
        trainingPlanRadioWait: 'Pocakaj ze na cakajoci trening',
        trainingPlanRadioContinue: 'Ne cakaj na cakajoci trening',
        trainingPlanUpdate: 'POSODOBI',
        trainingPlanClose: 'ZAPRI',
        trainingPlanDataMarginInfo: 'Minimalno stevilo novih podatkov predstavlja prag za vsakega izmed modelov na vseh nivojih, koliksno je minimalno zahtevano stevilo novih podatkov za vnovicno treniranje modela.\n' +
            '                Denimo, da imamo ze natreniran model za predpono 10, novih podatkov za ta model je npr. 30, ce nastavimo minimalno stevilo na 20, bo algoritem izvedel vnovicno treinranje, ce pa npr. na 50\n' +
            '                bo algoritem preskocil treniranje tega modela in poskusil ponovno naslednjic',
        trainingPlanErrorMarginInfo: 'Minimalna absolutna napaka modela nam pove za koliko % se sme pokvariti klasifikacijska tocnost modela. Denimo da model 10 napoveduje z klasifikacijsko tocnostjo 86.4 %, ce nastavimo\n' +
            '                stevilo minimalne absolutne napake modela na 10 % in je novo natrenirani model, zaradi novih podatkov nekoliko manj natacen npr. 85 %, ga sprejmemo kot aktivni klasifikacijski model, v primeru da\n' +
            '                bi to stevilo nastavili na 1%, bi sistem zavrnil novo trenirani model in uporabil starega.',
        trainingPlanIntervalInfo: 'Nastavitev intervala nam pove v kaksnih intervalih se bo izvedlo avtomatsko treniranje.',
        trainingPlanWaitPolicyInfo: 'Ce zelimo pocakati na izvedbo ze cakajocega trening lahko to storimo s klikom na Pocakaj na ze cakajoci trening, to pomeni, da bo sistem\n' +
            '                pocakal se toliko kolikor mu je preostalo do nacrtovanega treninga, ga izvedel, ter naprej nacrtoval trening po novem podanem intervalu.\n' +
            '                V primeru da se odlocimo za to da ne pocakamo na ze cakajoci trening, se zgodi slednje: trening ki je nacrtovan se odstrani iz vrste cakajocih, v vrsto cakajocih\n' +
            '                vstopi trening s podanim intervalom.',
        trainingPlanUpdateSuccess: 'Uspesno ste posodobili trening plan',
        trainingPlanUpdateError: 'Prislo je do napake pri posodabljanju trening plana',
        tableTitle: 'Preslikovalna tabela',
        tableContent: 'Trenutna aktivna preslikovalna tabela',
        successTableUpdate: 'Uspesno posodobljena preslikovalna tabela',
        failTableUpdate: 'Napaka pri posodobitvi preslikovalne tabele'

    },
    MONITORING: {
        trainingInfoEnded: 'Trening se je zakljucil, prisotnih je 30 zadnjih belezenj ze zakljucenega treninga (Trening porocilo)',
        trainingInfoLive: 'Spremljanje procesa treninga v zivo (Trening porocilo)',
        trainingInfoNone: 'Na voljo ni podatkov o porocilih treninga, pocakajte na naslednji trening (Trening porocilo)',
        trafficTitle: 'Spremljanje prometa/dostopa do HSTariff klasifikatorja',
        graphTitle: 'Stevilo zahtevkov do API-ja,'
    },
    USERS: {
        headerEmail: 'Elektronski naslov',
        headerID: 'Unikatni identifikator',
        headerActions: 'Akcije',
        errorDeleting: 'Prislo je do napake pri brisanju uporabnika',
        successDeleting: 'Uporabnik je uspesno izbrisan iz podatkovne baze',
        addNewUser: 'Dodaj novega uporabnika',
        userTitle: 'Seznam uporabnikov za elektronsko sporočanje',
        userInfo : 'Če želite, da so uporabniki po e-pošti obveščeni o trenutnem stanju in nepravilnostih v sistemu, dodajte svoj e-poštni naslov na ta seznam. Na primer, ko klasifikator ne uspe predvideti tarifne številke '+
        ', pride do neuspešnega usposabljanja, baza podatkov, od koder pridobivamo podatke, se ne odziva itd. bodo osebe na tem seznamu obveščene o takih dogodkih',
        errorCreating: 'Prislo je do napake pri kreiranju novega uporabnika',
        successCreating: 'Uspesno ste dodali novega uporabnika',
        detectedLanguage: function (language, original, translate) {
            return `Vnesen jezik ni bil slovenski temvec: ${language}, zato smo ga prevedli iz: "${original}" v "${translate}" , posledicno bo kvaliteta predikcija nekoliko slabsa kot ce bi opis vnesli v slovenscini`
        }

    },
    CUSTOMERS: {
        tableTitle: 'Stranke:',
        btnNewCustomerText: 'Dodaj stranko',
        tableHeaderEmail: 'Email',
        tableHeaderName: 'Ime',
        tableHeaderApiKey: 'API ključ',
        tableHeaderStatus: 'Stanje računa',
        tableHeaderCreatedAt: 'Ustvarjeno',
        tableHeaderUpdatedAt: 'Posodobljeno',
        tableHeaderActions: 'Akcije',
        formTitleNew: 'Dodaj stranko',
        formTitleEdit: 'Spremeni stranko',
        formTitleDelete: 'Ali ste prepričani, da želite izbrisati to stranko?',
        formCancel: 'prekliči',
        formSave: 'shrani',
        formOk: 'potrdi',
        statusActivated: 'Aktiven',
        statusDeactivated: 'Neaktiven',
    },
    HS_HAZARDS: {
        danger: 'Nevarna snov',
        forbidden: 'Prepovedana snov',
        all: 'Vse osebe',
        individuals: 'Fizične osebe'
    }
}
