/**
 * Checks if tariff number is in valid format
 * @param tariffNumber
 * @return {boolean}
 */
export function isValidTariff(tariffNumber) {
    const isTariff = tariffNumber.length >= 6 && tariffNumber.length < 10;
    const isNumeric = /^\d+$/.test(tariffNumber);

    return isNumeric && isTariff
}