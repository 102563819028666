import httpClient from "../utils/httpClient";

/**
 * Gets hs mapping for given year
 * @param year - active year
 * @param offset - pagination offset
 * @param limit - pagination limit
 */
export const getHsMapping = async (year, offset, limit) => {
    const res = await httpClient.get(`${process.env.VUE_APP_API_URL}/hsMapping?year=${year}&offset=${offset}&limit=${limit}`);
    return res.data;
}

/**
 * Gets all years for HsMapping
 */
export const getHsMappingYears = async () => {
    const res = await httpClient.get(`${process.env.VUE_APP_API_URL}/hsMapping/years`);
    return res.data;
}

/**
 * Gets HsConfig
 */
export const getHsConfig = async () => {
    const res = await httpClient.get(`${process.env.VUE_APP_API_URL}/hsMapping/config`);
    return res.data;
}

/**
 * Deletes HsMapping entry
 * @param hsMappingId - identifier of hs mapping
 */
export const deleteHsEntry = async (hsMappingId) => {
    await httpClient.delete(`${process.env.VUE_APP_API_URL}/hsMapping/${hsMappingId}`);
}

/**
 * Creates new HsMapping entry
 * @param hsMappingReq - mapping request
 */
export const createNewHsMappingReq = async (hsMappingReq) => {
    const res = await httpClient.post(`${process.env.VUE_APP_API_URL}/hsMapping`, hsMappingReq);
    return res.data;
}

