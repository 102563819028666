<template>
  <div>
    <v-row class="mt-12 justify-center" :class="isFuncSupported ? '' : 'locked'">
      <v-col class="col-lg-5" cols="12">
        <p class="rowTile">
          {{$t('Forbidden and dangerous commodities')}}
        </p>
        <v-divider />
        <v-col class="col-lg-12">
          <p class="rowSubTitle">
            {{$t('Filter by:')}}
          </p>
          <HazardousCommodityTypes
              @onTypeChange="onTypeChange"
              version="customer"
          />

          <p class="rowSubTitle mt-4">
            {{$t('List of dangerous and forbidden commodities')}}
          </p>
          <div class="mt-1">
            <HazardousCommoditiesTable
                :data="hazardousCommodities"
                @nexPage="fetchNextPage(false)"
                @onDelete="deleteEntry"
            />
          </div>

        </v-col>
      </v-col>
      <v-col class="col-lg-5 ml-5" cols="12">
        <p class="rowTile">
          {{$t('Enter new dangerous / forbidden commodity')}}
        </p>
        <v-divider />
        <NewHazardousCommodityForm
            @onNewEntry="onNewEntry"
            version="customer"
        />
      </v-col>
    </v-row>
    <FuncLocked v-if="!isFuncSupported" />
  </div>
</template>

<script>
import HazardousCommodityTypes from "../HazardousCommodityTypes";
import NewHazardousCommodityForm from "../NewHazardousCommodityForm";
import {paginationMixin} from "../../mixins/pagination";
import HazardousCommoditiesTable from "../HazardousCommoditiesTable";
import FuncLocked from "../tariffic/components/FuncLocked";
export default {
  name: "TarifficHazardousCommodities",
  components: {FuncLocked, HazardousCommoditiesTable, NewHazardousCommodityForm, HazardousCommodityTypes},
  mixins: [paginationMixin],
  methods: {
    onTypeChange(val) {
      this.$store.dispatch('hazardous-commodities/changeFilter', val)
    },
    async fetchNextPage(filterChange) {
      if (this.$store.getters["hazardous-commodities/fetchInProgress"]) return
      if (filterChange) {
        await this.$store.dispatch('hazardous-commodities/clearData')
      }
      await this.$store.dispatch('hazardous-commodities/fetchHazardousCommodities', {
        offset: this.paginationInfo.offset,
        limit: this.paginationInfo.limit
      })
      this.markPageFetch()
    },
    async onNewEntry(req) {
      await this.$store.dispatch('hazardous-commodities/createHazardousCommodity', req)
      await this.$store.dispatch('snackbar/fireAlert', {
        message: this.$t('You have successfully added new commodity'),
        color: 'success'
      });
    },
    deleteEntry(entry) {
      this.$store.dispatch('hazardous-commodities/deleteHazardousCommodity', entry)
    }
  },
  computed: {
    isFuncSupported() {
      const sub = this.$store.getters["tariffic-account-information/mySubscription"];
      return sub && sub.hazardousComAllowed;
    },
    filters() {
      return this.$store.getters['hazardous-commodities/filterCommoditiesValue']
    },
    hazardousCommodities() {
      return this.$store.getters['hazardous-commodities/commoditiesData'];
    }
  },
  async mounted() {
    await this.fetchNextPage(true)
  },
  watch: {
    filters: {
      handler() {
        this.setToDefault()
        this.fetchNextPage(true)
      },
      deep: true
    }
  }
}
</script>

<style scoped>
.rowTile {
  color: #607c24;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
  font-size: 1.4rem;
}
.rowSubTitle {
  font-family: 'Poppins', sans-serif;
  color: #607c24;
}
.locked {
  -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(5px);
  pointer-events: none;
}
</style>