<template>
  <div class="card">
    <div class="cardTitleContainer">
      <div class="mb-1">
        <v-icon large color="rgba(0, 0, 0, 0.3)">
          {{icon}}
        </v-icon>
      </div>
      <div class="cardTitle">
        {{title}}
      </div>
    </div>
    <div class="mainContent">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "TarifficCard",
  props: ['title', 'icon']
}
</script>

<style scoped>
.card {
  background-color: white;
  padding: 22px;
  border-radius: 5px;
  -webkit-box-shadow: 7px 7px 18px -4px rgba(92,97,91,0.28);
  -moz-box-shadow: 7px 7px 18px -4px rgba(92,97,91,0.28);
  box-shadow: 7px 7px 18px -4px rgba(92,97,91,0.28);
}
.cardTitleContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.cardTitle {
  color: #607c24;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  margin-left: 10px;
  font-size: 1.35rem;
}
.mainContent {
  margin-top: 12px;
}
</style>